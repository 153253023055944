import { inject, Injectable } from '@angular/core';
import { ThemeStore } from '../../stores/theme.store';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ThemeResolver {
  private readonly themeStore = inject(ThemeStore);
  async resolve(route: ActivatedRouteSnapshot) {
    await this.themeStore.updateTheme(route);
    return true;
  }
}
