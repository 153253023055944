import { Component, inject, Input } from '@angular/core';
import { ContextStore } from '../../../stores/context.store';

@Component({
  selector: 'app-error-box',
  templateUrl: './error-box.component.html',
  styleUrls: ['./error-box.component.scss'],
})
export class ErrorBoxComponent {
  @Input() withFrame = true;
  readonly contextStore = inject(ContextStore);
}
