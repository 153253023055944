import { Component, inject, OnInit } from '@angular/core';
import { GetDemoHostessSurplusResponse, PaymentResponse, PaymentTypeObject } from '@victoria-company/agora-client';
import { ResourcesStore } from '../../../../stores/resources.store';
import { ContextStore } from '../../../../stores/context.store';
import { DemoService } from '../../../../core/services/V2/demo.service';
import { PaymentsService } from '../../../../core/services/V2/payments.service';
import { ScreenService } from '../../../../core/services/screen.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorResponse } from '../../../../shared/components/flow-error/flow-error.component';
import { getVictoriaUrl } from '../../../../core/utils/filter.utils';
const MAX_OPEN_REQUEST = 12;
@Component({
  selector: 'app-hostess-payment-confirmation',
  templateUrl: './hostess-payment-confirmation.component.html',
  styleUrls: ['./hostess-payment-confirmation.component.scss'],
})
export class HostessPaymentConfirmationComponent implements OnInit {


  readonly resourcesStore = inject(ResourcesStore);
  readonly contextStore = inject(ContextStore);
  readonly demoService = inject(DemoService)
  readonly paymentService = inject(PaymentsService)

  readonly screen = inject(ScreenService);
  readonly router = inject(Router);
  readonly currentRoute = inject(ActivatedRoute)


  public surplus:GetDemoHostessSurplusResponse;
  public payment:PaymentResponse;
  public globalError:ErrorResponse;
  public demoCode:string;
  public paymentRequest = 0;

  private lyfPayStatus:string;


  async ngOnInit() {
    this.globalError = undefined;
    this.demoCode = this.currentRoute.snapshot.params.demoCode;
    this.lyfPayStatus = this.currentRoute.snapshot.queryParams.status ?? '';

    if(this.demoCode) {
      this.surplus = await this.demoService.getDataForSurplus(this.demoCode).catch(err => this.globalError = err)

      if(this.surplus?.paymentId) await this.checkPaymentStatus(this.surplus?.paymentId)
      else await this.router.navigateByUrl(`/${this.contextStore.locale()}/public/${this.demoCode}/payment`);

    } else await this.goToMVHome();
  }

  async goToMVHome() {
    await this.router.navigate([`/${this.contextStore.locale()}`]);
  }

  private async checkPaymentStatus(paymentId:string){
    this.payment = await this.paymentService.getByPaymentId(this.surplus?.paymentId).catch(err => {
      this.globalError = err
      return null;
    })

    if(!this.payment) {
      await this.router.navigateByUrl(`/${this.contextStore.locale()}/public/${this.demoCode}/payment?p=Unknown`)
      return
    }

    if(this.payment.status=="Open" && this.lyfPayStatus && this.lyfPayStatus=="canceled"){

      try {
        await this.paymentService.cancelPayment(this.payment.id);
        await this.router.navigateByUrl(`/${this.contextStore.locale()}/public/${this.demoCode}/payment?p=Canceled`)
      } catch (err) {
        this.globalError = err;
      }

      return
    }

    if(this.payment.status=="Open" && this.paymentRequest >= MAX_OPEN_REQUEST) {
      await this.router.navigateByUrl(`/${this.contextStore.locale()}/public/${this.demoCode}/payment?p=${this.payment.status}`)
    } else {
      this.paymentRequest++;
      return setTimeout(async () => await this.checkPaymentStatus(paymentId), 5000)
    }
  }

  public getLogoUrl() {
    return `${getVictoriaUrl(this.contextStore.locale(), true)}/${this.contextStore.locale()}`;
  }

  protected readonly PaymentTypeObject = PaymentTypeObject;
}
