<div class="communications-container">
  <div class="header flex center-all column">
    <img loading="lazy" src="/assets/images/logo.svg" alt="Logo Victoria" />
    <h1>{{ 'COMMUNICATION.TITLE' | translate }}</h1>
    <div class="dark"></div>
  </div>
  <div class="body">
    <div class="body-title flex ai-center column">
      <p class="subtitle" [innerHTML]="'COMMUNICATION.CAPTION' | translate"></p>
      <div *ngIf="data" class="heading flex column ai-center">
        <h2>{{ 'COMMUNICATION.EMAIL' | translate }}</h2>
        <h3>{{ data.email }}</h3>
      </div>
    </div>
    <div *ngIf="data && !globalError" class="body-communications container flex column ai-start">
      <div class="communications-main center">
        <p [innerHTML]="'COMMUNICATION.OPTIN.CAPTION' | translate: { Y: data.email }"></p>
      </div>
    </div>
    <div *ngIf="globalError" class="body-communications container flex column ai-center error-box">
      <p class="subtitle">{{ 'COMMUNICATION.UNAUTHORIZED' | translate }}</p>
    </div>
  </div>
</div>
<app-flow-error *ngIf="globalError" [error]="globalError" />
