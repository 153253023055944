<div class="status-action-card flex m-column">
  <div class="image-container">
    <svg *ngIf="hasSvgImage"><use [attr.xlink:href]="getTrackableActionSvgFromType(cardType)"></use></svg>
    <img *ngIf="!hasSvgImage" loading="lazy" src="assets/images/tips-{{ getTrackableActionTypeToLegacyId(cardType) }}.png" />
  </div>
  <div class="description-container">
    <p class="title">{{ getTrackableActionDescriptionTitle(cardType) | translate }}</p>
    <p class="description">{{ getTrackableActionDescriptionLabel(cardType) | translate: { X: value } }}</p>
  </div>
  <div class="received-container">
    <p class="received">
      @if (cardType == ClubActionTypeEnumObject.CodeAdd) {
        {{ 'GLOBAL.ADDONEPOINTEURO' | translate }}
      } @else if (cardType == ClubActionTypeEnumObject.BoosterLevel1) {
        {{ 'GLOBAL.ADDXPOINTS' | translate: { X: 275 } }}
        <br /><br />
        {{ 'GLOBAL.ADDXEUROJEWELS' | translate: { X: 100 | agoraCurrencyInt } }}
      } @else {
        {{ 'GLOBAL.ADDXPOINTS' | translate: { X: value } }}
      }
    </p>
  </div>
  <div class="action-container">
    @if (doneDate) {
      <div class="flex actionDone">
        <svg viewBox="0 0 32 32" class="icon peach height100">
          <use xlink:href="#icon-checkmark"></use>
        </svg>
        &nbsp; {{ 'STATUS.MOREPOINTS.ACTION.DONEATDATEX' | translate: { X: doneDate | date: 'dd/MM/yyyy' } }}
      </div>
    } @else {
      <div>
        <button class="btn btn-primary m-j-center m-fit-content block m-auto-no-margin flex center-all" (click)="executeAction()">
          <div>
            <label>{{ getTrackableActionCTAText(cardType) | translate }}</label>
          </div>
        </button>
      </div>
    }
  </div>
</div>
