import { inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class ImpersonationService {

      private api = inject(ApiService)

      async getUserInfoForImpersonationByDelegateId(delegateId:string){
        const client = this.api.getClientInstance()
        return client.api.delegates.byDelegateId(delegateId).userInfo.get()
      }

      async getImpersonationTokenByUserId(userId:string){
        const client = this.api.getClientInstance();
        return client.api.users.byUserId(userId).impersonationToken.get()
      }
}
