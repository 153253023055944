import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { CookieService } from 'ngx-cookie-service';
import { computed, inject } from '@angular/core';
import { getCookieDomainByLang } from '../core/utils/filter.utils';

export interface ContextState {
  locale: string;
  region: string;
  contextId: number;
  isLoaded: boolean;
}

export const initialState: ContextState = { locale: '', region: '', contextId: 0, isLoaded: false };

export const ContextStore = signalStore(
  { providedIn: 'root' },
  withDevtools('context'),
  withState(initialState),
  withComputed(store => ({
    commissionRate: computed(() => {
      switch (store.contextId()) {
        case 1:
          return 0.1916;
        case 2:
        case 3:
          return 0.21;
        default:
          return 0;
      }
    }),
  })),
  withMethods((store, cookieService = inject(CookieService)) => ({
    async setLocale(localeInfo: { locale: string; region: string }): Promise<void> {
      const region = localeInfo.region ?? localeInfo.locale;
      let contextId: number;
      switch (localeInfo.locale) {
        case 'nl-nl':
        case 'fr-be':
        case 'nl-be':
          contextId = 2;
          break;
        case 'de-de':
          contextId = 3;
          break;
        case 'fr-fr':
          contextId = 1;
          break;
        default:
          contextId = 0;
      }

      cookieService.set('v_locale', localeInfo.locale, 365, '/', getCookieDomainByLang(localeInfo.locale));
      cookieService.set('v_region', region, 365, '/', getCookieDomainByLang(localeInfo.locale));

      patchState(store, () => ({
        contextId,
        region,
        locale: localeInfo.locale,
        isLoaded: true,
      }));
    },
  }))
);
