<!--<div *ngIf="resourcesStore.isLoaded()" class="modal-container" id="sizeGuide" [style.display]="opened ? 'flex' : 'none'">-->
<!--  <div class="modal" *ngIf="resourcesStore.isLoaded()">-->
<!--    <div class="modal-icon icon peach" (click)="close()">-->
<!--      <svg viewBox="0 0 32 32">-->
<!--        <use xlink:href="#icon-close"></use>-->
<!--      </svg>-->
<!--    </div>-->
<!--    <h1>{{ resourcesStore.i18n().resources.popupPickSize.mainTitle }}</h1>-->
<!--    <div class="modal-body modal-size-guide">-->
<!--      &lt;!&ndash; Tab Area &ndash;&gt;-->
<!--      <div class="flex j-start selection scrollable">-->
<!--        <div-->
<!--          *ngFor="let item of resourcesStore.i18n().resources.sizeGuide.tabs; let i = index"-->
<!--          class="tab tab-auto flex center-all no-wrap selection-title"-->
<!--          [class.selected]="selectedTab == i.toString()"-->
<!--          (click)="switchTab(i.toString())">-->
<!--          {{ item.tabLabel }}-->
<!--        </div>-->
<!--      </div>-->
<!--      &lt;!&ndash; Tab Content Area&ndash;&gt;-->
<!--      <div class="search-form">-->
<!--        <ng-template [ngFor] let-item [ngForOf]="resourcesStore.i18n().resources.sizeGuide.tabs" let-i="index">-->
<!--          <div class="flex column gap-8 tab-details" *ngIf="selectedTab == i.toString()">-->
<!--            <p>{{ item.description }}</p>-->
<!--            <div class="flex ai-start gap-20 m-column">-->
<!--              <figure *ngIf="item.imagePoster.length > 0" class="flex-1">-->
<!--                <img loading="lazy" [src]="item.imagePoster[0].url" [alt]="item.imagePoster[0].filename" />-->
<!--              </figure>-->
<!--              <div class="flex-1 flex column table-container">-->
<!--                <div class="flex j-space-around">-->
<!--                  <div class="flex column gap-20 flex-1">-->
<!--                    <div class="flex column gap-20">-->
<!--                      <span class="lightbold table-head">{{ item.tableHeadSizeLabel }}</span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="flex column gap-20 flex-1">-->
<!--                    <div class="flex column gap-20">-->
<!--                      <span class="lightbold table-head">{{ item.tableHeadCircumferenceLabel }}</span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="flex gap-10 j-space-around table-size">-->
<!--                  <div class="flex column gap-20 flex-1">-->
<!--                    <div *ngFor="let tbl of item.table; let j = index" class="flex column gap-20">-->
<!--                      <span>{{ tbl.col1 }}</span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="flex column gap-20 flex-1">-->
<!--                    <div *ngFor="let tbl of item.table; let j = index" class="flex column gap-20">-->
<!--                      <span>{{ tbl.col2 }}</span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </ng-template>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<app-modals
  [id]="'sizeGuide'"
  (requestCloseModal)="close()"
  [opened]="resourcesStore.isLoaded() && opened"
  [title]="'GLOBAL.SIZEGUIDE' | translate"
  [uppercaseTitle]="true"
  [customClass]="'size-guide-modal'"
  [customTitleClass]="'title-futura-left'">
  <div class="modal-size-guide">
    <!-- Tab Area -->
    <div class="flex j-start selection scrollable no-scrollbar">
      <div
        *ngFor="let item of resourcesStore.i18n().resources.sizeGuide.tabs; let i = index"
        class="tab tab-auto flex center-all no-wrap selection-title"
        [class.selected]="selectedTab == i.toString()"
        (click)="switchTab(i.toString())">
        {{ item.tabLabel }}
      </div>
    </div>
    <!-- Tab Content Area-->
    <div class="search-form no-scrollbar modal-size-guide-body">
      <ng-template [ngFor] let-item [ngForOf]="resourcesStore.i18n().resources.sizeGuide.tabs" let-i="index">
        <div class="flex column gap-8 tab-details no-scrollbar" *ngIf="selectedTab == i.toString()">
          <p>{{ item.description }}</p>
          <div class="flex ai-start gap-20 m-column">
            <figure *ngIf="item.imagePoster.length > 0" class="flex-1">
              <img loading="lazy" [src]="item.imagePoster[0].url" [alt]="item.imagePoster[0].filename" />
            </figure>
            <div class="flex-1 flex column table-container">
              <div class="flex j-space-around">
                <div class="flex column gap-20 flex-1">
                  <div class="flex column gap-20">
                    <span class="lightbold table-head">{{ item.tableHeadSizeLabel }}</span>
                  </div>
                </div>
                <div class="flex column gap-20 flex-1">
                  <div class="flex column gap-20">
                    <span class="lightbold table-head">{{ item.tableHeadCircumferenceLabel }}</span>
                  </div>
                </div>
              </div>
              <div class="flex gap-10 j-space-around table-size">
                <div class="flex column gap-20 flex-1">
                  <div *ngFor="let tbl of item.table; let j = index" class="flex column gap-20">
                    <span>{{ tbl.col1 }}</span>
                  </div>
                </div>
                <div class="flex column gap-20 flex-1">
                  <div *ngFor="let tbl of item.table; let j = index" class="flex column gap-20">
                    <span>{{ tbl.col2 }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</app-modals>
