<div class="hostess-gift-modal" [id]="id" [style.display]="opened ? 'flex' : 'none'" (click)="closeModalFromOutside($event)">
  <div class="hostess-gift-modal-container">
    <div class="close">
      <svg viewBox="0 0 32 32" class="pointer" (click)="close()">
        <use xlink:href="#icon-close"></use>
      </svg>
    </div>
    <div class="gifts-container">
      <app-hostess-select-gifts [isDelegate]="isDelegate" [selectedCategory]="clickedCategory" [hostessGifts]="hostessGifts" (requestSaveSelectedGift)="saveGift($event)"></app-hostess-select-gifts>
    </div>
  </div>
</div>
