import { AuthenticationProvider, RequestInformation, Headers } from '@microsoft/kiota-abstractions';
import { AuthService } from './auth.service';
import { firstValueFrom } from 'rxjs';
import { StorageService } from '../storage.service';

export class AgoraAuthenticationProvider implements AuthenticationProvider {
  private static readonly authorizationHeaderKey = 'Authorization';

  /**
   *
   * @param accessTokenProvider
   */
  public constructor(private readonly authService: AuthService, private readonly storage:StorageService) {}

  public authenticateRequest = async (request: RequestInformation, additionalAuthenticationContext?: Record<string, unknown>): Promise<void> => {
    if (!request) {
      throw new Error('request info cannot be null');
    }

    if (additionalAuthenticationContext && additionalAuthenticationContext['claims'] && request.headers.has(AgoraAuthenticationProvider.authorizationHeaderKey)) {
      request.headers.delete(AgoraAuthenticationProvider.authorizationHeaderKey);
    }

    if (!request.headers || !request.headers.has(AgoraAuthenticationProvider.authorizationHeaderKey)) {
      if (!request.headers) {
        request.headers = new Headers();
        request.headers.add('responseType', 'blob');
      }

      const accessToken = await firstValueFrom(this.authService.getTokenFromOIDC());
      const impersonatedToken = this.storage.impersonatedToken;

      if(impersonatedToken){
        request.headers.add(AgoraAuthenticationProvider.authorizationHeaderKey, `Bearer ${impersonatedToken}`);
      }
      else if (accessToken) {
        request.headers.add(AgoraAuthenticationProvider.authorizationHeaderKey, `Bearer ${accessToken}`);
      }
    }
  };
}
