<app-modals [id]="'shipping-info'" [opened]="resourcesStore.isLoaded() && isOpened" (requestCloseModal)="close()" [title]="resourcesStore.i18n().resources.popupShippingOptions.mainTitle">
  <div class="modal-body shipping no-scrollbar">
    <p>{{ resourcesStore.i18n().resources.popupShippingOptions.description | removeHtmlTag }}</p>
    <ng-template [ngFor] let-item [ngForOf]="resourcesStore.i18n().resources.popupShippingOptions.stepsDescription">
      <div class="delivery-info border-bottom">
        <h2 class="flex ai-start">
          <span class="info-number"> {{ item.number }}</span
          >{{ item.stepTitle }}
        </h2>
        <p>{{ item.text }}</p>
      </div>
    </ng-template>
  </div>
</app-modals>
