<app-modals *ngIf="clubInformation" [id]="'create-voucher-modal'" [opened]="opened" [customClass]="'create-voucher-modal'" (requestCloseModal)="close()">
  <div class="create-voucher-scrollable no-scrollbar" *ngIf="selectedTab === 'createVoucherTab'">
    <a class="back-to-wallet-quickaccess" (click)="close()">
      <div class="icon icon-left peach arrow-back">
        <svg viewBox="0 0 32 32">
          <use xlink:href="#icon-arrow-left-long"></use>
        </svg>
      </div>
      <div class="label">{{ 'VOUCHER.MODAL.BACKTOWALLET' | translate }}</div>
    </a>
    <div class="create-voucher" *ngIf="clubInformation">
      <!-- Modal content -->
      <div class="modal-content d-only create-voucher-creation">
        <div class="wallet-summary-container flex row j-start ai-stretch ac-center">
          <div class="current-wallet-card" *ngIf="clubInformation">
            <app-card
              class="wallet-status-card"
              [data]="clubInformation"
              [cardType]="cardType.WALLET"
              [showTooltip]="true"
              [footerInformationTooltipText]="'VOUCHER.MODAL.DESCRIPTION' | translate"
              [footerInformationTooltipPosition]="'right'"></app-card>
          </div>
          <div class="flex-2 flex column j-space-between" *ngIf="clubInformation">
            <div class="flex column">
              <div>
                <h1>{{ 'WALLET.MODAL.TITLE' | translate }}</h1>
                <p class="creation-description">{{ 'WALLET.MODAL.LABEL' | translate }}</p>
              </div>
              <p *ngIf="clubInformation.walletAmount > 0" class="available" innerHTML="{{ 'WALLET.MODAL.XAVAILABLE' | translate: { X: clubInformation.walletAmount | agoraCurrency } }}"></p>
              <p
                *ngIf="clubInformation.walletAmount == 0"
                class="available"
                innerHTML="{{ 'WALLET.CREATEVOUCHER.LABEL.ALLCONSUMED' | translate: { X: clubInformation.walletAmount | agoraCurrency } }}"></p>
              <div *ngIf="clubInformation.walletAmount > 0" class="form-control flex-1 input input-amount" [class.error]="error">
                <p>{{ 'WALLET.MODAL.VOUCHERVALUE' | translate }}</p>
                <div class="flex ai-center"><input [(ngModel)]="voucherValue" type="number" min="0.01" step="any" /> €</div>
                <app-flow-error *ngIf="error" [error]="error" [showError]="true" />
                <label *ngIf="hasError" class="label-error sticky-message">{{ 'GLOBAL.ERROR.AMOUNTINCORRECT' | translate }}</label>
              </div>
            </div>
            <div>
              <div>
                <button
                  class="btn btn-primary"
                  [class.disabled]="isLoading || clubInformation.walletAmount == 0"
                  [disabled]="isLoading || clubInformation.walletAmount == 0"
                  (click)="createVoucherAction()">
                  <div *ngIf="isLoading" class="loader loader-mini"></div>
                  {{ 'WALLET.MODAL.ACTION' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-content m-only create-voucher-creation">
        <h1>{{ 'WALLET.MODAL.TITLE' | translate }}</h1>
        <div class="flex center-all center">
          <p *ngIf="clubInformation.walletAmount > 0" class="available" innerHTML="{{ 'WALLET.MODAL.XAVAILABLE' | translate: { X: clubInformation.walletAmount | agoraCurrency } }}"></p>
          <p
            *ngIf="clubInformation.walletAmount == 0"
            class="available"
            innerHTML="{{ 'WALLET.CREATEVOUCHER.LABEL.ALLCONSUMED' | translate: { X: clubInformation.walletAmount | agoraCurrency } }}"></p>
        </div>
        <div class="input-amount">
          <p *ngIf="clubInformation.walletAmount > 0">{{ 'WALLET.MODAL.VOUCHERVALUE' | translate }}</p>
          <div *ngIf="clubInformation.walletAmount > 0" class="form-control flex-1 input input-amount" [class.error]="error">
            <div class="flex ai-center"><input [(ngModel)]="voucherValue" type="number" min="0.01" step="any" /> €</div>
            <app-flow-error *ngIf="error" [error]="error" [showError]="true" />
          </div>
        </div>
        <div *ngIf="clubInformation.walletAmount > 0" class="flex center-all center">
          <p class="creation-description">{{ 'WALLET.MODAL.LABEL' | translate }}</p>
        </div>
        <div class="action-button">
          <button
            class="btn btn-primary m-btn-block"
            [class.disabled]="isLoading || clubInformation.walletAmount == 0"
            [disabled]="isLoading || clubInformation.walletAmount == 0"
            (click)="createVoucherAction()">
            <div *ngIf="isLoading" class="loader loader-mini"></div>
            {{ 'WALLET.MODAL.ACTION' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="create-voucher-scrollable no-scrollbar" *ngIf="selectedTab === 'voucherCreatedTab'">
    <div class="voucher-created" *ngIf="voucherCreated">
      <!-- Modal content -->
      <div class="modal-content m-only create-voucher-created flex column ai-center">
        <h1>{{ 'GLOBAL.GREETINGS' | translate }}</h1>
        <p class="created-confirmation">{{ 'VOUCHER.MODAL.VOUCHERCREATED' | translate }}</p>
        <div class="card">
          <div class="header">
            <div class="flex row j-space-between ai-center">
              <div class="voucher-type">
                <p>{{ 'GLOBAL.VOUCHER.TYPE_' + voucherCreated.type?.toUpperCase() | translate }}</p>
              </div>
              <div class="flex row ai-center">
                <svg viewBox="0 0 14 14" class="icon peach status-icon">
                  <use xlink:href="#green-point"></use>
                </svg>
                &nbsp; {{ 'GLOBAL.STATUS.NOTUSED' | translate }}
              </div>
            </div>
          </div>
          <div class="body">
            <div class="container">
              <p class="title-m" innerHTML="{{ 'GLOBAL.VOUCHER.VALUEX' | translate: { X: voucherCreated.amount | agoraCurrency } }}"></p>
              <p class="voucher-code">{{ voucherCreated.code }}</p>
              <div class="flex row copy-button">
                <div class="j-center">
                  <div class="icon-container flex j-end">
                    <svg viewBox="0 0 32 32" class="icon icon-copy-check">
                      <use xlink:href="#icon-checkmark"></use>
                    </svg>
                    <button class="btn-copy" (click)="copyValue($event, voucherCreated.code)">
                      <svg viewBox="0 0 32 32" class="icon peach">
                        <use xlink:href="#icon-copy"></use>
                      </svg>
                    </button>
                  </div>
                </div>
                <div class="j-center">
                  <p>{{ 'GLOBAL.VOUCHER.COPYCODE' | translate }}</p>
                </div>
              </div>
              <p class="title-m no-line-height expiration-date-label" innerHTML="{{ 'GLOBAL.VOUCHER.EXPIREATDATEX' | translate: { X: voucherCreated.expirationDate | date: 'dd/MM/yyyy' } }}"></p>
            </div>
          </div>
          <div class="footer">
            <div class="action-button">
              <div class="button">
                <button class="btn-icon btn-primary flex j-center" (click)="downloadVoucher(voucherCreated.code)">
                  <svg viewBox="0 0 28 29" class="icon">
                    <use xlink:href="#icon-download"></use>
                  </svg>
                </button>
              </div>
              <p>{{ 'GLOBAL.ACTION.DOWNLOAD' | translate }}</p>
            </div>
          </div>
        </div>
        <div class="back-to-wallet">
          <button class="btn btn-primary m-btn-block" (click)="close()">{{ 'VOUCHER.MODAL.ALLMYVOUCHERS' | translate }}</button>
        </div>
        <a class="create-other" (click)="goToStep(CreateVoucherTabsEnum.createVoucherTab)">{{ 'VOUCHER.MODAL.CREATENEWVOUCHER' | translate }}</a>
      </div>

      <div class="modal-content d-only create-voucher-created">
        <div class="flex column ai-center">
          <h1>{{ 'GLOBAL.GREETINGS' | translate }}</h1>
          <p class="created-confirmation">{{ 'VOUCHER.MODAL.VOUCHERCREATED' | translate }}</p>
          <div class="card">
            <div class="header">
              <div class="flex row j-space-between ai-center">
                <div class="voucher-type">
                  <p>{{ 'GLOBAL.VOUCHER.TYPE_' + voucherCreated.type?.toUpperCase() | translate }}</p>
                </div>
                <div class="flex row ai-center">
                  <svg viewBox="0 0 14 14" class="icon peach status-icon">
                    <use xlink:href="#green-point"></use>
                  </svg>
                  &nbsp; {{ 'GLOBAL.STATUS.NOTUSED' | translate }}
                </div>
              </div>
            </div>
            <div class="body">
              <div class="container">
                <p class="title-m" innerHTML="{{ 'GLOBAL.VOUCHER.VALUEX' | translate: { X: voucherCreated.amount | agoraCurrency } }}"></p>
                <p class="voucher-code">{{ voucherCreated.code }}</p>
                <div class="flex row copy-button">
                  <div class="j-center">
                    <div class="icon-container flex j-end">
                      <svg viewBox="0 0 32 32" class="icon icon-copy-check">
                        <use xlink:href="#icon-checkmark"></use>
                      </svg>
                      <button class="sm-btn-icon m-j-center copy-voucher-code" (click)="copyValue($event, voucherCreated.code)">
                        <svg viewBox="0 0 32 32" class="icon">
                          <use xlink:href="#icon-copy"></use>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div class="j-center">
                    <p>{{ 'GLOBAL.VOUCHER.COPYCODE' | translate }}</p>
                  </div>
                </div>
                <p class="title-m no-line-height expiration-date-label" innerHTML="{{ 'GLOBAL.VOUCHER.EXPIREATDATEX' | translate: { X: voucherCreated.expirationDate | date: 'dd/MM/yyyy' } }}"></p>
              </div>
            </div>
            <div class="footer">
              <div class="action-button">
                <div class="button">
                  <button class="btn-icon btn-primary flex j-center" (click)="downloadVoucher(voucherCreated.code)">
                    <svg viewBox="0 0 28 29" class="icon">
                      <use xlink:href="#icon-download"></use>
                    </svg>
                  </button>
                </div>
                <p>{{ 'GLOBAL.ACTION.DOWNLOAD' | translate }}</p>
              </div>
            </div>
          </div>
          <div class="back-to-wallet">
            <button class="btn btn-primary m-btn-block" (click)="close()">{{ 'VOUCHER.MODAL.ALLMYVOUCHERS' | translate }}</button>
          </div>
          <a class="create-other" (click)="goToStep(CreateVoucherTabsEnum.createVoucherTab)">{{ 'VOUCHER.MODAL.CREATENEWVOUCHER' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</app-modals>
