<div class="modal-container" [id]="'#' + id">
  <div class="modal + {{ modalClass }}" [class.search-delegate]="template == 'searchDelegate' || template == 'selectDelegate'" *ngIf="resourcesStore.isLoaded()" [style]="{ width: width }">
    <div *ngIf="isClosable" class="modal-icon icon peach" (click)="modal.close('#' + id)">
      <svg viewBox="0 0 32 32">
        <use xlink:href="#icon-close"></use>
      </svg>
    </div>
    <div *ngIf="viewNextStep" class="flex cursor" (click)="stepBack()">
      <svg viewBox="0 0 32 32" class="icon">
        <use xlink:href="#icon-arrow-left-long"></use>
      </svg>
      {{ resourcesStore.i18n().resources.popupFindConsultant.backLabel }}
    </div>
    <h1 *ngIf="title && !viewNextStep">{{ title }}</h1>
    <h1 *ngIf="titleNextStep && viewNextStep">{{ titleNextStep }}</h1>
<!--    <ng-container-->
<!--      *ngTemplateOutlet="-->
<!--        {-->
<!--          info: info,-->
<!--          promo: promo,-->
<!--          size: size,-->
<!--          addCode: addCode,-->
<!--          searchDelegate: searchDelegate,-->
<!--          selectDelegate: selectDelegate,-->
<!--          confirmDeleteWishlistItem: confirmDeleteWishlistItem,-->
<!--          walletCreateVoucher: walletCreateVoucher,-->
<!--          simpleSize: simpleSize,-->
<!--          birthDateModal: birthDateModal,-->
<!--          congratulationsModal: congratulationsModal,-->
<!--          contextRedirectionModal: contextRedirectionModal,-->
<!--          confirmation: confirmation-->
<!--        }[template]-->
<!--      ">-->
<!--    </ng-container>-->
  </div>
</div>

<ng-template #promo>
  <div class="modal-body promo">
    <p>{{ 'MODAL.PROMO.SUBTITLE' | translate }}</p>
    <div class="promo-form">
      <label>{{ 'MODAL.PROMO.LABEL' | translate }}</label>
      <div class="flex gap-8">
        <div [class.error]="hasError">
          <input type="text" [(ngModel)]="value" [placeholder]="'MODAL.PROMO.PLACEHOLDER' | translate" />
          <label *ngIf="hasError && errorCode == 12">{{ resourcesStore.i18n().resources.shoppingBag.bagLabel }}</label>
          <label *ngIf="hasError && errorCode == 13">{{ resourcesStore.i18n().resources.shoppingBag.backToTopLabel }}</label>
          <label *ngIf="hasError && errorCode == 14">{{ resourcesStore.i18n().resources.shoppingBag.backLabel }}</label>
          <label *ngIf="hasError && errorCode == 15">{{ resourcesStore.i18n().resources.shoppingBag.backToTopLabel }}</label>
        </div>
        <button class="btn btn-primary" (click)="modal.executeAction({ actionName: 'validateCode', value: value })">{{ 'GLOBAL.ACTION.ADD' | translate }}</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #congratulationsModal>
  <div class="modal-body modal-congratulations">
    <div class="flex center-all header">
      <p>{{ 'MODAL.CONGRATULATION.TITLE' | translate }}</p>
    </div>
    <!--    <div class="flex column center-all" *ngIf="summary">-->
    <!--      <p class="title">{{ 'MODAL.CONGRATULATION.LEVELUP' | translate }}</p>-->
    <!--      <app-card-->
    <!--        class="card"-->
    <!--        [cardType]="cardType.STATUS"-->
    <!--        [data]="summary.status"-->
    <!--        [hiddeFooterAction]="true"-->
    <!--        [forecasts]="summary.forecasts"-->
    <!--        [showStatusLink]="false"-->
    <!--        [paddingBottom]="false"></app-card>-->
    <!--    </div>-->
    <div class="flex j-center link" *ngIf="!hiddeLink">
      <app-wavedlink
        [linkText]="'MODAL.CONGRATULATIONS.ACTION' | translate | uppercase"
        [linkUrl]="'/' + contextStore.locale() + '/club/status'"
        (click)="modal.close('#congratulationsModal')"></app-wavedlink>
    </div>
  </div>
</ng-template>

<ng-template #contextRedirectionModal>
  <div class="modal-body modal-redirection">
    <div class="flex column center-all header">
      <p>{{ 'REDIRECTION.BODY' | translate }}</p>
      <p>
        <em>{{ 'REDIRECTION.OPERATION' | translate }}</em>
      </p>
    </div>
  </div>
</ng-template>
