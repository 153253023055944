<div class="modal-container" [id]="id" *ngIf="opened" (click)="closeModalFromOutside($event)">
  <div class="modal-content" [ngClass]="customClass" [class.padding-thin]="thinPadding" [style]="customStyle">
    <div class="modal-close-button" (click)="close()">
      <svg viewBox="0 0 32 32">
        <use xlink:href="#icon-close"></use>
      </svg>
    </div>
    <div class="modal-title" [ngClass]="customTitleClass" [class.centered]="centerTitle" [class.uppercase]="uppercaseTitle" *ngIf="title">{{ title }}</div>
    <ng-content></ng-content>
  </div>
</div>
