import { Component, inject, OnInit } from '@angular/core';
import { ResourcesStore } from '../../../../stores/resources.store';
import { ScreenService } from '../../../../core/services/screen.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextStore } from '../../../../stores/context.store';
import { DemoService } from '../../../../core/services/V2/demo.service';
import { PaymentsService } from '../../../../core/services/V2/payments.service';
import { GetDemoHostessSurplusResponse, PaymentResponse, PaymentResponseStatusObject } from '@victoria-company/agora-client';
import { ErrorResponse } from '../../../../shared/components/flow-error/flow-error.component';

@Component({
  selector: 'app-hostess-payment',
  templateUrl: './hostess-payment.component.html',
  styleUrls: ['./hostess-payment.component.scss'],
})
export class HostessPaymentComponent implements OnInit {
  readonly resourcesStore = inject(ResourcesStore);
  readonly contextStore = inject(ContextStore);
  readonly demoService = inject(DemoService);
  readonly paymentService = inject(PaymentsService);

  readonly screen = inject(ScreenService);
  readonly router = inject(Router);
  readonly currentRoute = inject(ActivatedRoute);

  public surplus: GetDemoHostessSurplusResponse;
  public payment: PaymentResponse;
  public loaded = false;
  public globalError: ErrorResponse;

  public demoCode: string;
  public paymentError: string;
  public isOperationPending = false;

  async ngOnInit() {
    this.globalError = undefined;
    this.demoCode = this.currentRoute.snapshot.params.demoCode;
    this.paymentError = this.currentRoute.snapshot.queryParams.p;
    if (this.paymentError == 'Open') this.paymentError = undefined;

    if (this.demoCode) {
      this.surplus = await this.demoService.getDataForSurplus(this.demoCode).catch(err => {
        this.globalError = err;
        this.loaded = true;
        return null;
      });
      if (this.surplus?.paymentId)
        this.payment = await this.paymentService.getByPaymentId(this.surplus?.paymentId).catch(err => {
          this.globalError = err;
          this.loaded = true;
          return null;
        });
      if (this.payment && this.payment?.status == 'Paid') await this.router.navigateByUrl(`/${this.contextStore.locale()}/public/${this.demoCode}/payment/confirmation`);
      this.loaded = true;
    } else await this.goToMVHome();
  }

  async goToMVHome() {
    await this.router.navigate([`/${this.contextStore.locale()}`]);
  }

  async requestPayment() {
    this.isOperationPending = true;
    this.globalError = undefined;
    let checkoutUrl = '';

    if (this.payment && this.payment.checkoutUrl) {
      checkoutUrl = this.payment.checkoutUrl;
    } else {
      const redirectUrl = `${window.origin}/${this.contextStore.locale()}/public/${this.demoCode}/payment/confirmation`;
      const paymentResponse = await this.paymentService.requestSurplusPayment(this.demoCode, this.contextStore.locale(), redirectUrl).catch(err => (this.globalError = err));
      if (paymentResponse) checkoutUrl = paymentResponse.checkoutUrl;
    }

    if (!this.globalError) window.location.href = checkoutUrl;

    this.isOperationPending = false;
  }

  protected readonly open = open;
  protected readonly PaymentResponseStatusObject = PaymentResponseStatusObject;
}
