<ng-container *ngIf="themeStore.loaded() && (resourcesStore.isLoaded() || routeIsResourcesFree)">
  <!-- PAGE TOP -->
  <app-header
    *ngIf="themeStore.showHeader()"
    (openCloseUserMenu)="updateUserMenuState()"
    (openCloseMenu)="updateMenuState()"
    (requestRegistration)="registerListeners()"
    [isMenuOpen]="themeStore.menuOpened()"
    [isUserMenuOpen]="themeStore.userMenuOpened()"></app-header>
  <app-jumbotron class="fixed-jumbo" *ngIf="themeStore.showJumbotron()"></app-jumbotron>
  <app-go-to-demo-banner *ngIf="userStore.userActiveDemoCode() && themeStore.showDemoBanner()"></app-go-to-demo-banner>
  <!-- PAGE BODY -->
  <main [class.background-pink]="themeStore.showPinkBackground()">
    <!-- MAIN CONTAINER -->
    <section
      class="main-container"
      [class.wideScreen]="themeStore.showLargeScreen()"
      [class.wideScreenForMobileOnly]="themeStore.showLargeScreenForMobileOnly()"
      [class.fullScreen]="themeStore.showFullScreen()">
      <!-- BREADCRUMB -->
      <app-breadcrum *ngIf="themeStore.showBreadcrumb()"></app-breadcrum>
      <div>
        <router-outlet></router-outlet>
      </div>
    </section>

    <!-- ADDITIONAL COMPONENTS-->
    <app-back-to-top></app-back-to-top>
    <app-alert [id]="'display-alert-toast'" [message]="alert.message" [icon]="alert.icon" [urlLink]="alert.urlLink" [type]="alert.type" [position]="alert.position"></app-alert>
    <app-wishlist-delete-confirmation-modal></app-wishlist-delete-confirmation-modal>
    <app-product-variant-selection-modal></app-product-variant-selection-modal>
  </main>
  <!-- PAGE BOTTOM  -->
  <app-footer *ngIf="themeStore.showFooter()"></app-footer>

  <!-- VERSION INFO BOX -->
  <div *ngIf="showVersion" class="version">
    <span *ngIf="jwtService.connectedAsDelegate$ | async">Delegate</span>
    <span *ngIf="(jwtService.connectedAsDelegate$ | async) === false">Client</span>
    <br />{{ version }}<br />
    <button *ngIf="jwtService.hasDelegateRole$ | async" (click)="jwtService.switchRole()">switchRole</button>
  </div>
  <!-- CONNECT AS BANNER -->
  <app-connect-as *ngIf="!environment.isSandbox && !environment.production" [showForm]="false" [showBanner]="true" [connectedAsName]="userStore.profile()?.displayName" />
  <!-- SAND BOX WARNING BAR-->
  <app-sandbox-bar *ngIf="isSandbox"></app-sandbox-bar>
  <!-- CONTEXT REDIRECTION MODALE -->
  <app-modal id="redirectionModal" [title]="'REDIRECTION.TITLE' | translate" template="contextRedirectionModal" [isClosable]="false"></app-modal>
  <!-- CONGRATULATION MODALE -->
  <app-modal [id]="'congratulationsModal'" template="congratulationsModal" modalClass="congratulationsModal"></app-modal>

  <app-flow-error *ngIf="resourcesStore.isLoaded() && globalError" [error]="globalError" />
</ng-container>
