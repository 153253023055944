import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';

export type CountryFinancesResponse = {
  sectorLeaders: {
    delegateId: string;
    name: string;
    profileImage: string;
    openedDemos: number;
    turnoverConfirmed: number;
    turnoverTransferred: number;
    groupLeaders: {
      delegateId: string;
      name: string;
      profileImage: string;
      openedDemos: number;
      turnoverConfirmed: number;
      turnoverTransferred: number;
    }[];
  }[];
};

export type GroupLeaderFinancesResponse = {
  delegateId: string;
  name: string;
  openedDemos: number;
  profileImage: string;
  turnoverConfirmed: number;
  turnoverTransferred: number;
  demos: {
    delegateName: string;
    demoId: string;
    demoCode: string;
    delegateId: string;
    profileImage: string;
    date: Date;
    turnoverConfirmed: number;
    turnoverTransferred: number;
  }[];
};

export type DemoFinancesResponse = {
  delegateName: string;
  demoId: string;
  demoCode: string;
  delegateId: string;
  profileImage: string;
  date: Date;
  turnover: number;
  turnoverConfirmed: number;
  turnoverTransferred: number;
  confirmedCarts: number;
  finalizedCarts: number;
  nonFinalizedCarts: number;
  attendees: number;
};

export interface DashboardState {
  country: CountryFinancesResponse;
  groupLeader: GroupLeaderFinancesResponse;
  demo: DemoFinancesResponse;
}

export const initialState: DashboardState = {
  country: null,
  groupLeader: null,
  demo: null,
};

const MOCK_BASE_URL = './assets/mock';
export const DashboardStore = signalStore(
  { providedIn: 'root' },
  withDevtools('dashboard'),
  withState(initialState),
  withMethods((store, http = inject(HttpClient)) => ({
    async getDashboardForCountry() {
      const data = await firstValueFrom(http.get<CountryFinancesResponse>(`${MOCK_BASE_URL}/country.json`));
      patchState(store, { country: data });
    },
    async getDashboardForCdg() {
      const data = await firstValueFrom(http.get<GroupLeaderFinancesResponse>(`${MOCK_BASE_URL}/cdg.json`));
      patchState(store, { groupLeader: data });
    },
    async getDashboardForDemo() {
      const data = await firstValueFrom(http.get<DemoFinancesResponse>(`${MOCK_BASE_URL}/demo.json`));
      patchState(store, { demo: data });
    },
  }))
);
