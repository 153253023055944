<div *ngIf="!userStore.isImpersonated() && showForm" class="flex ai-center j-center gap-10 connect-as-body">
  <label>Se connecter en tant que : </label>
  <input [(ngModel)]="connectAsId" />
  <div class="cta-container">
    <button (click)="connectAs()"><label>Se connecter</label></button>
  </div>
</div>
<div *ngIf="userStore.isImpersonated() && showBanner" class="flex ai-center j-center gap-10 connect-as-body connect-as-banner">
  Attention, vous êtes connecté en tant que {{ connectedAsName }}!!
  <div class="cta-container">
    <button (click)="logConnectAsOut()"><label>Se déconnecter</label></button>
  </div>
</div>
