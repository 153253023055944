import { Component, inject } from '@angular/core';
import { ScreenService } from 'src/app/core/services/screen.service';
import { ContextStore } from '../../../stores/context.store';

@Component({
  selector: 'app-booster-access',
  templateUrl: './booster-access.component.html',
  styleUrls: ['./booster-access.component.scss'],
})
export class BoosterAccessComponent {
  readonly contextStore = inject(ContextStore);

  constructor(public screen: ScreenService) {}
}
