import { inject, Injectable } from '@angular/core';
import { UserStore } from '../../../stores/user.store';
import { environment } from '../../../../environments/environment';

declare global {
  interface Window {
    cookieStore: CookieStore;
  }
}
interface CookieChangeEvent {
  changed: Array<{ name: string; value: string }>;
  deleted: Array<{ name: string }>;
}

interface CookieStore {
  get(name: string): Promise<{ name: string; value: string } | undefined>;
  set(cookie: { name: string; value: string }): Promise<void>;
  delete(name: string): Promise<void>;
  addEventListener(event: 'change', callback: (event: CookieChangeEvent) => void): void;
}

@Injectable({
  providedIn:'root'
})
export class CookieDetectionService {
  userStore = inject(UserStore)

  constructor(){
    this.listenForCookieChanges()
  }

  private listenForCookieChanges(){
    if("cookieStore" in window){
      console.log("=====> Listen for cookie changes <=====")
      window.cookieStore.addEventListener('change', (event) => {
        const impersonatedDeleted = event.deleted?.find(d => d.name=="victoria_jwt_token_impersonated")
        if(impersonatedDeleted){
          if(!environment.production) alert("DEBUG INFO: Impersonated Delegate Session Exipred")
          this.userStore.logImpersonateUserOut()
        }
      });
    }
  }
}
