import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { ScreenService } from '../core/services/screen.service';
import { computed, inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

export interface ThemeState {
  showPinkBackground: boolean;
  showLargeScreen: boolean;
  showLargeScreenForMobileOnly: boolean;
  showFullScreen: boolean;
  menuOpened: boolean;
  userMenuOpened: boolean;
  showHeader: boolean;
  showJumbotron: boolean;
  showFooter: boolean;
  showNewsletter: boolean;
  showFooterMenu: boolean;
  showFooterLegal: boolean;
  showBreadcrumb: boolean;
  showDemoBanner: boolean;
  showVersion: boolean;
  loaded: boolean;
}

export const initialState: ThemeState = {
  showPinkBackground: false,
  showLargeScreen: false,
  showLargeScreenForMobileOnly: false,
  showFullScreen: false,
  menuOpened: false,
  userMenuOpened: false,
  showVersion: false,
  showHeader: false,
  showJumbotron: false,
  showFooter: true,
  showNewsletter: false,
  showFooterMenu: false,
  showFooterLegal: true,
  showBreadcrumb: false,
  showDemoBanner: false,
  loaded: false,
};

export const ThemeStore = signalStore(
  { providedIn: 'root' },
  withDevtools('theme'),
  withState(initialState),
  withComputed((store, screen = inject(ScreenService)) => ({
    isMobile: computed(() => {
      return screen.isMobile;
    }),
  })),
  withMethods(store => ({
    async updateTheme(route: ActivatedRouteSnapshot) {
      patchState(store, { loaded: false });

      if (route.data) {
        const data = route.data as ThemeState;
        patchState(store, {
          ...initialState,
          ...data,
          loaded: true,
        });
      } else {
        patchState(store, {
          ...initialState,
          loaded: true,
        });
      }
    },
    //Menu and User Menu are strongly coupled, Open one imply to close the other, ALWAYS!
    openCloseMenu() {
      patchState(store, {
        menuOpened: !store.menuOpened(),
        userMenuOpened: false,
      });
    },
    openCloseUserMenu() {
      patchState(store, { userMenuOpened: !store.userMenuOpened(), menuOpened: false });
    },
  }))
);
