import { Component, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DataForm } from 'src/app/core/models/dataForm.model';
import { FormsService } from 'src/app/core/services/forms.service';
import { TranslateService } from '@ngx-translate/core';
import { UserStore } from '../../../../../../stores/user.store';
import { GenderObject, UpdateUserProfileRequest } from '@victoria-company/agora-client';
import { NotificationStore } from '../../../../../../stores/notification.store';
import { ResourcesStore } from '../../../../../../stores/resources.store';
import { DateOnly } from '@microsoft/kiota-abstractions';
import { ErrorResponse } from '../../../../../../shared/components/flow-error/flow-error.component';
import { ContextStore } from '../../../../../../stores/context.store';
@Component({
  selector: 'app-mydatas',
  templateUrl: './mydatas.component.html',
  styleUrls: ['./mydatas.component.scss'],
})
export class MydatasComponent implements OnInit {
  readonly userStore = inject(UserStore);
  readonly contextStore = inject(ContextStore);
  readonly notificationStore = inject(NotificationStore);
  readonly resourcesStore = inject(ResourcesStore);
  readonly translate = inject(TranslateService);

  generalForm: UntypedFormGroup;
  updateProfileStatus: number = 0;
  operationPending: boolean = false;
  resources: any;

  birthdateModalOpened = false;
  globalError: ErrorResponse;

  currentActionTypeId = 0;

  public get form() {
    return this.generalForm?.controls;
  }

  constructor(
    private fb: UntypedFormBuilder,
    public fs: FormsService
  ) {}

  ngOnInit() {
    this.generalForm = this.fs.getDataForms(this.fb, new DataForm());
    this.setFormData();
  }

  private setFormData() {
    const profile = this.userStore.profile();
    if (!profile) return;

    this.generalForm.patchValue(this.userStore.profile());

    if (profile.birthDate) {
      const birthDate = profile.birthDate.toString().split('-');
      this.generalForm.controls.birthDate.setValue(`${birthDate[2].split('T')[0]}-${birthDate[1]}-${birthDate[0]}`);
      if (this.generalForm.controls.birthDate.value) this.generalForm.controls.birthDate.disable();
    }

    this.generalForm.controls.firstName.setValue(profile.firstname);
    this.generalForm.controls.lastName.setValue(profile.lastname);
    this.generalForm.controls.gender.setValue(profile.gender);
    this.generalForm.controls.langage.setValue(profile.locale);

    const defaultAddress = profile.addresses.find(add => add.type === 'Main');

    if (defaultAddress) {
      this.generalForm.patchValue(defaultAddress);
      this.generalForm.controls.country.setValue(defaultAddress.countryCode);
      this.generalForm.controls.mobile.setValue(defaultAddress.mobile);
      this.generalForm.controls.phone.setValue(defaultAddress.phone);
    }
  }

  public async update() {
    this.updateProfileStatus = 0;

    if (this.generalForm.valid) {
      let birthDate: string;
      this.operationPending = true;

      if (this.generalForm.get('birthDate').value) {
        const birthDateParts = this.generalForm.get('birthDate').value.split('-');
        birthDate = `${birthDateParts[2]}-${birthDateParts[1]}-${birthDateParts[0]}`;
      }

      let request: UpdateUserProfileRequest = {
        firstname: this.generalForm.get('firstName').value,
        lastname: this.generalForm.get('lastName').value,
        address1: this.generalForm.get('address1').value,
        address2: this.generalForm.get('address2').value,
        city: this.generalForm.get('city').value,
        zipCode: this.generalForm.get('zipCode').value,
        country: this.generalForm.get('country').value?.toUpperCase(),
        gender: this.generalForm.get('gender').value,
        locale: this.generalForm.get('langage').value,
        mobile: this.generalForm.get('mobile').value,
        phone: this.generalForm.get('phone').value,
        email: this.userStore.profile()?.email,
        birthDate: birthDate ? DateOnly.parse(birthDate) : undefined,
      };
      // dto.defaultAddressId = this.generalForm.get('defaultAddressId').value;

      try {
        this.globalError = undefined;
        await this.userStore.updateProfile(request);
        this.operationPending = false;
      } catch (err) {
        this.globalError = err;
        this.updateProfileStatus = 1;
        this.operationPending = false;
      }
    } else this.fs.checkFormAndDisplayError(this.generalForm);
  }

  openBirthDateModal() {
    this.currentActionTypeId = 13;
    this.birthdateModalOpened = true;
  }

  closeBirthDateModal() {
    this.birthdateModalOpened = false;
  }

  async saveBirthdateFromModal(birthDate: Date) {
    try {
      this.globalError = undefined;
      await this.userStore.addBirthdateToProfile(birthDate, this.contextStore.contextId());
      this.closeBirthDateModal();
      this.updateBirthDate(birthDate);
    } catch (err) {
      this.globalError = err;
    }
  }

  private updateBirthDate(value: Date) {
    let strDate;
    if (value) {
      strDate = value.getDate() >= 10 ? value.getDate() : '0' + value.getDate();
      strDate += '-' + (value.getMonth() + 1 >= 10 ? (value.getMonth() + 1).toString() : '0' + (value.getMonth() + 1).toString());
      strDate += '-' + value.getFullYear().toString();
    }

    this.generalForm.controls.birthDate.setValue(strDate);
  }

  protected readonly GenderObject = GenderObject;
}
