import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContextStore } from '../../../../stores/context.store';
import { RGPDService } from '../../../../core/services/V2/rgpd.service';
import { GdprResponse } from '@victoria-company/agora-client';
import { ErrorResponse } from '../../../../shared/components/flow-error/flow-error.component';

@Component({
  selector: 'app-opt-in',
  templateUrl: './opt-in.component.html',
  styleUrls: ['./opt-in.component.scss'],
})
export class OptInComponent implements OnInit {
  readonly contextStore = inject(ContextStore);
  private activeRoute = inject(ActivatedRoute);
  private rgpdService = inject(RGPDService);

  public data: GdprResponse;
  public globalError: ErrorResponse;

  async ngOnInit() {
    const gdprId = this.activeRoute.snapshot.params?.gdprId;

    this.data = await this.rgpdService.getRGPDFromDetailsId(gdprId).catch(e => {
      this.globalError = e;
      return null;
    });

    if (this.data) {
      const connection = this.data.delegateConnections[0];
      if (connection && !connection.isOptIn) this.rgpdService.setVisitorDelegateConnection(this.data?.id, connection.delegateId).catch(e => (this.globalError = e));
    }
  }
}
