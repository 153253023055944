import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ScreenService } from '../../../core/services/screen.service';

@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.scss'],
})
export class ModalsComponent {
  @Input() id: string = 'default-modal';
  @Input() title: string;
  @Input() closable = true;
  @Input() opened = true;

  //Use to custom modal rendering s.a. width, height
  @Input() customClass: string = '';
  @Input() customTitleClass: string = '';
  @Input() customStyle: object = {};
  //Set as true for agora modal, false for former club modals
  @Input() thinPadding = false;
  //Club titles are aligned left, Agora title are centered
  @Input() centerTitle = false;
  //Club titles are Normal case; Agora title are mostly uppercase
  @Input() uppercaseTitle = false;

  @Output() requestCloseModal = new EventEmitter();

  private readonly screen = inject(ScreenService);

  public close() {
    this.requestCloseModal.emit();
  }

  closeModalFromOutside(el: MouseEvent) {
    const target = el.target as HTMLElement;
    if (target.id == this.id && !this.screen.isMobile) {
      this.close();
    }
  }
}
