import { Component, inject, OnInit } from '@angular/core';
import { CheckoutOrValidationProgressionStep } from '../../../../core/enums/checkoutOrValidationProgressionStep.enum';
import { CartPaymentResponse, CartResponse, PaymentResponse, PaymentResponseStatusObject, PaymentTypeObject } from '@victoria-company/agora-client';
import { ScreenService } from '../../../../core/services/screen.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from '../../../../core/services/V2/cart.service';
import { CheckoutType } from '../../../../core/enums/checkout-type.enum';
import { UserStore } from '../../../../stores/user.store';
import { ContextStore } from '../../../../stores/context.store';
import { ResourcesStore } from '../../../../stores/resources.store';
import { PaymentsService } from '../../../../core/services/V2/payments.service';
import { ErrorResponse } from '../../../../shared/components/flow-error/flow-error.component';
const MAX_OPEN_REQUEST = 12;
@Component({
  selector: 'app-public-order-payment-confirmation',
  templateUrl: './public-order-payment-confirmation.component.html',
  styleUrls: ['./public-order-payment-confirmation.component.scss'],
})
export class PublicOrderPaymentConfirmationComponent implements OnInit {
  public resourcesStore = inject(ResourcesStore);

  public userStore = inject(UserStore);
  public contextStore = inject(ContextStore);
  readonly paymentsService = inject(PaymentsService);

  private openRequest = 0;
  public paymentLoaded = false;
  public globalError: ErrorResponse;

  constructor(
    public screen: ScreenService,
    private router: Router,
    private activated: ActivatedRoute,
    private cartService: CartService
  ) {}

  public cart: CartResponse;
  public payment: PaymentResponse;

  async ngOnInit() {
    const lyfPayStatus = this.activated.snapshot.queryParams.status ?? '';
    const params = this.activated.snapshot.params;

    if (params.cartId) {
      const cart = await this.cartService.getCartByCartId(params.cartId);
      this.cart = cart;

      if (cart) {
        this.cart = cart;

        if (this.cart.totalToPay == 0 && this.cart.status == 'Confirmed') {
          this.paymentLoaded = true;
          return;
        }

        let payment: CartPaymentResponse;
        //Search for Digital Payment Open Or Success
        const digitalPayments = this.cart.cartPayments?.filter(p => p.type == 'Digital' && (p.result == 'Open' || p.result == 'Success'));
        if (digitalPayments.length) {
          payment = digitalPayments[0];
        } else {
          payment = this.cart.cartPayments?.find(p => p.type == 'HandledByDelegate' && (p.result == 'Open' || p.result == 'Success'));
        }

        // const sortedPayments =
        //   this.cart.cartPayments?.sort((a, b) => {
        //     if (a.paymentDate > b.paymentDate) return -1;
        //     else return 0;
        //   }) ?? [];

        if (payment?.result == 'Open' && lyfPayStatus == 'canceled') {
          //Handle LyfPayPayment update
          try {
            await this.paymentsService.cancelPayment(payment?.paymentId);
            this.globalError = undefined;
          } catch (err) {
            this.globalError = err;
          }

          return await this.handlingPayment(payment?.paymentId, cart.id);
        } else if (payment && payment?.paymentId) {
          return await this.handlingPayment(payment?.paymentId, cart.id);
        } else {
          this.paymentLoaded = true;
        }
      } else await this.router.navigate([`/${this.contextStore.locale()}/public/cart/${params.cartId}/checkout`]);
    }
  }

  private async handlingPayment(paymentId: string, cartId: string) {
    try {
      //Get Payment
      this.payment = await this.cartService.getCartPayment(paymentId);
      //Refresh Cart State
      this.cart = await this.cartService.getCartByCartId(cartId);
      this.globalError = undefined;
      if (!this.payment) return this.router.navigateByUrl(`/${this.contextStore.locale()}/public/cart/${this.cart.id}/checkout?p=${PaymentResponseStatusObject.Unknown}`);
      this.paymentLoaded = true;
    } catch (err) {
      this.globalError = err;
    }

    switch (this.payment.status) {
      case PaymentResponseStatusObject.Open:
        if (this.payment.type == PaymentTypeObject.HandledByDelegate) return;
        else if (this.openRequest >= MAX_OPEN_REQUEST) {
          return this.router.navigateByUrl(`/${this.contextStore.locale()}/public/cart/${this.cart.id}/checkout?p=${PaymentResponseStatusObject.Unknown}`);
        } else {
          this.openRequest++;
          return setTimeout(async () => await this.handlingPayment(this.payment.id, cartId), 5000);
        }
      case PaymentResponseStatusObject.Pending:
        return setTimeout(async () => await this.handlingPayment(this.payment.id, cartId), 5000);
      case PaymentResponseStatusObject.Expired:
      case PaymentResponseStatusObject.Failed:
      case PaymentResponseStatusObject.Canceled:
      case PaymentResponseStatusObject.Unknown:
        return this.router.navigateByUrl(`/${this.contextStore.locale()}/public/cart/${this.cart.id}/checkout?p=${this.payment.status}`);
    }
  }

  protected readonly CheckoutType = CheckoutType;
  protected readonly CheckoutOrValidationProgressionStep = CheckoutOrValidationProgressionStep;
  protected readonly PaymentTypeObject = PaymentTypeObject;
}
