<app-modals [id]="'encoding-demo-code-modal'" [opened]="isOpened" [title]="resourcesStore.i18n().resources.popupFindHomeparty.mainTitle | removeHtmlTag" (requestCloseModal)="close()">
  <div class="modal-body promo">
    <p>{{ resourcesStore.i18n().resources.popupFindHomeparty.description | removeHtmlTag }}</p>
    <div class="promo-form">
      <label>{{ resourcesStore.i18n().resources.popupFindHomeparty.homepartyLabel | removeHtmlTag }}</label>
      <div class="flex gap-8 promo">
        <div [class.error]="error">
          <input type="text" [(ngModel)]="demoCode" />
        </div>
        <button class="btn btn-primary" (click)="addCodeToDemo()">{{ resourcesStore.i18n().resources.popupFindHomeparty.buttonLabel | removeHtmlTag }}</button>
      </div>
      <app-flow-error *ngIf="error" [error]="error" [showError]="true" />
    </div>
  </div>
</app-modals>
