
<app-modals [id]="'add-birthdate-modal'" [opened]="opened" (requestCloseModal)="close()" [customClass]="'add-birthdate-modal'">
  <div class="modal-birthdate">
    <div class="flex column center-all">
      <div class="gift flex center-all">
        <svg viewBox="0 0 36 36" class="icon">
          <use xlink:href="#icon-gift"></use>
        </svg>
      </div>
      <p class="title">{{ 'STATUS.MOREPOINTS.ADD_BIRTHDATE.TITLE' | translate }}</p>
      <p class="description">{{ 'STATUS.MOREPOINTS.ADD_BIRTHDATE.LABELXPOINTS' | translate: { X: 50 } }}</p>
      <div class="input-date flex center-all">
        <form [formGroup]="birthDateForm" class="flex column center-all">
          <input class="" type="text" [inputMask]="dateInputMask" formControlName="birthDate" />
          <div
            class="form-error-container"
            *ngIf="bForm.birthDate.errors && (bForm.birthDate.dirty || bForm.birthDate.touched) && (bForm.birthDate.errors.dateMinimum || bForm.birthDate.errors.dateMaximum)">
            {{ 'GLOBAL.ERROR.DATERANGE' | translate }}
          </div>
        </form>
      </div>
      <div class="flex ai-center">
        <button class="btn btn-primary" (click)="addBirthDate()"><div *ngIf="isBusy" class="loader-icon loader loader-mini"></div>{{ 'MODAL.BIRTHDATE.ACTION' | translate | uppercase }}</button>
      </div>
    </div>
  </div>
</app-modals>
