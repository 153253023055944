import { computed, inject } from '@angular/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import {
  CartStatusObject,
  CreateAttendeeRequest,
  DelegateResponse,
  DemoAttendeeResponse,
  DemoHostessSummaryResponse,
  DemoNotificationLevel,
  DemoResponse,
  DemoRole,
  DemoSummaryResponse,
  GetDemoAttendeesOverviewResponse,
  KitType,
  ProductSearchModel,
  UpdateUserProfileRequest,
} from '@victoria-company/agora-client';
import { addDays, addHours, subDays } from 'date-fns';
import { CommonService } from 'src/app/core/services/V2/common.sevice';
import { DemoService } from 'src/app/core/services/V2/demo.service';
import { getDemoLink } from 'src/app/core/utils/filter.utils';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { ContextStore } from './context.store';
import { WishlistService } from '../core/services/V2/wishlist.service';
import { CartService } from '../core/services/V2/cart.service';
import { SocketService } from '../core/services/V2/sockets.service';
import { NotificationStore } from './notification.store';
import { UserService } from '../core/services/V2/user.service';
import { SaveGiftsRequest } from '../shared/components/hostessGift/hostess-select-gifts/hostess-select-gifts.component';
import { DocumentsStore } from './documents.store';
import { TranslateService } from '@ngx-translate/core';
import { DemoHostessGiftsResponse_DemoSelectedGift, GiftCategory } from '@victoria-company/agora-client/dist/models';
import { CatalogService } from '../core/services/V2/catalog.service';
import { getProductsFromGiftsResponse } from '../core/utils/common.utils';
import { ActivityNotification, ActivityType } from '../core/types/activityNotification.type';
import { NotificationIcon, NotificationStyle } from '../core/types/notification.options.type';
import { ErrorResponse } from '../shared/components/flow-error/flow-error.component';
import { GoogleAnalyticService } from '../core/services/google-analytic.service';

export type DemoHostessGifts_GiftsForCategory_GiftChoice = {
  productId?: string | null;
  missingTurnOver?: number | null;
  product?: ProductSearchModel | null;
};

export type DemoHostessGifts_GiftsForCategory = {
  category?: GiftCategory | null;
  choices?: DemoHostessGifts_GiftsForCategory_GiftChoice[] | null;
};

export type DemoHostessGifts = {
  giftsForCategories?: DemoHostessGifts_GiftsForCategory[] | null;
  selectedGifts?: DemoHostessGiftsResponse_DemoSelectedGift[] | null;
};

export interface DemoState {
  demo: DemoResponse;
  hostessGifts: DemoHostessGifts;
  attendee: DemoAttendeeResponse;
  roles: DemoRole[];
  delegate: DelegateResponse;
  hostessSummary: DemoHostessSummaryResponse;
  shareQrCodeModal: {
    isOpened: boolean;
    isShareDemoLink: boolean;
    isSharePaymentLink: boolean;
  };
  addProductToClientsModal: {
    opened: boolean;
    product: ProductSearchModel;
  };
  addVariantToClientsModal: {
    opened: boolean;
    product: ProductSearchModel;
  };
  commentModal: {
    opened: boolean;
    attendee: DemoAttendeeResponse;
    isBusy: boolean;
    error: ErrorResponse;
  };
  overview: GetDemoAttendeesOverviewResponse;
  management: {
    attendees: DemoAttendeeResponse[];
    summary: DemoSummaryResponse;
  };
}

export const initialState: DemoState = {
  demo: null,
  hostessGifts: null,
  attendee: null,
  roles: [],
  delegate: null,
  hostessSummary: null,
  shareQrCodeModal: { isShareDemoLink: false, isSharePaymentLink: null, isOpened: false },
  addProductToClientsModal: {
    opened: false,
    product: null,
  },
  addVariantToClientsModal: {
    opened: false,
    product: null,
  },
  commentModal: {
    opened: false,
    attendee: null,
    isBusy: false,
    error: null,
  },
  overview: null,
  management: null,
};

export const DemoStore = signalStore(
  { providedIn: 'root' },
  withDevtools('demo'),
  withState(initialState),
  withComputed(({ demo, roles, attendee, management }, contextStore = inject(ContextStore)) => ({
    availableKitTypes: computed(() => (demo ? ['Full', 'A', 'B', 'C', ...(demo().contextId != 3 ? ['X', 'Y'] : [])] : []) as KitType[]),
    canActivateDemo: computed(() => {
      if (!demo() || !roles().includes('Delegate')) return false;
      const now = new Date();
      const endDemoValidity = addDays(demo().date, 8);
      const startDemoValidity = subDays(demo().date, 3);
      return demo().status == 'PreSale' && now >= startDemoValidity && now <= endDemoValidity; //TODO Check if logic is OK,
    }),
    showStartAlert: computed(() => {
      if (!demo()) return false;
      const now = new Date();
      const endDemoValidity = addDays(demo().date, 8);
      const startDemoValidity = subDays(demo().date, 3);
      return demo().status == 'PreSale' && now >= startDemoValidity && now <= endDemoValidity;
    }),
    showEndAlert: computed(() => {
      if (!demo()) return false;
      const now = new Date();
      const demoDatePlus3Hours = addHours(demo().date, 3);
      return demo().status == 'Opened' && now >= demoDatePlus3Hours;
    }),
    qrCodeModalLink: computed(() => {
      return getDemoLink(contextStore.locale(), demo()?.code, demo()?.delegateCode, demo()?.hostessDisplayName);
    }),
    isDelegate: computed(() => roles()?.includes('Delegate')),
    isHostess: computed(() => attendee()?.role == 'Hostess'),
    demoIsOpen: computed(() => demo()?.status == 'Opened'),
    demoIsPreSale: computed(() => demo()?.status == 'PreSale'),
    demoClosed: computed(() => demo()?.status == 'Closed'),
    demoIsPostSale: computed(() => demo()?.status == 'PostSale'),
    demoIsReadOnly: computed(() => demo()?.status == 'Canceled' || demo()?.status == 'Closed' || demo()?.status == 'PostSale'),
    lscCount: computed(() => {
      if (management() && roles()?.includes('Delegate')) {
        return management()
          .attendees?.flatMap(a => a.carts)
          ?.flatMap(c => c.cartItems)
          ?.filter(ci => ci.isLsc).length;
      }

      return 0;
    }),
  })),
  withComputed(({ isDelegate, management, attendee, demoClosed }) => ({
    isHostessLocked: computed(() => {
      const hostessCart = isDelegate()
        ? management()
            ?.attendees?.flatMap(a => a.carts)
            ?.find(c => c.isHostessCart)
        : attendee()?.carts?.find(c => c.isHostessCart);

      if (!hostessCart && !demoClosed()) return false;
      else if (!demoClosed()) return hostessCart.status == 'Finalized' || hostessCart.status == 'Confirmed';
      else return true;
    }),
    hostess: computed(() => {
      return isDelegate() ? management()?.attendees?.find(a => a.role == 'Hostess') : attendee()?.role == 'Hostess' ? attendee() : undefined;
    }),
  })),
  withMethods((store, demoService = inject(DemoService), catalogService = inject(CatalogService)) => ({
    async getAttendeeOverview(demoCodeOrId: string) {
      const overview = await demoService.getAttendeeOverview(demoCodeOrId);

      if (store.attendee()) overview.attendees = overview?.attendees?.filter(a => a.userId != store.attendee()?.user?.id);

      patchState(store, { overview });
    },
    async getHostessGifts(demoCodeOrId: string, locale: string, contextId: number) {
      const gifts = (await demoService.getGiftsForDemo(demoCodeOrId, locale)) as DemoHostessGifts;
      await getProductsFromGiftsResponse(gifts, locale, contextId, catalogService);

      patchState(store, { hostessGifts: gifts });
    },
    async loadHostessSummary(demoCodeOrId: string) {
      const hostessSummary = await demoService.getHostessSummary(demoCodeOrId);
      patchState(store, { hostessSummary });
    },
  })),
  withMethods(
    (
      store,
      contextStore = inject(ContextStore),
      documentStore = inject(DocumentsStore),
      demoService = inject(DemoService),
      commonService = inject(CommonService),
      socketService = inject(SocketService),
      catalogService = inject(CatalogService),
      notificationStore = inject(NotificationStore),
      translate = inject(TranslateService)
    ) => ({
      resetDemo(): void {
        patchState(store, { ...initialState });
      },
      openShareDemoQrCodeModal(): void {
        patchState(store, state => ({
          shareQrCodeModal: {
            ...state.shareQrCodeModal,
            isOpened: true,
            isShareDemoLink: true,
          },
        }));
      },
      closeShareDemoQrCodeModal(): void {
        patchState(store, state => ({
          shareQrCodeModal: {
            ...state.shareQrCodeModal,
            isOpened: false,
            isShareDemoLink: false,
            isSharePaymentLink: false,
            cart: null,
          },
        }));
      },
      async setAttendeeWillAttend(userId: string, value: boolean) {
        await demoService.setAttendeeWillAttend(store.demo()?.code, userId, value);
      },
      async setAttendeeCanBeDelegate(userId: string, value: boolean) {
        await demoService.setAttendeeCanBeDelegate(store.demo()?.code, userId, value);
      },
      async setAttendeeCanBeHostess(userId: string, value: boolean) {
        await demoService.setAttendeeCanBeHostess(store.demo()?.code, userId, value);
      },
      async loadDemo(demoCodeOrId: string, locale: string, contextId: number): Promise<void> {
        const currentDemo = store.demo();
        const isLoadingAnotherDemo = currentDemo && demoCodeOrId != currentDemo.id && demoCodeOrId != currentDemo.code;
        const isFirstDemoLoad = !currentDemo;

        if (isLoadingAnotherDemo) {
          documentStore.resetDocuments();
          patchState(store, () => ({ ...initialState }));
        }

        const loadDemoFromApi = async (demoCodeOrId: string) => {
          const demoResponse = await demoService.getDemoByCodeOrId(demoCodeOrId);
          if (demoResponse == null) return;

          const attendee = await demoService.getConnectedDemoAttendee(demoResponse.demo.code);
          const delegate = await commonService.getDelegateProfileAsPromise(demoResponse.demo.delegateId);
          const demoAttendeesResponse = demoResponse.roles.includes('Delegate') ? await demoService.getDemoAttendees(demoCodeOrId) : null;
          const summary = demoResponse.roles.includes('Delegate') ? await demoService.getDemoSummary(demoCodeOrId) : null;
          const hostessSummary = demoResponse.roles.includes('Hostess') ? await demoService.getHostessSummary(demoCodeOrId) : null;

          patchState(store, {
            demo: demoResponse.demo,
            roles: demoResponse.roles,
            attendee: attendee ? attendee : null,
            delegate,
            hostessSummary,
            management: {
              attendees: demoAttendeesResponse?.attendees ?? [],
              summary: summary,
            },
          });
        };

        const handleDemoNotification = async (activity: ActivityNotification) => {
          switch (activity.type) {
            case ActivityType.AddToCart:
              console.log('demo add to cart : ', activity);
              if (
                activity.actorUserId == activity.affectedUserId &&
                store
                  .management()
                  .attendees?.map(a => a.user?.id)
                  ?.includes(activity.affectedUserId)
              ) {
                const productVariantId = activity.metadata.productVariantId;
                const product = await catalogService.getProductsByVariantIdsPromise(contextStore.contextId(), contextStore.locale(), [productVariantId]);
                notificationStore.notify({
                  message: translate.instant('SOCKET.NOTIFICATION.DEMO.XADDEDYTOCART', { X: activity.actorName, Y: product.products[0]?.name }),
                  icon: NotificationIcon.INFO,
                  style: NotificationStyle.INFO,
                });
              }
              break;
            case ActivityType.AddToWishlist:
              console.log('demo add to wishlist : ', activity);
              if (
                activity.actorUserId == activity.affectedUserId &&
                store
                  .management()
                  .attendees?.map(a => a.user?.id)
                  ?.includes(activity.affectedUserId)
              ) {
                const productId = activity.metadata.productId;
                const product = await catalogService.getProductsPromise(contextStore.contextId(), contextStore.locale(), [productId]);
                notificationStore.notify({
                  message: translate.instant('SOCKET.NOTIFICATION.DEMO.XADDEDYTOWISHLIST', { X: activity.actorName, Y: product.products[0]?.name }),
                  icon: NotificationIcon.INFO,
                  style: NotificationStyle.INFO,
                });
              }
              break;
            case ActivityType.RemoveFromCart:
              console.log('demo remove from cart : ', activity);
              if (
                activity.actorUserId == activity.affectedUserId &&
                store
                  .management()
                  .attendees?.map(a => a.user?.id)
                  ?.includes(activity.affectedUserId)
              ) {
                const productVariantId = activity.metadata.productVariantId;
                const product = await catalogService.getProductsByVariantIdsPromise(contextStore.contextId(), contextStore.locale(), [productVariantId]);
                notificationStore.notify({
                  message: translate.instant('SOCKET.NOTIFICATION.DEMO.XREMOVEDYFROMCART', { X: activity.actorName, Y: product.products[0]?.name }),
                  icon: NotificationIcon.INFO,
                  style: NotificationStyle.INFO,
                });
              }
              break;
            case ActivityType.RemoveFromWishlist:
              console.log('demo remove from wishlist : ', activity);
              if (
                activity.actorUserId == activity.affectedUserId &&
                store
                  .management()
                  .attendees?.map(a => a.user?.id)
                  ?.includes(activity.affectedUserId)
              ) {
                const productId = activity.metadata.productId;
                const product = await catalogService.getProductsPromise(contextStore.contextId(), contextStore.locale(), [productId]);
                notificationStore.notify({
                  message: translate.instant('SOCKET.NOTIFICATION.DEMO.XREMOVEDYFROMWISHLIST', { X: activity.actorName, Y: product.products[0]?.name }),
                  icon: NotificationIcon.INFO,
                  style: NotificationStyle.INFO,
                });
              }
              break;
            case ActivityType.CartStatusUpdated:
              if (
                activity.actorUserId == activity.affectedUserId &&
                store
                  .management()
                  .attendees?.map(a => a.user?.id)
                  ?.includes(activity.affectedUserId)
              ) {
                switch (activity.metadata.newStatus) {
                  case CartStatusObject.Finalized:
                  case CartStatusObject.Confirmed:
                    notificationStore.notify({
                      message: translate.instant('SOCKET.NOTIFICATION.DEMO.X' + activity.metadata.newStatus?.toUpperCase() + 'CART', { X: activity.actorName }),
                      icon: NotificationIcon.INFO,
                      style: NotificationStyle.INFO,
                    });
                    break;
                }
              }
              break;
            case ActivityType.CartItemVariantUpdated:
              console.log('Demo CartItemVariantUpdated : ', activity);
              if (
                activity.actorUserId == activity.affectedUserId &&
                store
                  .management()
                  .attendees?.map(a => a.user?.id)
                  ?.includes(activity.affectedUserId)
              ) {
                const productVariantId = activity.metadata.productVariantId;
                const product = await catalogService.getProductsByVariantIdsPromise(contextStore.contextId(), contextStore.locale(), [productVariantId]);
                notificationStore.notify({
                  message: translate.instant('SOCKET.NOTIFICATION.DEMO.XUPDATEDYFROMCART', { X: activity.actorName, Y: product.products[0]?.name }),
                  icon: NotificationIcon.INFO,
                  style: NotificationStyle.INFO,
                });
              }
              break;
            case ActivityType.BecomeDelegateRequested: {
              let internationalisationKey = '';
              //User Request
              if (activity.actorUserId == activity.affectedUserId) {
                internationalisationKey = 'SOCKET.NOTIFICATION.DEMO.BECOMEDELEGATE_CLIENT';
              }
              //Delegate request
              else if (activity.actorUserId != activity.affectedUserId) {
                internationalisationKey = 'SOCKET.NOTIFICATION.DEMO.BECOMEDELEGATE_DELEGATE';
              }

              if (
                store
                  .management()
                  .attendees?.map(a => a.user?.id)
                  ?.includes(activity.affectedUserId)
              )
                notificationStore.notify({
                  message: translate.instant(internationalisationKey, { X: activity.actorName }),
                  icon: NotificationIcon.INFO,
                  style: NotificationStyle.INFO,
                });
              break;
            }
            case ActivityType.BecomeDelegateCancelled: {
              let internationalisationKey = '';
              //User Request
              if (activity.actorUserId == activity.affectedUserId) {
                internationalisationKey = 'SOCKET.NOTIFICATION.DEMO.CANCEL_BECOMEDELEGATE_CLIENT';
              }
              //Delegate request
              else if (activity.actorUserId != activity.affectedUserId) {
                internationalisationKey = 'SOCKET.NOTIFICATION.DEMO.CANCEL_BECOMEDELEGATE_DELEGATE';
              }

              if (
                store
                  .management()
                  .attendees?.map(a => a.user?.id)
                  ?.includes(activity.affectedUserId)
              )
                notificationStore.notify({
                  message: translate.instant(internationalisationKey, { X: activity.actorName }),
                  icon: NotificationIcon.INFO,
                  style: NotificationStyle.INFO,
                });
              break;
            }
            case ActivityType.BecomeHostessRequested: {
              let internationalisationKey = '';
              //User Request
              if (activity.actorUserId == activity.affectedUserId) {
                internationalisationKey = 'SOCKET.NOTIFICATION.DEMO.BECOMEHOSTESS_CLIENT';
              }
              //Delegate request
              else if (activity.actorUserId != activity.affectedUserId) {
                internationalisationKey = 'SOCKET.NOTIFICATION.DEMO.BECOMEHOSTESS_DELEGATE';
              }

              if (
                store
                  .management()
                  .attendees?.map(a => a.user?.id)
                  ?.includes(activity.affectedUserId)
              )
                notificationStore.notify({
                  message: translate.instant(internationalisationKey, { X: activity.actorName }),
                  icon: NotificationIcon.INFO,
                  style: NotificationStyle.INFO,
                });
              break;
            }
            case ActivityType.BecomeHostessCancelled: {
              let internationalisationKey = '';
              //User Request
              if (activity.actorUserId == activity.affectedUserId) {
                internationalisationKey = 'SOCKET.NOTIFICATION.DEMO.CANCEL_BECOMEHOSTESS_CLIENT';
              }
              //Delegate request
              else if (activity.actorUserId != activity.affectedUserId) {
                internationalisationKey = 'SOCKET.NOTIFICATION.DEMO.CANCEL_BECOMEHOSTESS_DELEGATE';
              }

              if (
                store
                  .management()
                  .attendees?.map(a => a.user?.id)
                  ?.includes(activity.affectedUserId)
              )
                notificationStore.notify({
                  message: translate.instant(internationalisationKey, { X: activity.actorName }),
                  icon: NotificationIcon.INFO,
                  style: NotificationStyle.INFO,
                });
              break;
            }
          }
        };

        await loadDemoFromApi(demoCodeOrId);

        if (isFirstDemoLoad) {
          console.log('WILL LISTEN FOR DEMO UPDATES IN LOAD DEMO');
          await socketService.listenForDemoUpdates(store.demo().id, {
            onDemoAttendeeCartRemovedFromDemo: (demoId, userId, cartId) => {
              console.log('cart removed from demo ', demoId, cartId);
              if (store.demo()?.id != demoId) return;

              if (store.attendee() && store.attendee()?.user.id == userId) {
                patchState(store, state => ({
                  attendee: {
                    ...state.attendee,
                    carts: [...state.attendee.carts.filter(c => c.id != cartId)],
                  },
                }));
              }

              if (store.management()) {
                patchState(store, state => ({
                  management: {
                    ...state.management,
                    attendees: state.management.attendees.map(attendee => ({
                      ...attendee,
                      carts: [...attendee.carts.filter(c => c.id != cartId)],
                    })),
                  },
                }));
              }
            },
            onDemoAttendeeCartChange: cart => {
              if (store.demo().id != cart.demoId) return;

              if (cart.userId == store.attendee()?.user.id) {
                patchState(store, state => ({
                  attendee: {
                    ...state.attendee,
                    carts: state.attendee.carts.map(c => c.id).includes(cart.id) ? state.attendee.carts.map(c => (c.id == cart.id ? cart : c)) : [cart, ...state.attendee.carts],
                  },
                }));
              }

              if (store.management()) {
                patchState(store, state => ({
                  management: {
                    ...state.management,
                    attendees: state.management.attendees.map(attendee => ({
                      ...attendee,
                      carts: (attendee.carts.map(c => c.id).includes(cart.id) || attendee.user?.id != cart.userId
                        ? attendee.carts.map(c => (c.id == cart.id ? cart : c))
                        : [cart, ...attendee.carts]
                      ).filter(c => c.status != 'Merged'),
                      //FROM OLD carts: [...(attendee.carts.some(c => c.id == cart.id) ? [] : [cart]), ...attendee.carts.map(c => (c.id == cart.id ? cart : c))],
                    })),
                  },
                }));
              }
            },
            onDemoAttendeeCartCreated: cart => {
              if (store.demo().id != cart.demoId) return;

              if (
                (store.management() &&
                  store
                    .management()
                    ?.attendees?.flatMap(a => a.carts)
                    ?.some(c => c.id == cart.id)) ||
                (store.attendee() && store.attendee()?.carts?.some(c => c.id == cart.id))
              )
                return;

              if (store.attendee() && store.attendee()?.user.id == cart.userId) {
                patchState(store, state => ({
                  attendee: {
                    ...state.attendee,
                    carts: [cart, ...state.attendee.carts],
                  },
                }));
              }

              if (store.management()) {
                patchState(store, state => ({
                  management: {
                    ...state.management,
                    attendees: state.management.attendees.map(attendee => ({
                      ...attendee,
                      carts: [...(attendee.user.id == cart.userId ? [cart] : []), ...attendee.carts],
                    })),
                  },
                }));
              }
            },
            onDemoAttendeeWishlistChange: (userId, wishlist) => {
              if (store.attendee() && store.attendee()?.user.id == userId) {
                patchState(store, state => ({
                  attendee: {
                    ...state.attendee,
                    productIdsInWishlist: wishlist.productIds,
                  },
                }));
              }

              if (store.management()) {
                patchState(store, state => ({
                  management: {
                    ...state.management,
                    attendees: state.management.attendees.map(attendee => ({
                      ...attendee,
                      productIdsInWishlist: attendee.user.id == userId ? wishlist.productIds : attendee.productIdsInWishlist,
                    })),
                  },
                }));
              }
            },
            onDemoHostessAttendeeJoined: demoAttendee => {
              patchState(store, () => ({
                overview: {
                  ...store.overview(),
                  attendees: store.overview()?.attendees ? [...(store.overview()?.attendees ?? []), demoAttendee] : [demoAttendee],
                },
              }));
            },
            onDemoAttendeeChange: async demoAttendeeChanged => {
              if (store.demo().id != demoAttendeeChanged.demoId) return;

              const isCurrentAttendee = store.attendee() && store.attendee()?.user.id == demoAttendeeChanged.userId;
              const isNewHostess = isCurrentAttendee && !store.isHostess() && demoAttendeeChanged.role == 'Hostess';

              if (isNewHostess) {
                console.log('New Hostess detected, reloading hostess environment');
                window.location.reload();
                return;
              }

              if (store.attendee() && store.attendee()?.role == 'Hostess' && store.overview() != null) {
                patchState(store, () => ({
                  overview: {
                    attendees: store.overview()?.attendees?.map(a => (a.userId == demoAttendeeChanged.userId ? { ...a, ...demoAttendeeChanged } : a)),
                  },
                }));
              }

              //Attendee == current Connected Client,
              if (isCurrentAttendee) {
                patchState(store, () => ({
                  attendee: {
                    ...store.attendee(),
                    ...demoAttendeeChanged,
                  },
                }));
              }

              if (store.isHostess() && store.overview() != null) {
                patchState(store, () => ({
                  overview: {
                    ...store.overview(),
                    attendees: store.overview()?.attendees?.map(attendee =>
                      attendee.userId == demoAttendeeChanged.userId
                        ? {
                            ...attendee,
                            couldBecomeDelegate: demoAttendeeChanged.couldBecomeDelegate,
                            couldBecomeHostess: demoAttendeeChanged.couldBecomeHostess,
                            willAttend: demoAttendeeChanged.willAttend,
                          }
                        : attendee
                    ),
                  },
                }));
              }

              if (store.isDelegate()) {
                //Management == current Connected Delegate
                patchState(store, () => ({
                  management: {
                    ...store.management(),
                    attendees: store.management().attendees.map(attendee =>
                      attendee?.user?.id != demoAttendeeChanged.userId
                        ? attendee
                        : {
                            ...attendee,
                            ...demoAttendeeChanged,
                          }
                    ),
                  },
                }));
              }
            },
            onDemoAttendeeJoined: demoAttendee => {
              patchState(store, state => ({
                management: {
                  ...state.management,
                  attendees: [demoAttendee, ...state.management.attendees.filter(a => a.user.id != demoAttendee.user.id)],
                },
              }));
            },
            onDemoChange: async demo => {
              patchState(store, {
                demo,
              });

              if (demo.status == 'PostSale' || demo.status == 'Closed') await loadDemoFromApi(demo.code);
            },
            onDemoHostessGiftsChanged: async (_demoId, gifts) => {
              console.log('Gifts changed');
              gifts = gifts as DemoHostessGifts;
              await getProductsFromGiftsResponse(gifts, locale, contextId, catalogService);

              patchState(store, { hostessGifts: gifts });
            },

            onDemoHostessSummaryChanged: async (_demoId, hostessSummary) => {
              patchState(store, { hostessSummary });
              //Reload hostess Cart if needed
              const hostessCart = store.attendee()?.carts?.find(c => c.isHostessCart);
              if (hostessCart && (hostessCart.status == 'Active' || hostessCart.status == 'Reopened')) {
                const attendee = await demoService.getConnectedDemoAttendee(store.demo().code);
                patchState(store, { attendee });
              }
            },
            onDemoSummaryChanged: (_demoId, summary) => {
              patchState(store, () => ({
                management: {
                  ...store.management(),
                  summary: {
                    ...store.management().summary,
                    ...summary,
                  },
                },
              }));
            },
            onDemoActivityOccurred: async (activity: ActivityNotification) => {
              await handleDemoNotification(activity);
            },
            onReconnected: () => {
              loadDemoFromApi(store.demo().id);
            },
          });
        }

        if (isLoadingAnotherDemo) {
          await socketService.switchListenForDemoUpdates(store.demo().id);
        }
      },
      async reloadDemoAttendee(userId?: string): Promise<void> {
        if (!store.demo()?.id) return;
        if (!userId) {
          const attendee = await demoService.getConnectedDemoAttendee(store.demo().code);

          patchState(store, { attendee });
        } else {
          const attendee = await demoService.getDemoAttendee(store.demo().code, userId);

          patchState(store, state => {
            const index = state.management.attendees.findIndex(a => a.user.id == userId);
            return {
              management: {
                ...state.management,
                attendees: index == -1 ? [...state.management.attendees, attendee] : [...state.management.attendees.slice(0, index), attendee, ...state.management.attendees.slice(index + 1)],
              },
            };
          });
        }
      },
    })
  ),
  withMethods((store, userService = inject(UserService), notificationStore = inject(NotificationStore), demoService = inject(DemoService), translate = inject(TranslateService)) => ({
    async syncDelcomOrders(demoCodeOrId: string) {
      await demoService.syncDelcomOrders(demoCodeOrId);
    },
    async saveAdditionalData(demoCodeOrId: string, amount: number) {
      await demoService.saveAdditionalData(demoCodeOrId, amount);
    },
    async saveHostessGift(demoCodeOrId: string, userId: string, gift: SaveGiftsRequest) {
      await demoService.saveHostessGift(demoCodeOrId, userId, gift);
    },
    async requestBecomeDelegate(demoCodeOrId: string, userId?: string): Promise<void> {
      await demoService.requestToBecomeDelegate(demoCodeOrId, userId);
      await store.reloadDemoAttendee(userId);
    },
    async requestCancelBecomeDelegate(demoCodeOrId: string, userId?: string): Promise<void> {
      await demoService.cancelRequestToBecomeDelegate(demoCodeOrId, userId);
      await store.reloadDemoAttendee(userId);
    },
    async requestBecomeHostess(demoCodeOrId: string, userId?: string): Promise<void> {
      await demoService.requestBecomeHostess(demoCodeOrId, userId);
      await store.reloadDemoAttendee(userId);
    },
    async requestHostDemoOn(demoCodeOrId: string, userId: string, date: Date): Promise<void> {
      await demoService.requestHostDemoOn(demoCodeOrId, userId, date);
      await store.reloadDemoAttendee(userId);
    },
    async requestCancelBecomeHostess(demoCodeOrId: string, userId?: string): Promise<void> {
      await demoService.cancelRequestBecomeHostess(demoCodeOrId, userId);
      await store.reloadDemoAttendee(userId);
    },
    async updateDemoSettings(demoCodeOrId: string, delegateApprovalRequired: boolean, notificationLevel: DemoNotificationLevel, displayTraysFromKitTypes: KitType[]): Promise<void> {
      await demoService.updateDemoSettings(demoCodeOrId, delegateApprovalRequired, notificationLevel, displayTraysFromKitTypes);
    },
    async requestOpenDemo(demoCodeOrId: string): Promise<void> {
      await demoService.openDemo(demoCodeOrId);
      notificationStore.notify({ title: translate.instant('GLOBAL.CONGRATULATION'), message: translate.instant('DEMO.OPEN.SUCCESS'), style: 'SUCCESS', icon: '#icon-success' });
    },
    async addAttendee(demoCodeOrId: string, userId: string) {
      await demoService.addDemoAttendee(demoCodeOrId, userId);
    },
    async addAnonymousDemoAttendee(demoCodeOrId: string, createAttendeeRequest: CreateAttendeeRequest) {
      await demoService.addAnonymousDemoAttendee(demoCodeOrId, createAttendeeRequest);
    },
    async updateAnonymousAttendee(userId: string, updateUserProfileRequest: UpdateUserProfileRequest) {
      await userService.updateUserProfile(userId, updateUserProfileRequest);

      const attendee = store.management().attendees.find(a => a.user.id == userId);
      const address = attendee?.user.addresses.find(a => a.type == 'Main');

      address.phone = updateUserProfileRequest.phone;
      address.mobile = updateUserProfileRequest.mobile;
      address.city = updateUserProfileRequest.city;
      address.zipCode = updateUserProfileRequest.zipCode;
      address.address1 = updateUserProfileRequest.address1;
      address.address2 = updateUserProfileRequest.address2;
      address.countryCode = updateUserProfileRequest.country;

      attendee.user.gender = updateUserProfileRequest.gender;
      attendee.user.email = updateUserProfileRequest.email;
      attendee.user.firstname = updateUserProfileRequest.firstname;
      attendee.user.lastname = updateUserProfileRequest.lastname;
      attendee.user.birthDate = updateUserProfileRequest.birthDate;
      attendee.user.addresses = attendee.user.addresses.map(a => (a.type == 'Main' ? address : a));

      attendee.displayName = attendee.user.displayName;

      patchState(store, () => ({
        management: {
          ...store.management(),
          attendees: store.management().attendees.map(a => (a.user?.id == userId ? attendee : a)),
        },
      }));
    },
    async updateDemoComment(demoCodeOrId: string, comment: string): Promise<void> {
      await demoService.updateDemoComment(demoCodeOrId, comment);

      patchState(store, () => ({
        demo: {
          ...store.demo(),
          comment,
        },
      }));
    },
    async setHostess(demoCode: string, userId: string) {
      await demoService.setHostess(demoCode, userId);
    },

    async unsetHostess(demoCode: string, userId: string) {
      await demoService.unsetHostess(demoCode, userId);
    },

    async transferDemo(demoCodeOrId: string) {
      return await demoService.transferDemo(demoCodeOrId);
    },
    async saveAttendeeComment(demoCodeOrId: string, attendee: DemoAttendeeResponse, comment: string) {
      patchState(store, {
        commentModal: {
          ...store.commentModal(),
          error: null,
          isBusy: true,
        },
      });

      return await demoService.saveAttendeeComment(demoCodeOrId, attendee, comment);
    },
  })),
  withMethods((store, wishlistService = inject(WishlistService), cartService = inject(CartService), gaService = inject(GoogleAnalyticService)) => ({
    async sendDirectAccessLink(cartId: string): Promise<void> {
      await cartService.sendDirectAccessLink(cartId);
    },
    async addToClientWishlist(userId: string, productId: string): Promise<void> {
      await wishlistService.addToUser(userId, [productId]);
    },
    async deleteFromClientWishlist(userId: string, productId: string): Promise<void> {
      await wishlistService.deleteFromUser(userId, productId);
    },
    async addToClientCart(cartId: string, variantId: string , contextId:number, locale:string, asLsc = false): Promise<void> {
      await cartService.add(variantId, cartId, asLsc);
      await gaService.GAAddToCart(variantId, contextId, locale)
    },
    async removeFromClientCart(cartId: string, cartItemId: string, productVariantId:string, contextId:number, locale:string): Promise<void> {
      await cartService.delete(cartItemId, cartId);
      await gaService.GARemoveFromCart(productVariantId, contextId, locale)
    },
    async updateClientCartItemSize(cartId: string, cartItemId: string, variantId: string, asLsc = false): Promise<void> {
      await cartService.update(cartId, cartItemId, variantId, undefined, asLsc);
    },
    async updateClientCartItemQuantity(cartId: string, variantId: string, quantity: number): Promise<void> {
      await cartService.update(cartId, variantId, undefined, quantity);
    },
    async updateCartItemLinks(links: number, cartItemId: string, cartId: string) {
      await cartService.updateCartItemLinks(links, cartItemId, cartId);
    },
    openCommentModal(attendee: DemoAttendeeResponse) {
      patchState(store, {
        commentModal: {
          ...store.commentModal(),
          opened: true,
          isBusy: false,
          error: null,
          attendee,
        },
      });
    },
    closeCommentModal() {
      patchState(store, {
        commentModal: {
          ...store.commentModal(),
          opened: false,
          attendee: null,
          isBusy: false,
          error: null,
        },
      });
    },
    setAttendeeCommentError(error: ErrorResponse) {
      patchState(store, {
        commentModal: {
          ...store.commentModal(),
          isBusy: false,
          error,
        },
      });
    },
  }))
);
