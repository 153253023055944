import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { getAreaByUrl } from '../utils/filter.utils';
import { ContextStore } from '../../stores/context.store';
import { ResourcesStore } from '../../stores/resources.store';
import { match } from '@formatjs/intl-localematcher';

@Injectable({
  providedIn: 'root',
})
export class AuthorizeNoUrlLocalResolver {
  readonly contextStore = inject(ContextStore);
  readonly resourcesStore = inject(ResourcesStore);
  readonly cookie = inject(CookieService);
  readonly router = inject(Router);

  async resolve() {
    const area = getAreaByUrl(window.location.origin);

    const availableLanguages = environment.allowedLocales[area];
    const languages = availableLanguages?.map(l => l.split('-')[0]) ?? [];

    let locale = this.cookie.get('v_locale')?.replace('_', '-');
    let region = this.cookie.get('v_region');

    if (locale && availableLanguages.includes(locale)) {
      if (!region) region = locale;

      await this.contextStore.setLocale({ locale: locale, region });
      await this.resourcesStore.loadResources(locale);

      return true;
    } else {
      locale = match(navigator.languages, languages, availableLanguages[0]);
      locale = availableLanguages.find(l => l.startsWith(locale));

      if (locale) {
        if (!region) region = locale;
        await this.contextStore.setLocale({ locale: locale, region });
        await this.resourcesStore.loadResources(locale);

        return true;
      } else await this.router.navigate(['/']);
    }
  }
}
