import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommandeComponent } from './components/commande/commande.component';
import { PanierComponent } from './components/panier/panier.component';
import { WishlistComponent } from './components/wishlist/wishlist.component';
import { CommunicationsComponent } from './components/communications/communications.component';
import { OptInComponent } from './components/opt-in/opt-in.component';
import { PublicOrderPaymentComponent } from './components/public-order-payment/public-order-payment.component';
import { EsoLandingComponent } from '../landing-pages/esolanding/eso-landing.component';
import { LanguageResolver } from 'src/app/core/resolver/langFragmentResolver.service';
import { CommandeConfirmationComponent } from './components/commande-confirmation/commande-confirmation.component';
import { PublicOrderPaymentConfirmationComponent } from './components/public-order-payment-confirmation/public-order-payment-confirmation.component';
import { DesoLandingComponent } from '../landing-pages/desolanding/deso-landing.component';
import { HostessPaymentComponent } from './components/hostess-payment/hostess-payment.component';
import { ThemeResolver } from '../../core/resolver/theme.resolver';
import { HostessPaymentConfirmationComponent } from './components/hostess-payment-confirmation/hostess-payment-confirmation.component';

//ROUTING OPTION ---------------------------------------------------------------------
// breadcrumb : value  -- value to display in breadcrumb
// showBreadCrumb: false -- request to hide breadcrumb
// showJumbotron: false -- request to hide jumbo
// showNewsletter: false -- request to hide newsletter subscribtion frame
// showFooterMenu: false -- request to hide footer menu
// showFooterDisclaimer= false -- reqiest to hide footer disclaimer section
// showHeader: false -- switch header to header-light
// usePinkColor: true -- will switch main page + breadcrumb color to victoria pink
//------------------------------------------------------------------------------------
const routes: Routes = [
  {
    path: '',
    redirectTo: 'cart',
    pathMatch: 'full',
  },
  {
    path: 'cart',
    component: PanierComponent,
    resolve: [ThemeResolver],
    data: {
      showLargeScreen: false,
      showHeader: true,
      showDemoBanner: true,
    },
  },
  {
    path: 'checkout',
    component: CommandeComponent,
    resolve: [ThemeResolver],
  },
  {
    path: 'checkout/:cartId/confirmation',
    resolve: [ThemeResolver],
    component: CommandeConfirmationComponent,
  },
  {
    path: 'cart/:cartId/checkout',
    resolve: [ThemeResolver],
    component: PublicOrderPaymentComponent,
  },
  {
    path: 'cart/:cartId/checkout/confirmation',
    resolve: [ThemeResolver],
    component: PublicOrderPaymentConfirmationComponent,
  },
  {
    path: 'wishlist',
    component: WishlistComponent,
    resolve: [ThemeResolver],
    data: {
      showHeader: true,
      showDemoBanner: true,
      showPinkBackground: false,
    },
  },
  {
    path: 'landing',
    resolve: [LanguageResolver, ThemeResolver],
    data: {
      showPinkBackground: true,
      showLargeScreenForMobileOnly: true,
      showFullScreen: true,
    },
    children: [
      {
        path: ':delegateId',
        component: EsoLandingComponent,
      },
      {
        path: ':delegateId/:demoCode',
        component: DesoLandingComponent,
      },
      {
        path: ':delegateId/:demoCode/',
        component: DesoLandingComponent,
      },
      {
        path: ':delegateId/:demoCode/:hostessName',
        component: DesoLandingComponent,
      },
    ],
  },
  {
    path: 'gdpr/:gdprId',
    component: CommunicationsComponent,
    resolve: [ThemeResolver],
    data: {
      showPinkBackground: false,
      showLargeScreenForMobileOnly: true,
      showFullScreen: true,
    },
  },
  {
    path: 'gdpr/optin/:gdprId',
    component: OptInComponent,
    resolve: [ThemeResolver],
    data: {
      showPinkBackground: false,
      showFullScreen: true,
      showLargeScreenForMobileOnly: true,
    },
  },
  {
    path: ':demoCode/payment',
    component: HostessPaymentComponent,
  },
  {
    path: ':demoCode/payment/confirmation',
    component: HostessPaymentConfirmationComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PublicPagesRoutingModule {}
