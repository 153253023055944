import { Component, inject } from '@angular/core';
import { CardType } from '../../../../core/enums/card-type.enum';
import { UserStore } from '../../../../stores/user.store';
import { getStatusIdFromClubStatus } from '../../../../core/utils/common.utils';
import { ScreenService } from '../../../../core/services/screen.service';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent {
  readonly userStore = inject(UserStore);
  readonly screen = inject(ScreenService);

  cardType = CardType;

  public getRemainingPoints() {
    let remainingPoints = 0;
    if (this.userStore.clubInformation()) {
      switch (getStatusIdFromClubStatus(this.userStore.clubInformation()?.clubStatus)) {
        case 1:
          remainingPoints = 250 - this.userStore.clubInformation()?.clubPoints;
          break;
        case 2:
          remainingPoints = 500 - this.userStore.clubInformation()?.clubPoints;
          break;
        case 3:
          remainingPoints = 750 - this.userStore.clubInformation()?.clubPoints;
          break;
      }
    }
    return remainingPoints;
  }

  protected readonly getStatusIdFromClubStatus = getStatusIdFromClubStatus;
}
