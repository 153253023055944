import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ScreenService } from '../../../../../core/services/screen.service';
import {
  getTrackableActionCTAText,
  getTrackableActionDescriptionLabel,
  getTrackableActionDescriptionTitle,
  getTrackableActionSvgFromType,
  getTrackableActionTypeToLegacyId,
  getTrackableActionTypeToLegacyText,
} from '../../../../../core/utils/common.utils';
import { ClubActionTypeEnumObject } from '@victoria-company/agora-client';
import { ClubActionTypeEnum } from '@victoria-company/agora-client/dist/models';

@Component({
  selector: 'app-status-action-card',
  templateUrl: './status-action-card.component.html',
  styleUrls: ['./status-action-card.component.scss'],
})
export class StatusActionCardComponent {
  public readonly screen = inject(ScreenService);

  @Input() cardType: ClubActionTypeEnum;
  @Input() doneDate: Date;
  @Input() value: number;
  @Output() requestExecuteAction = new EventEmitter();

  get hasSvgImage() {
    return getTrackableActionTypeToLegacyId(this.cardType) == 0;
  }

  public executeAction() {
    this.requestExecuteAction.emit();
  }

  protected readonly getTrackableActionTypeToLegacyId = getTrackableActionTypeToLegacyId;
  protected readonly getTrackableActionTypeToLegacyText = getTrackableActionTypeToLegacyText;
  protected readonly ClubActionTypeEnumObject = ClubActionTypeEnumObject;
  protected readonly getTrackableActionDescriptionTitle = getTrackableActionDescriptionTitle;
  protected readonly getTrackableActionDescriptionLabel = getTrackableActionDescriptionLabel;
  protected readonly getTrackableActionCTAText = getTrackableActionCTAText;
  protected readonly getTrackableActionSvgFromType = getTrackableActionSvgFromType;
}
