import { Directive, ElementRef, HostListener, inject, Renderer2 } from '@angular/core';


@Directive({
  selector: 'button[animate]'
})
export class AnimateBtnDirective {
  private readonly el = inject(ElementRef)
  private readonly renderer = inject(Renderer2)

  @HostListener('click', ['$event'])
  onClick(): void {
    if ("vibrate" in navigator) {
      navigator.vibrate(100);
    }

    this.renderer.setStyle(this.el.nativeElement, 'opacity', '0.3');
    setTimeout(() => {
      this.renderer.setStyle(this.el.nativeElement, 'opacity', '1');
    }, 2000);

  }
}
