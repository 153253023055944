import { Component, inject, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { getAreaByUrl } from 'src/app/core/utils/filter.utils';
import { environment } from 'src/environments/environment';
import { ContextStore } from '../../../../stores/context.store';
import { Router } from '@angular/router';
import { match } from '@formatjs/intl-localematcher';

@Component({
  selector: 'app-redirection',
  templateUrl: './redirection.component.html',
  styleUrls: ['./redirection.component.scss'],
})
export class RedirectionComponent implements OnInit {
  readonly contextStore = inject(ContextStore);
  readonly cookie = inject(CookieService);
  readonly router = inject(Router);

  async ngOnInit() {
    const area = getAreaByUrl(window.location.origin);
    const availableLanguages = environment.allowedLocales[area];
    const languages = availableLanguages?.map(l => l.split('-')[0]) ?? [];

    let locale = this.cookie.get('v_locale');
    let region = this.cookie.get('v_region');

    if (!locale){
      locale = match(navigator.languages, languages, availableLanguages[0]);
      locale = availableLanguages.find(l => l.startsWith((locale)))
    }
    else if (!availableLanguages.includes(locale)) {
      locale = match(navigator.languages, languages, availableLanguages[0]);
      if(!locale.includes("-")){
        switch(area){
          case "be": locale = locale + "-be"; break;
          case "fr": locale = locale + "-fr"; break;
          case "de": locale = locale + "-de"; break;
        }
      }
    }

    if (!region) region = locale;

    if (locale == 'nl-be' && (region == 'fr-lu' || region == 'fr-be')) region = 'nl-be';
    else if (locale == 'fr-be' && (region == 'nl-be' || region == 'nl-nl')) region = 'fr-be';

    if (locale) this.contextStore.setLocale({ locale, region });

    await this.router.navigate([`/${locale}`]);
  }
}
