import { Component, inject, Input, OnInit } from '@angular/core';
import { UserStore } from '../../../stores/user.store';

@Component({
  selector: 'app-connect-as',
  templateUrl: './connect-as.component.html',
  styleUrls: ['./connect-as.component.scss'],
})
export class ConnectAsComponent implements OnInit {

  @Input() showForm = true;
  @Input() showBanner = false;
  @Input() connectedAsName:string;

  public readonly userStore=inject(UserStore)
  connectAsId:string;

  constructor() { }

  ngOnInit() {}

  async connectAs(){
    if(this.connectAsId){
        await this.userStore.impersonateUser(this.connectAsId)
        window.location.reload()
    }
  }

  logConnectAsOut(){
    if(this.userStore.isImpersonated()){
      this.userStore.logImpersonateUserOut()
      window.location.reload()
    }
  }

}
