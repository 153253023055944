<ng-container>
  <app-basic-header />
  <div class="order-container" *ngIf="resourcesStore.isLoaded() && loaded && surplus && !globalError">
    <app-page-title [mainTitle]="'SURPLUS.TITLE' | translate"> </app-page-title>
    <div class="flex column ai-center j-center">
      <div *ngIf="paymentError" class="order-payment-error">
        <div class="flex row ai-center">
          <svg viewBox="0 0 28 28" class="icon-warning">
            <use xlink:href="#icon-warning"></use>
          </svg>
          <div>
            <span *ngIf="paymentError == PaymentResponseStatusObject.Canceled">{{ resourcesStore.i18n().resources.checkoutPayment.messageLabelError }}</span>
            <span *ngIf="paymentError == PaymentResponseStatusObject.Failed">{{ resourcesStore.i18n().resources.checkoutPayment.topicLabelError }}</span>
            <span *ngIf="paymentError == PaymentResponseStatusObject.Expired">{{ resourcesStore.i18n().resources.resetpassword.shortDescription }}</span>
            <span *ngIf="paymentError == PaymentResponseStatusObject.Unknown">{{ 'ALERT.ERROR.GLOBAL' | translate }}</span>
          </div>
        </div>
      </div>
      <div class="hostess-order-frame ai-center">
        <p *ngIf="!surplus?.hostessName">{{ 'SURPLUS.HELLO' | translate }}</p>
        <p *ngIf="surplus?.hostessName">{{ 'SURPLUS.HELLOX' | translate: { X: surplus.hostessName } }}</p>

        <p class="center" [innerHTML]="'SURPLUS.BODY' | translate: { X: surplus.delegateName | uppercase, Y: surplus.surplusAmount | agoraCurrency, Z: surplus.demoDate | date: 'dd/MM/YYYY' }"></p>

        <div class="cta-container width-fit">
          <button (click)="requestPayment()" [disabled]="isOperationPending" [class.disabled]="isOperationPending">
            <div *ngIf="isOperationPending" class="loader loader-mini"></div>
            <label>{{ 'SURPLUS.PAY' | translate }}</label>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="order-container order-paid flex column j-start ai-center ac-center" *ngIf="loaded && (!surplus || globalError)">
    <app-page-title [mainTitle]="'DEMO.PAYMENT.LINK.EXPIRED.TITLE' | translate"> </app-page-title>
    <div class="flex column ai-center j-center">
      <div class="hostess-order-frame ai-center">
        <p class="m-center">
          {{ 'DEMO.PAYMENT.LINK.EXPIRED.DESCRIPTION' | translate }}
        </p>
      </div>
    </div>
  </div>
</ng-container>
