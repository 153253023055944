/* eslint-disable @typescript-eslint/naming-convention */
import { inject, Injectable, Renderer2 } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CartResponse } from '@victoria-company/agora-client';
import { CatalogService } from './V2/catalog.service';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticService {
  loaded = false;
  GA_TRAKINK_ID: string;

  catalogService = inject(CatalogService);

  constructor() {}

  //Call Once in AppComponent
  public createGAElements(contextId: number, render: Renderer2, document: Document) {
    // if (this.loaded || !environment.production) return;

    this.setGATrackingID(contextId);

    let element = render.createElement('script');
    element.src = this.loadGAScripts();
    element.async = true;
    render.appendChild(document.body, element);

    element = render.createElement('script');
    element.text = this.initPagePreviewHit();
    render.appendChild(document.body, element);

    console.log('GA Element Created');
  }

  public async GARemoveFromCart(productVariantId: string, contextId: number, locale: string) {
    if (window.dataLayer && environment.production) {
      const product = (await this.catalogService.getProductsByVariantIdsPromise(contextId, locale, [productVariantId]))?.products[0];
      const variant = product?.variants?.find(v => v.id == productVariantId);

      if (!product && !variant) return;

      window.dataLayer.push({
        event: 'removeFromCart',
        ecommerce: {
          remove: {
            products: [
              {
                name: product.name,
                id: product.reference,
                price: variant.currentPrice,
                brand: 'Victoria',
                category: product.category?.slug,
                variant: variant.sku,
                quantity: 1,
              },
            ],
          },
        },
      });

      console.log('GARemoveFromCart Send!');
    }
  }

  public async GAAddToCart(productVariantId: string, contextId: number, locale: string) {
    if (window.dataLayer && environment.production) {
      const product = (await this.catalogService.getProductsByVariantIdsPromise(contextId, locale, [productVariantId]))?.products[0];
      const variant = product?.variants?.find(v => v.id == productVariantId);

      window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          currency: 'EUR',
          value:variant.normalPrice! > variant.currentPrice! ? variant.currentPrice! : variant.normalPrice,
          items: [
            {
              item_name: product.name,
              item_id: product.reference,
              price: variant.normalPrice! > variant.currentPrice! ? variant.currentPrice! : variant.normalPrice,
              item_brand: 'Victoria',
              item_category: product.category?.slug,
              item_variant: variant.sku,
              quantity: 1
            },
          ],
        },
      });


      console.log('GAAddToCart Send!', {
        currency: 'EUR',
        value:variant.normalPrice! > variant.currentPrice! ? variant.currentPrice! : variant.normalPrice,
        items: [
          {
            item_name: product.name,
            item_id: product.reference,
            price: variant.normalPrice! > variant.currentPrice! ? variant.currentPrice! : variant.normalPrice,
            item_brand: 'Victoria',
            item_category: product.category?.slug,
            item_variant: variant.sku,
            quantity: 1
          },
        ],
      });
    }
  }

  public GACheckout(step: number, option: string, order: any) {
    let products = [];

    order.orderItems.forEach(product => {
      products.push({
        name: product.productGroup?.nameOnly,
        id: product.productGroup?.code,
        price: product.unitPrice,
        brand: 'Victoria',
        category: product.productGroup?.category?.slug,
        variant: product.sku,
        quantity: product.quantity,
      });
    });


    if (window.dataLayer && environment.production) {
      products = [];
      order.orderItems.forEach(product => {
        products.push({
          name: product.productGroup?.nameOnly,
          id: product.productGroup?.code,
          price: product.unitPrice,
          brand: 'Victoria',
          category: product.productGroup?.category?.slug,
          variant: product.sku,
          quantity: product.quantity,
        });
      });

      const checkoutDatas = {
        actionField: { step, option },
        products,
      };

      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: checkoutDatas,
        },
      });
    }
  }

  public async GAOrderConfirmed(cart: CartResponse, contextId: number, locale: string) {
    if (window.dataLayer && environment.production) {
      const catalogItems = await this.catalogService.getProductsByVariantIdsPromise(
        contextId,
        locale,
        cart.cartItems.map(ci => ci.productVariantId)
      );
      if (!catalogItems.products?.length) return;

      const items = [];

      cart.cartItems.forEach(ci => {
        const product = catalogItems.products.find(p => p.variants.some(v => v.id == ci.productVariantId));
        const variant = product?.variants.find(v => v.id == ci.productVariantId);

        if (product && variant) {
          items.push({
              item_name: product.name,
              item_id: product.reference,
              price: variant.normalPrice! > variant.currentPrice! ? variant.currentPrice! : variant.normalPrice,
              item_brand: 'Victoria',
              item_category: product.category?.slug,
              item_variant: variant.sku,
              quantity: 1
          });
        }
      });

      console.log('GA Total to pay : ', cart.turnOver);

      const purchaseDatas = {
        currency: 'EUR',
        value:cart.turnOver,
        shipping: cart.deliveryFee,
        transaction_id: cart.id,
        items : items,
      };

      console.log('GA purchase data: ', purchaseDatas);


      window.dataLayer.push({
        event: 'purchase',
        ecommerce: purchaseDatas
      });

      console.log('GAOrderConfirmed Send!');
    }
  }

  private setGATrackingID(contextId: number) {
    switch (contextId) {
      case 1:
        this.GA_TRAKINK_ID = 'GTM-NVTMTL4';
        break;
      case 2:
        this.GA_TRAKINK_ID = 'GTM-5LMF6SM';
        break;
      case 3:
        this.GA_TRAKINK_ID = 'GTM-53J29LR';
        break;
      default:
        this.GA_TRAKINK_ID = '0';
        break;
    }
  }

  private loadGAScripts() {
    return `https://www.googletagmanager.com/gtm.js?id=${this.GA_TRAKINK_ID}`;
  }

  private initPagePreviewHit() {
    this.loaded = true;
    return `
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${this.GA_TRAKINK_ID}');
      `;
  }
}
