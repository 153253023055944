<div class="footer-wrapper">
  <div *ngIf="themeStore.showNewsletter()" class="newsletter">
    <div class="newsletter-container">
      <div class="newsletter-image-container" *ngIf="newsLetterBlock">
        <figure class="newsletter-image d-only">
          <img loading="lazy" alt="Victoria jewels subscribe" class="lazy lazy--loaded" sizes="100vw" [src]="newsLetterBlock.newsletterBackground[0].url" data-ll-status="loaded" />
        </figure>
        <figure class="newsletter-image m-only">
          <img loading="lazy" alt="Victoria jewels subscribe" class="lazy lazy--loaded" sizes="100vw" [src]="newsLetterBlock.newsletterBackgroundMobile[0].url" data-ll-status="loaded" />
        </figure>
      </div>
      <div class="newsletter-text-container">
        <div class="newsletter-text-wrapper">
          <div class="newsletter-text-content">
            <div class="newsletter-text-content-title">
              <h2 class="newsletter-text-title">{{ 'NEWSLETTER.TITLE' | translate }}</h2>
            </div>
            <div class="newsletter-text-content-subtitle">
              <div class="newsletter-text-subtitle">{{ 'NEWSLETTER.SUBTITLE' | translate }}</div>
            </div>
          </div>
          <a *ngIf="newletterLocales" class="newsletter-text-actionlink" href="{{ getNewsletterUrl() }}">
            <div class="newsletter-text-actionlink-wrapper">
              <div class="newsletter-text-actionlink-label">{{ 'NEWSLETTER.ACTIONLINK' | translate }}</div>
              <div class="newsletter-text-actionlink-icon">
                <svg viewBox="0 0 32 32" class="-XJm-u0lc14_1LOvv6CWI">
                  <use xlink:href="#icon-arrow-right-long"></use>
                </svg>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <footer *ngIf="themeStore.showFooterMenu()">
    <div class="footer-container">
      <div class="footer-left">
        <div class="footer-content">
          <div class="footer-logo">
            <a href="/fr-be/" class="logo-link">
              <img loading="lazy" src="/assets/images/logo.svg" alt="Victoria Jewels logo" />
            </a>
          </div>
          <div class="footer-adresses-container" *ngIf="footerLocales">
            <div class="footer-adresses">
              <div class="adresses-entry">
                <div class="adresses-icon">
                  <svg viewBox="0 0 32 32" class="icon">
                    <use xlink:href="#icon-location"></use>
                  </svg>
                </div>
                <div class="adresses-text">
                  <div class="text">
                    <div innerHTML="{{ footerLocales.footerContact[0].text }}">
                      <!-- <p>Rue de Verrewinkel 93</p>
                      <p>1180 Bruxelles, Belgique</p> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="adresses-entry">
                <div class="adresses-icon">
                  <svg viewBox="0 0 32 32" class="icon">
                    <use xlink:href="#icon-newsletter"></use>
                  </svg>
                </div>
                <div class="adresses-text">
                  <div class="text">
                    <div innerHTML="{{ footerLocales.footerContact[1].text }}">
                      <!-- <p><a href="mailto:info@victoria-benelux.com">info@victoria-benelux.com</a></p> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="adresses-entry">
                <div class="adresses-icon">
                  <svg viewBox="0 0 32 32" class="icon">
                    <use xlink:href="#icon-telephone"></use>
                  </svg>
                </div>
                <div class="adresses-text">
                  <div class="text">
                    <div>
                      <p innerHTML="{{ footerLocales.footerContact[2].text }}">
                        <!-- <a href="tel:+3223446088">+32 2 344 60 88</a> -->
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="adresses-entry">
                <div class="adresses-icon">
                  <svg viewBox="0 0 32 32" class="icon">
                    <use xlink:href="#icon-clock"></use>
                  </svg>
                </div>
                <div class="adresses-text">
                  <div class="text">
                    <div innerHTML="{{ footerLocales.footerContact[3].text }}">
                      <!-- <p>Lundi - Vendredi</p>
                      <p>9:00 - 17:00</p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-right">
        <div class="footer-right-container">
          <div class="footer-social">
            <div class="social-container" *ngIf="footerLocales">
              <ng-template [ngFor] let-item [ngForOf]="footerLocales.footerSocials">
                <div class="social-item">
                  <a [href]="item.socialLink" target="_blank">
                    <img loading="lazy" width="20px" height="20px" [src]="item.icon[0].url" />
                  </a>
                </div>
              </ng-template>
            </div>
          </div>
          <div class="footer-links">
            <!-- BLOC 1 => 4 Footer Menu-->
            <ng-template *ngIf="footerLocales" ngFor let-linkTitle [ngForOf]="footerLocales.footerNav">
              <div class="link-area">
                <div class="area">
                  <div class="area-title-container" (click)="toogleElement($event)">
                    <div class="area-title">{{ linkTitle.columnTitle }}</div>
                    <div class="area-title-icon">
                      <svg viewBox="0 0 32 32">
                        <use xlink:href="#icon-arrow-right"></use>
                      </svg>
                    </div>
                  </div>
                  <div class="area-links-container">
                    <ng-template ngFor let-link [ngForOf]="linkTitle.children">
                      <div class="area-link-content">
                        <a href="{{ dataService.filter(link.navLink) }}">{{ link.navLabel }}</a>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </ng-template>
            <!-- BLOC 2 -->
            <!-- BLOC 3 -->
            <!-- BLOC 4 -->
          </div>
        </div>
      </div>
    </div>
  </footer>
  <div *ngIf="themeStore.showFooterLegal()" class="footer-legal">
    <div class="footer-legal-container">
      <div>
        <div class="footer-legal-block">
          <div class="footer-legal-content" [class.alone]="reduce">
            <div class="footer-legal-content-nav">
              <!-- Template goes here -->
              <ng-template *ngIf="footerLocales" ngFor let-link [ngForOf]="footerLocales.footerBottomNav">
                <div *ngIf="link.label" class="footer-legal-entry">
                  <div class="footer-legal-entry-content">{{ link.label }}</div>
                </div>
                <div *ngIf="link.navLabel" class="footer-legal-entry">
                  <a class="footer-legal-entry-content" href="{{ dataService.filter(link.navLink) }}">{{ link.navLabel }}</a>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
