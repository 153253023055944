import { Component, computed, EventEmitter, inject, Input, Output } from '@angular/core';
import { GetDatasService } from 'src/app/core/services/get-datas.service';
import { RoleEnum } from 'src/app/features/agora-pages/demo/enums/role.enum';
import { CartItemResponse, GetDemoResponse, ProductSearchModel, ProductVariantSearchModel } from '@victoria-company/agora-client';
import { ResourcesStore } from '../../../../stores/resources.store';
import { doesProductHaveAvailabilities, getLowestVariantNormalPrice, getProductVariantOptionIsActive } from '../../../../core/utils/variants.utils';
import { DemoStore } from '../../../../stores/demo.store';
import { CartStore } from '../../../../stores/cart.store';
import { objIsEmpty } from '../../../../core/extensions/extensions';
import { Router } from '@angular/router';
import { ContextStore } from '../../../../stores/context.store';

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss'],
})
export class CartItemComponent {
  public resourcesStore = inject(ResourcesStore);
  public demoStore = inject(DemoStore);
  public cartStore = inject(CartStore);
  public dataService = inject(GetDatasService);

  public selectedVariant = computed(() => this.product?.variants?.find(v => v.id == this.cartItem.productVariantId));

  @Input() cartItem: CartItemResponse;
  @Input() demo: GetDemoResponse;
  @Input() product: ProductSearchModel;
  @Input() productsInWishlist: ProductSearchModel[] = [];

  @Input() classCondition: string;
  @Input() isReadOnly = false;
  @Input() bottomBorder = false;

  @Output() requestAddRemoveFromWishlist = new EventEmitter<string>();
  @Output() requestRemoveFromCart = new EventEmitter<boolean>();
  @Output() requestAddItemToCart = new EventEmitter<ProductSearchModel>();
  @Output() requestUpdateCartItemLinks = new EventEmitter<number>();
  @Output() requestUpdateQuantity = new EventEmitter<number>();
  @Output() requestUpdateSize = new EventEmitter<string>();
  @Output() requestZoomInImage = new EventEmitter<string>();

  readonly router = inject(Router);
  readonly contextStore = inject(ContextStore);

  public quantities: { id: number; value: number }[];
  public role: RoleEnum = RoleEnum.CLIENT;

  get isInWishlist(): boolean {
    return this.productsInWishlist?.some(p => p.id == this.product?.id) ?? false;
  }

  updateItemSize(e: EventTarget) {
    const htmle = e as HTMLInputElement;

    if (htmle) {
      const newVariantId = htmle.value;
      if (this.cartItem?.productVariantId != newVariantId) {
        this.requestUpdateSize.emit(newVariantId);
      }
    }
  }

  getProductVariantReference(product: ProductSearchModel, variantId: string) {
    return product?.variants?.find(v => v.id == variantId)?.sku ?? '';
  }

  getVariantAvailability(variant: ProductVariantSearchModel) {
    return getProductVariantOptionIsActive(variant, this.getCarts(), this.getDemo(), this.isDemoDelegate());
  }

  isProductAvailable() {
    return doesProductHaveAvailabilities(this.product, this.getCarts(), this.getDemo(), this.isDemoDelegate());
  }

  async goToProduct(product: ProductSearchModel) {
    if (this.demo?.demo && (this.demo?.demo.status == 'Opened' || this.demo?.demo.status == 'PostSale') && this.demo?.roles?.length) {
      await this.router.navigateByUrl(`/${this.contextStore.locale()}/myvictoria/demo/${this.demo?.demo?.code}/jewelry/${product?.id}`);
    } else {
      const url = this.resourcesStore.getEshopProductCollectionLink(product);
      window.location.href = url;
    }

    // let link = "";
    // if(!this.isDemo){
    //  link = getVictoriaUrl(this.translation.currentLang, true) +
    //   '/' + this.translate.getJsonLocales()?.lang +
    //   '/' + slugify(this.item.productGroup.category.name) +
    //   '/' + this.item.productGroup.nameOnly.toLowerCase() +
    //   '-' + this.item.productGroup.code.toLowerCase();
    //
    //   window.open(link);
    // } else {
    //   link = `/${this.locale}/myvictoria/demo/${this.demo?.demoCode}/jewelry/${this.item.productGroup?.code}`
    //   this.router.navigate([link]);
    // }
  }

  addItemToCart() {
    this.requestAddItemToCart.emit(this.product);
  }

  addRemoveItemFromWishlist() {
    this.requestAddRemoveFromWishlist.emit(this.product?.id);
  }

  removeFromCart() {
    this.requestRemoveFromCart.emit();
  }

  zoomInImage() {
    this.requestZoomInImage.emit(this.dataService.getProductImagesPathForXXL(this.product.imageThumbnail));
  }

  updateCartItemLinks(value: number) {
    this.requestUpdateCartItemLinks.emit(value);
  }

  isDemoDelegate() {
    return this.demo?.roles?.includes('Delegate');
  }

  private getDemo() {
    return this.demo?.demo;
  }

  private getCarts() {
    if (this.isDemoDelegate()) {
      return this.demoStore.management()?.attendees?.flatMap(a => a.carts);
    } else return [this.cartStore.cart()];
  }

  protected readonly getProductVariantOptionIsActive = getProductVariantOptionIsActive;
  protected readonly objIsEmpty = objIsEmpty;
  protected readonly getLowestVariantNormalPrice = getLowestVariantNormalPrice;
}
