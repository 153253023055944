import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { LoginGuard } from './config/login-guard.guard';
import { LanguageResolver } from './core/resolver/langFragmentResolver.service';
import { ErrorComponent } from './features/club-pages/components/error/error.component';
import { RedirectionComponent } from './features/public-pages/components/redirection/redirection.component';
import { AuthcallbackComponent } from './features/sso-pages/authcallback/authcallback.component';
import { LoginComponent } from './features/sso-pages/login/login.component';
import { LogoutComponent } from './features/sso-pages/logout/logout.component';
import { SupportComponent } from './features/support/support.component';
import { ThemeResolver } from './core/resolver/theme.resolver';
import { EsoLandingComponent } from './features/landing-pages/esolanding/eso-landing.component';
import { DesoLandingComponent } from './features/landing-pages/desolanding/deso-landing.component';
import { AuthorizeNoUrlLocalResolver } from './core/resolver/authorizeNoUrlLocalResolver.service';

const routes: Routes = [
  {
    path: '',
    component: RedirectionComponent,
    resolve: [ThemeResolver],
    data: {
      showHeader: false,
      showDemoBanner: false,
      showFooter: false,
    },
  },
  {
    path: 'support',
    component: SupportComponent,
    data: {
      breadcrumb: 'GLOBAL.ORDER',
      showHeader: false,
      showHeaderMobileOnly: false,
      showJumbotron: false,
      showBreadCrumb: false,
      showNewsletter: false,
      showFooterMenu: false,
    },
  },
  {
    path: 'authCallback',
    component: AuthcallbackComponent,
    resolve: [ThemeResolver],
    data: {
      showHeader: false,
      showDemoBanner: false,
      showFooter: false,
    },
  },
  {
    path: 'logout',
    component: LogoutComponent,
    resolve: [ThemeResolver],
    data: {
      showHeader: false,
      showDemoBanner: false,
      showFooter: false,
    },
  },
  {
    path: 'error',
    component: ErrorComponent,
    data: {
      breadcrumb: 'GLOBAL.ORDER',
      showHeader: false,
      showHeaderMobileOnly: true,
      showJumbotron: false,
      showBreadCrumb: false,
      showNewsletter: false,
      showFooterMenu: false,
      usePinkColor: true,
      enlargeScreen: true,
    },
  },
  {
    path: 'public/landing',
    resolve: [AuthorizeNoUrlLocalResolver, ThemeResolver],
    data: {
      showPinkBackground: true,
      showLargeScreenForMobileOnly: true,
      showFullScreen: true,
    },
    children: [
      {
        path: ':delegateId',
        component: EsoLandingComponent,
      },
      {
        path: ':delegateId/:demoCode',
        component: DesoLandingComponent,
      },
      {
        path: ':delegateId/:demoCode/',
        component: DesoLandingComponent,
      },
      {
        path: ':delegateId/:demoCode/:hostessName',
        component: DesoLandingComponent,
      },
    ],
  },
  {
    path: ':id/club',
    resolve: [LanguageResolver, ThemeResolver],
    canActivate: [AutoLoginPartialRoutesGuard],
    canActivateChild: [AutoLoginPartialRoutesGuard],
    data: { showHeader: true, showJumbotron: true, showNewsletter: true, showFooterMenu: true, showFooterLegal: true },
    loadChildren: () => import('./features/club-pages/club-pages.module').then(m => m.ClubPagesModule),
  },
  {
    path: ':id/public',
    resolve: [LanguageResolver, ThemeResolver],
    data: {
      showPinkBackground: true,
      showLargeScreen: true,
    },
    loadChildren: () => import('./features/public-pages/public-pages.module').then(m => m.PublicPagesModule),
  },
  {
    path: ':id/myvictoria',
    resolve: [LanguageResolver, ThemeResolver],
    loadChildren: () => import('./features/agora-pages/agora-pages.module').then(m => m.AgoraPagesModule),
  },
  {
    path: ':id',
    component: LoginComponent,
    canActivate: [LoginGuard],
    resolve: [LanguageResolver, ThemeResolver],
    data: {
      showHeader: true,
      showPinkBackground: true,
    },
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

//RouterModule.forRoot(routes, {preloadingStrategy: NoPreloading, scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload', useHash: false })
@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading, scrollPositionRestoration: 'enabled', useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
