<app-modals
  *ngIf="resourcesStore.isLoaded()"
  (requestCloseModal)="cancel()"
  [id]="'product-variant-selection-modal'"
  [opened]="cartStore.addToCartSelectProductVariantModal().isOpened"
  [title]="viewNextStep ? resourcesStore.i18n().resources.sizeGuide.mainTitle : resourcesStore.i18n().resources.popupPickSize.mainTitle"
  [uppercaseTitle]="true"
  [customClass]="'product-variant-selection-modal'">
  <div *ngIf="viewNextStep" class="flex cursor step-back" (click)="stepBack()">
    <svg viewBox="0 0 32 32" class="icon">
      <use xlink:href="#icon-arrow-left-long"></use>
    </svg>
    {{ resourcesStore.i18n().resources.popupFindConsultant.backLabel }}
  </div>
  <div class="select-size-modal" *ngIf="!viewNextStep">
    <p>{{ resourcesStore.i18n().resources.popupPickSize.description | removeHtmlTag }}</p>
    <div class="promo-form">
      <h3 class="lightbold">{{ cartStore.addToCartSelectProductVariantModal().product?.name }}</h3>
      <div class="flex column gap-15">
        <div class="flex gap-8 ai-center">
          <select class="control-select" (change)="changeSelectedVariantId($event.target)">
            <ng-container *ngFor="let variant of cartStore.addToCartSelectProductVariantModal()?.product?.variants">
              <option
                *ngIf="variant.isVisibleByCustomer"
                [value]="variant.id"
                [selected]="variant.id == cartStore.addToCartSelectProductVariantModal().selectedVariantId"
                [disabled]="!getVariantAvailability(variant)"
                [class.barre]="!getVariantAvailability(variant)">
                {{ variant?.size }}
              </option>
            </ng-container>
          </select>
          <div class="flex cursor ai-center" (click)="goNextStep()">
            <svg viewBox="0 0 32 32" class="icon">
              <use xlink:href="#icon-size"></use>
            </svg>
            <span class="mini-text">{{ resourcesStore.i18n().resources.shoppingBag.sizesLabels }}</span>
          </div>
        </div>
        <p *ngIf="additionalText" class="error-box ral-message">{{ additionalText | translate }}</p>
        <button
          animate
          [disabled]="!selectedVariantAvailable() || isBusy"
          [class.disabled]="!selectedVariantAvailable() || isBusy"
          class="btn btn-primary j-center"
          (click)="addToCart(cartStore.addToCartSelectProductVariantModal().selectedVariantId)">
          <div *ngIf="isBusy" class="loader-icon loader loader-mini"></div>
          {{ resourcesStore.i18n().resources.popupPickSize.addToBagLabel }}
        </button>
      </div>
    </div>
  </div>
  <div class="modal-body modal-size-guide" *ngIf="viewNextStep">
    <!-- Tab Area -->
    <div class="flex j-start selection scrollable no-scrollbar">
      <div
        *ngFor="let item of resourcesStore.i18n().resources.sizeGuide.tabs; let i = index"
        class="tab tab-auto flex center-all no-wrap selection-title"
        [class.selected]="selectedTab == i.toString()"
        (click)="switchTab(i.toString())">
        {{ item.tabLabel }}
      </div>
    </div>
    <!-- Tab Content Area-->
    <div class="search-form modal-size-guide-body no-scrollbar">
      <ng-template [ngFor] let-item [ngForOf]="resourcesStore.i18n().resources.sizeGuide.tabs" let-i="index">
        <div class="flex column gap-8 tab-details no-scrollbar" *ngIf="selectedTab == i.toString()">
          <p>{{ item.description }}</p>
          <div class="flex ai-start gap-20 m-column">
            <figure *ngIf="item.imagePoster.length > 0" class="flex-1">
              <img loading="lazy" [src]="item.imagePoster[0].url" [alt]="item.imagePoster[0].filename" />
            </figure>
            <div class="flex-1 flex column table-container">
              <div class="flex j-space-around">
                <div class="flex column gap-20 flex-1">
                  <div class="flex column gap-20">
                    <span class="lightbold table-head">{{ item.tableHeadSizeLabel }}</span>
                  </div>
                </div>
                <div class="flex column gap-20 flex-1">
                  <div class="flex column gap-20">
                    <span class="lightbold table-head">{{ item.tableHeadCircumferenceLabel }}</span>
                  </div>
                </div>
              </div>
              <div class="flex gap-10 j-space-around table-size">
                <div class="flex column gap-20 flex-1">
                  <div *ngFor="let tbl of item.table; let j = index" class="flex column gap-20">
                    <span>{{ tbl.col1 }}</span>
                  </div>
                </div>
                <div class="flex column gap-20 flex-1">
                  <div *ngFor="let tbl of item.table; let j = index" class="flex column gap-20">
                    <span>{{ tbl.col2 }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</app-modals>
<app-flow-error *ngIf="globalError" [error]="globalError" />
