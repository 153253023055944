<div class="order-delivery" *ngIf="resourcesStore.isLoaded() && cart">
  <h1 class="canela">{{ resourcesStore.i18n().resources.checkoutDelivery.mainSteps[1].label }}</h1>
  <p>{{ 'FIDELITY.DELIVERY.OPTIONX' | translate: { X: deliveryOptions?.deliveryOptions?.length } }}</p>

  <div class="order-delivery-options flex column j-start">
    <div class="delivery-option flex" *ngIf="deliveryIsAvailable(DeliveryTypeObject.ToDelegate)" [class.selected]="isSelectedDelivery(DeliveryTypeObject.ToDelegate)">
      <label class="flex">
        <input type="radio" id="delivery_toDelegate" name="delivery" (click)="switchOption(DeliveryTypeObject.ToDelegate)" [checked]="isSelectedDelivery(DeliveryTypeObject.ToDelegate)" />
        <div>
          <div class="flex delivery-option-label">
            <div class="header" (click)="switchOption(DeliveryTypeObject.ToDelegate)">
              <!-- {{resources.checkoutDelivery.shippingOptions[0].label | translateWihParam:{'name': delegate?.displayName} }} -->
              {{
                translate.getTranslationMultiple(
                  resourcesStore.i18n().resources.checkoutDelivery.shippingOptions[0].label | translateWihParam: { name: delegate?.displayName },
                  'ORDER.DELIVERY.CHOICE.DELEGATE',
                  isValidationTunnel,
                  undefined,
                  { displayName: delegate?.displayName },
                  true
                )
              }}
            </div>
            <div class="option">{{ resourcesStore.i18n().resources.checkoutDelivery.shippingOptions[0].priceLabel }}</div>
          </div>
          <p class="subtitle">
            {{
              translate.getTranslationMultiple(
                resourcesStore.i18n().resources.checkoutDelivery.shippingOptions[0].description
                  | removeHtmlTag
                  | translateWihParam: { zipCode: delegate?.zipCode, city: delegate?.city, countryCode: delegate?.countryCode },
                'ORDER.DELIVERY.CHOICE.DELEGATE.SUBTITLE',
                isValidationTunnel,
                undefined,
                undefined,
                true
              )
            }}
          </p>
          <div class="delivery-info" [class.selected]="isSelectedDelivery(DeliveryTypeObject.ToDelegate)">
            <!--            <p *ngIf="!order.isOnlyEGifts && !isValidationTunnel">-->
            <p *ngIf="!isValidationTunnel">
              {{ resourcesStore.i18n().resources.checkoutDelivery.shippingOptions[0].descriptionSecond | removeHtmlTag | translateWihParam: { delegateName: delegate?.displayName } }}
            </p>
            <!--            <p *ngIf="order.isOnlyEGifts">{{ 'FIDELITY.DELIVERY.EGIFTS' | translate }}</p>-->
            <p>{{ 'FIDELITY.DELIVERY.EGIFTS' | translate }}</p>
            <button class="btn btn-primary j-center" (click)="confirmDelivery()">
              {{ translate.getTranslationMultiple(resourcesStore.i18n().resources.checkoutDelivery.pickupButtonLabel, 'GLOBAL.ACTION.CONFIRM', isValidationTunnel, undefined, undefined, true) }}
              <!--              <div *ngIf="isBusy" class="loader loader-mini"></div>-->
            </button>
          </div>
        </div>
      </label>
    </div>
    <div class="delivery-option flex" *ngIf="deliveryIsAvailable(DeliveryTypeObject.ToCustomer)" [class.selected]="isSelectedDelivery(DeliveryTypeObject.ToCustomer)">
      <label class="flex">
        <input type="radio" id="delivery_toCustomer" name="delivery" (click)="switchOption(DeliveryTypeObject.ToCustomer)" [checked]="isSelectedDelivery(DeliveryTypeObject.ToCustomer)" />
        <div>
          <div class="flex delivery-option-label align-to-bullet">
            <div class="header" (click)="switchOption(DeliveryTypeObject.ToCustomer)">{{ resourcesStore.i18n().resources.checkoutDelivery.shippingOptions[1].label }}</div>
            <div class="option">
              {{ resourcesStore.i18n().resources.checkoutDelivery.shippingOptions[1].priceLabel | translateWihParam: { price: (getDeliveryFee(DeliveryTypeObject.ToCustomer) | agoraCurrency) } }}
            </div>
          </div>
          <!-- <p class="subtitle">{{resources.checkoutDelivery.shippingOptions[1].description | removeHtmlTag}}</p> -->
          <div class="delivery-info" [class.selected]="isSelectedDelivery(DeliveryTypeObject.ToCustomer)">
            <p class="subheader">{{ resourcesStore.i18n().resources.checkoutDelivery.shippingOptions[1].descriptionSecond | removeHtmlTag }}</p>
            <form [formGroup]="deliveryForm" *ngIf="deliveryForm && editAddress()">
              <div class="form-block">
                <label>{{ resourcesStore.i18n().resources.checkout.addressLabel }}</label>
                <input type="text" formControlName="street" id="street" />
                <div class="form-error-container" *ngIf="(df.street.touched || df.street.dirty) && df.street.errors && df.street.errors.required">
                  {{
                    translate.getTranslationMultiple(resourcesStore.i18n().resources.popupFindConsultant.streetLabelError, 'INPUT.VALIDATION.REQUIRED', isValidationTunnel, undefined, undefined, true)
                  }}
                </div>
              </div>
              <div class="flex gap-15 address-input">
                <div class="form-block">
                  <label>{{ resourcesStore.i18n().resources.checkout.postalCodeLabel }}</label>
                  <input type="text" formControlName="zipCode" id="zipCode" />
                  <div class="form-error-container" *ngIf="(df.zipCode.touched || df.zipCode.dirty) && df.zipCode.errors && df.zipCode.errors.required">
                    {{
                      translate.getTranslationMultiple(
                        resourcesStore.i18n().resources.popupFindConsultant.postalCodeLabelError,
                        'INPUT.VALIDATION.REQUIRED',
                        isValidationTunnel,
                        undefined,
                        undefined,
                        true
                      )
                    }}
                  </div>
                </div>
                <div class="form-block">
                  <label>{{ resourcesStore.i18n().resources.checkout.cityLabel }}</label>
                  <input type="text" formControlName="city" id="city" />
                  <div class="form-error-container" *ngIf="(df.city.touched || df.city.dirty) && df.city.errors && df.city.errors.required">
                    {{
                      translate.getTranslationMultiple(resourcesStore.i18n().resources.popupFindConsultant.cityLabelError, 'INPUT.VALIDATION.REQUIRED', isValidationTunnel, undefined, undefined, true)
                    }}
                  </div>
                </div>
              </div>
              <div class="form-block">
                <label>{{ resourcesStore.i18n().resources.checkout.countryLabel }}</label>
                <select formControlName="country" id="country" (change)="changeCountry($event)">
                  <option *ngFor="let opt of this.form.getCountries()" [value]="opt.id">
                    {{ opt.value }}
                  </option>
                </select>
                <div class="form-error-container" *ngIf="(df.country.touched || df.country.dirty) && df.country.errors && df.country.errors.required">
                  {{
                    translate.getTranslationMultiple(resourcesStore.i18n().resources.popupFindConsultant.countryLabelError, 'INPUT.VALIDATION.REQUIRED', isValidationTunnel, undefined, undefined, true)
                  }}
                </div>
              </div>
              <div class="form-block exceptionnal-margin">
                <label>{{ resourcesStore.i18n().resources.checkout.phoneLabel }}</label>
                <input digitOnly decimalSeparator="" type="text" pattern="[0-9]*" inputmode="numeric" formControlName="phone" id="phone" />
                <div class="form-error-container" *ngIf="df.phone.errors && df.phone.errors.required">
                  {{ translate.getTranslationMultiple(resourcesStore.i18n().resources.register.phoneLabelError, 'INPUT.VALIDATION.REQUIRED', isValidationTunnel, undefined, undefined, true) }}
                </div>
                <div class="form-error-container" *ngIf="df.phone.errors && df.phone.errors.pattern">
                  {{ translate.getTranslationMultiple(resourcesStore.i18n().resources.register.phoneLabelError, 'INPUT.VALIDATION.PHONEPATTERN', isValidationTunnel, undefined, undefined, true) }}
                </div>
              </div>
              <div class="text-margin-top save-default-address">
                <!-- <label class="flex row checkbox path text-margin-bottom">
                        <input type="checkbox" [(ngModel)]="saveAsDefaultAdress"  [ngModelOptions]="{standalone:true}"  id="sav">
                        <svg viewBox="0 0 28 28">
                          <use xlink:href="#custom-checkbox"></use>
                        </svg>
                        Sauvegarder cette adresse par défaut
                      </label> -->
              </div>
            </form>
            <!-- No internal Data -->
            <div class="adresse-container" *ngIf="!editAddress() && (!internalDeliveryData || (internalDeliveryData?.delivery?.address == null && cart.delivery?.address))">
              <p>{{ cart.delivery?.address?.firstname }} {{ cart.delivery?.address?.lastname }}</p>
              <p>{{ cart.delivery?.address?.address1 }}</p>
              <p>{{ cart.delivery?.address?.zipCode }} - {{ cart.delivery?.address?.city }} ({{ cart.delivery?.address?.country }})</p>
              <p>{{ cart.delivery?.address?.mobile }}</p>
            </div>
            <!-- Internal Data -->
            <div class="adresse-container" *ngIf="!editAddress() && internalDeliveryData?.delivery?.address != null">
              <p>{{ internalDeliveryData.delivery?.address?.firstname }} {{ internalDeliveryData.delivery?.address?.lastname }}</p>
              <p>{{ internalDeliveryData.delivery?.address?.address1 }}</p>
              <p>{{ internalDeliveryData.delivery?.address?.zipCode }} - {{ internalDeliveryData.delivery?.address?.city }} ({{ internalDeliveryData.delivery?.address?.country }})</p>
              <p>{{ internalDeliveryData.delivery?.address?.mobile }}</p>
            </div>
            <div class="form-block flex margin-top-50 save-button" *ngIf="editAddress()">
              <button class="btn btn-primary j-center" id="button1" aria-details="button1" (click)="saveAddress()">
                {{ resourcesStore.i18n().resources.checkoutDelivery.buttonLabel }}
                <!--                <div *ngIf="isBusy" class="loader loader-mini"></div>-->
              </button>
            </div>
            <div class="form-block flex margin-top-50 ai-center save-button" *ngIf="!editAddress()">
              <button class="btn btn-primary j-center" id="button2" aria-details="button2" (click)="confirmDelivery()">
                {{ resourcesStore.i18n().resources.checkoutDelivery.deliverButtonLabel }}
                <!--                <div *ngIf="isBusy" class="loader loader-mini"></div>-->
              </button>
              <a class="ctaLink" id="button3" aria-details="button3" (click)="editDeliveryAddress()">{{ resourcesStore.i18n().resources.checkoutDelivery.editButtonLabel }}</a>
            </div>
          </div>
        </div>
      </label>
    </div>
  </div>
</div>
