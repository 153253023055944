<app-modals [id]="'#searchDelegate'" [opened]="isOpened" [title]="resourcesStore.i18n().resources.popupFindConsultant.mainTitle" [customClass]="'search-delegate-modal'" (requestCloseModal)="close()">
  <div class="modal-body search-delegate">
    <div class="flex m-column j-space-between selection">
      <div class="tab flex center-all flex-1" [class.selected]="selectedTab == 'localisation'" (click)="switchTab('localisation')">
        {{ resourcesStore.i18n().resources.popupFindConsultant.tabPostalCode }}
      </div>
      <div class="tab flex center-all flex-1" [class.selected]="selectedTab == 'name'" (click)="switchTab('name')">
        {{ resourcesStore.i18n().resources.popupFindConsultant.tabName }}
      </div>
    </div>
    <div class="search-form no-scrollbar" *ngIf="data">
      <!-- Localisation Search Tab  -->
      <div class="flex column gap-8" *ngIf="selectedTab == 'localisation'">
        <p class="form-caption">{{ resourcesStore.i18n().resources.popupFindConsultant.tabPostalCodeDescription }}</p>
        <div class="flex column form-block gap-5">
          <label>{{ resourcesStore.i18n().resources.popupFindConsultant.streetLabel }}</label>
          <input type="text" [(ngModel)]="dataModel.address" />
        </div>
        <div class="flex gap-20 postal m-column" [class.error]="hasFormError && !dataModel.zip">
          <div class="flex flex-1 column gap-5 form-block">
            <label>{{ resourcesStore.i18n().resources.popupFindConsultant.tabPostalCodePlaceholder }}</label>
            <input type="text" [(ngModel)]="dataModel.zip" />
            <div class="form-error-container" *ngIf="hasFormError && !dataModel.zip">
              {{ resourcesStore.i18n().resources.popupFindConsultant.postalCodeLabelError }}
            </div>
          </div>
          <div class="flex flex-1 column gap-5 form-block" [class.error]="hasFormError && !dataModel.city">
            <label>{{ resourcesStore.i18n().resources.popupFindConsultant.cityLabel }}</label>
            <input type="text" [(ngModel)]="dataModel.city" />
            <div class="form-error-container" *ngIf="hasFormError && !dataModel.city">
              {{ resourcesStore.i18n().resources.popupFindConsultant.cityLabelError }}
            </div>
          </div>
        </div>
        <div class="flex column gap-5 form-block" [class.error]="hasFormError && !dataModel.country">
          <label>{{ resourcesStore.i18n().resources.popupFindConsultant.countryLabel }}</label>
          <select [(ngModel)]="dataModel.country">
            <option *ngFor="let opt of this.formService.getCountries()" [value]="opt.id">{{ opt.value }}</option>
          </select>
          <div class="form-error-container" *ngIf="hasFormError && !dataModel.country">
            {{ resourcesStore.i18n().resources.popupFindConsultant.countryLabelError }}
          </div>
        </div>
        <div *ngIf="hasError">
          <p class="error-box">{{ resourcesStore.i18n().resources.popupFindConsultant.labelInvalidGeolocation }}</p>
        </div>
        <div class="flex j-center margin-top-15">
          <button class="btn btn-primary j-center ai-center ac-center" (click)="validateFormAndCheck()">
            {{ resourcesStore.i18n().resources.popupFindConsultant.buttonLabel }}
          </button>
        </div>
      </div>
      <!-- Name search Tab -->
      <div class="flex column gap-8" *ngIf="selectedTab == 'name'">
        <p class="form-caption">{{ resourcesStore.i18n().resources.popupFindConsultant.tabNameDescription }}</p>
        <div class="form-block flex rox">
          <input class="search-form-field" type="text" [(ngModel)]="dataModel.name" name="delegateName" placeholder="{{ resourcesStore.i18n().resources.popupFindConsultant.tabNamePlaceholder }}" />
          <button class="btn btn-primary btn-icon-only j-center search-form-btn" (click)="validateDelegateAndCheck()">
            <svg viewBox="0 0 32 32">
              <use xlink:href="#icon-search"></use>
            </svg>
          </button>
        </div>
        <p class="error-box" *ngIf="hasError">{{ resourcesStore.i18n().resources.popupFindConsultant.lastNameLabelError }}</p>
      </div>
    </div>
  </div>
</app-modals>
