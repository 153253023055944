import { inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  readonly api = inject(ApiService);

  async cancelPayment(paymentId: string) {
    const client = this.api.getClientInstance();
    return client.api.payments.byPaymentId(paymentId).setAsCancelled.post();
  }

  async getByPaymentId(paymentId:string){
    const client = this.api.getClientInstance();
    return client.api.payments.byPaymentId(paymentId).get();
  }

  async requestSurplusPayment(demoCodeOrId:string, languageCode:string, redirectUrl:string){
    const client = this.api.getClientInstance()
    return client.api.demos.byDemoCodeOrId(demoCodeOrId).hostessSurplus.payments.post({languageCode, redirectUrl})
  }
}
