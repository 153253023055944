import { environment } from 'src/environments/environment';

const filterURL = (url: any) => {
  if (!url) return '';

  // remove everything that starts with http(s):// until the next /
  return url.toLowerCase().replace(/^https?:\/\/[^/]+/, '');
};

const getProfilePictureOrDefault = (picturePath: string) => {
  if (!picturePath || picturePath == null) return '/assets/images/empty-delegate.png';
  return picturePath;
};

const getVictoriaUrl = (locale: string, force: boolean = false) => {
  //if(force) return "https://agora-eshop.agora-victoria.com";

  // DEV Switch country mechanism
  const path = window.location.href;
  //Add dev local switch context
  if (path.includes('localhost') && path.includes('be')) return environment.base_url_benelux;
  else if (path.includes('localhost') && path.includes('fr')) return environment.base_url_france;
  else if (path.includes('localhost') && path.includes('de')) return environment.base_url_allemagne;
  else if (path.includes('localhost')) return environment.base_url_benelux;

  switch (locale) {
    case 'nl-nl':
    case 'fr-be':
    case 'nl-be':
      return `${environment.base_url_benelux}`;
    case 'de-de':
      return `${environment.base_url_allemagne}`;
    case 'fr-fr':
      return `${environment.base_url_france}`;
    default:
      return `${environment.base_url_france}`;
  }
};

const getApiUrlFromOrigin = () => {
  const origin = window.origin;
  let apiUrl = '';

  //prod
  if (origin.startsWith('http://localhost:4200')) apiUrl = 'https://dev-api.agora-victoria.com';
  else if (origin.startsWith(environment.base_url_benelux)) apiUrl = environment.base_url_benelux;
  else if (origin.startsWith(environment.base_url_france)) apiUrl = environment.base_url_france;
  else if (origin.startsWith(environment.base_url_allemagne)) apiUrl = environment.base_url_allemagne;

  if (!origin.startsWith('http://localhost:4200') && (environment.staging == true || environment.agora == true)) return apiUrl + '/staging-api';
  else if (!origin.startsWith('http://localhost:4200') && environment.dev == true) return apiUrl + '/dev-api';
  else return apiUrl + '/api';
};

const getSocketUrlFromOrigin = () => {
  const origin = window.origin;
  let socketUrl = '';

  if (origin.startsWith('http://localhost:4200')) socketUrl = 'https://dev-api.agora-victoria.com';
  else socketUrl = environment.sockets_url;

  return socketUrl + '/agoraHub';
  //     if(origin.startsWith("http://localhost:4200")) socketUrl ="https://dev-api.agora-victoria.com"
  //     else if(origin.startsWith(environment.base_url_benelux)) socketUrl = environment.base_url_benelux
  //     else if(origin.startsWith(environment.base_url_france)) socketUrl = environment.base_url_france
  //     else if(origin.startsWith(environment.base_url_allemagne)) socketUrl = environment.base_url_allemagne

  //     if(!origin.startsWith("http://localhost:4200") && (environment.staging == true || environment.agora==true)) return socketUrl + "/staging-agoraHub"
  //     else if(!origin.startsWith("http://localhost:4200") && environment.dev == true) return socketUrl + "/dev-agoraHub"
  //     else return socketUrl + "/agoraHub";
};

const getDefaultLangByUrl = (route: string) => {
  if (route.startsWith(environment.eshop_url_benelux)) return 'nl-be';
  else if (route.startsWith(environment.eshop_url_france)) return 'fr-fr';
  else if (route.startsWith(environment.eshop_url_allemagne)) return 'de-de';
  else return 'fr_fr';
};

const getContextByUrl = (route: string) => {
  if (route.startsWith(environment.eshop_url_benelux)) return 2;
  else if (route.startsWith(environment.eshop_url_france)) return 1;
  else if (route.startsWith(environment.eshop_url_allemagne)) return 3;
  else return 1;
};

const getCookieDomainByUrl = (route: string) => {

  //Dev locale cookie domain
  if(route.includes('http://localhost')) return 'localhost';

  if (route.startsWith(environment.eshop_url_benelux)) return environment.cookie_domain_benelux;
  else if (route.startsWith(environment.eshop_url_france)) return environment.cookie_domain_france;
  else if (route.startsWith(environment.eshop_url_allemagne)) return environment.cookie_domain_allemagne;
  else return environment.cookie_domain_france;
};

const getRootUrl = (locale: string, region: string = undefined, force: boolean = false) => {
  //if(force) return "https://agora-eshop.agora-victoria.com";

  region = region || locale;

  //TODO remove replace()

  switch (locale) {
    case 'nl-nl':
    case 'fr-be':
    case 'nl-be':
      return `${environment.base_url_benelux}/${locale.replace('_', '-')}?region=${region.replace('_', '-')}`;
    case 'de-de':
      return `${environment.base_url_allemagne}/${locale.replace('_', '-')}?region=${region.replace('_', '-')}`;
    case 'fr-fr':
      return `${environment.base_url_france}/${locale.replace('_', '-')}?region=${region.replace('_', '-')}`;
    default:
      return `${environment.base_url_france}/${locale.replace('_', '-')}?region=${region.replace('_', '-')}`;
  }
};

const getEshopUrlByContext = (contextId: number) => {
  switch (contextId) {
    case 2:
      return environment.eshop_url_benelux;
    case 3:
      return environment.eshop_url_allemagne;
    case 1:
      return environment.eshop_url_france;
    default:
      return environment.eshop_url_france;
  }
};

const getEshopUrlByLang = (locale: string) => {
  switch (locale) {
    case 'nl-nl':
    case 'fr-be':
    case 'nl-be':
      return environment.eshop_url_benelux;
    case 'de-de':
      return environment.eshop_url_allemagne;
    case 'fr-fr':
      return environment.eshop_url_france;
    default:
      return environment.eshop_url_france;
  }
};

const getCookieDomainByContext = (contextId: number) => {
  switch (contextId) {
    case 1:
      return environment.cookie_domain_france;
    case 2:
      return environment.cookie_domain_benelux;
    case 3:
      return environment.cookie_domain_allemagne;
    default:
      return environment.cookie_domain_france;
  }
};

const getCookieDomainByLang = (locale: string) => {
  switch (locale) {
    case 'nl-nl':
    case 'fr-be':
    case 'nl-be':
      return environment.cookie_domain_benelux;
    case 'de-de':
      return environment.cookie_domain_allemagne;
    case 'fr-fr':
      return environment.cookie_domain_france;
    default:
      return environment.cookie_domain_france;
  }
};

const getAreaByUrl = route => {
  //DEV Switch country mechanism
  const path = window.location.href;
  //Add dev local switch context
  if (path.includes('localhost') && path.includes('be')) return 'be';
  else if (path.includes('localhost') && path.includes('fr')) return 'fr';
  else if (path.includes('localhost') && path.includes('de')) return 'de';
  else if (path.includes('localhost')) return 'be';

  if (route.startsWith(environment.eshop_url_benelux)) return 'be';
  else if (route.startsWith(environment.eshop_url_france)) return 'fr';
  else if (route.startsWith(environment.eshop_url_allemagne)) return 'de';
  else return 'be';
};

const getSSOLocale = locale => {
  const explodedLocale = locale?.replace("_","-")?.split('-');
  return explodedLocale[0]; // + "_" + explodedLocale[1].toUpperCase();
};

const slugify = (text: string, separator?: string) => {
  if (!text) return text;

  let value = text.toString().toLowerCase().trim();

  const sets = [
    { to: 'a', from: '[ÀÁÂÃÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]' },
    { to: 'ae', from: '[Ä]' },
    { to: 'c', from: '[ÇĆĈČ]' },
    { to: 'd', from: '[ÐĎĐÞ]' },
    { to: 'e', from: '[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]' },
    { to: 'g', from: '[ĜĞĢǴ]' },
    { to: 'h', from: '[ĤḦ]' },
    { to: 'i', from: '[ÌÍÎÏĨĪĮİỈỊ]' },
    { to: 'j', from: '[Ĵ]' },
    { to: 'ij', from: '[Ĳ]' },
    { to: 'k', from: '[Ķ]' },
    { to: 'l', from: '[ĹĻĽŁ]' },
    { to: 'm', from: '[Ḿ]' },
    { to: 'n', from: '[ÑŃŅŇ]' },
    { to: 'o', from: '[ÒÓÔÕØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]' },
    { to: 'oe', from: '[ŒÖ]' },
    { to: 'p', from: '[ṕ]' },
    { to: 'r', from: '[ŔŖŘ]' },
    { to: 's', from: '[ŚŜŞŠ]' },
    { to: 'ss', from: '[ß]' },
    { to: 't', from: '[ŢŤ]' },
    { to: 'u', from: '[ÙÚÛŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]' },
    { to: 'ue', from: '[Ü]' },
    { to: 'w', from: '[ẂŴẀẄ]' },
    { to: 'x', from: '[ẍ]' },
    { to: 'y', from: '[ÝŶŸỲỴỶỸ]' },
    { to: 'z', from: '[ŹŻŽ]' },
    { to: '-', from: "[·/_,:;']" },
  ];

  sets.forEach(set => {
    value = value.replace(new RegExp(set.from, 'gi'), set.to);
  });

  value = value
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text

  if (typeof separator !== 'undefined' && separator !== '-') {
    value = value.replace(/-/g, separator);
  }

  return value;
};

// return `${window.location.origin}/${locale.replace('_', '-')}/public/demo/${delegateId}/${demoCode}/${hostessName}`;
const getDemoLink = (locale: string, demoCode: string, delegateId: string, hostessName: string = '') => `${getVictoriaUrl(locale)}/${locale}/myvictoria/${delegateId}/${demoCode}/${hostessName}`;

const getPaymentLink = (locale: string, cartId: string) => `${window.location.origin}/${locale.replace('_', '-')}/public/cart/${cartId}/checkout`;

export {
  filterURL,
  getVictoriaUrl,
  getRootUrl,
  getEshopUrlByContext,
  getEshopUrlByLang,
  getCookieDomainByContext,
  getCookieDomainByLang,
  slugify,
  getDefaultLangByUrl,
  getCookieDomainByUrl,
  getAreaByUrl,
  getSSOLocale,
  getContextByUrl,
  getDemoLink,
  getPaymentLink,
  getApiUrlFromOrigin,
  getSocketUrlFromOrigin,
  getProfilePictureOrDefault,
};
