import { CartResponse, DemoResponse, DemoStatusObject, ProductSearchModel, ProductVariantSearchModel } from '@victoria-company/agora-client';
import { DateOnly } from '@microsoft/kiota-abstractions';

//CTA BEHAVIOR FOR PRODUCTS
export type ProductsVariantViewSpecs = {
  enableCTA: boolean;
  additionalText: string;
  showETA: boolean;
  ETA: DateOnly;
};

export const getLowestVariantNormalPrice = (product: ProductSearchModel) => {
  const lowestNormalPrice = Math.min(...product?.variants?.map(variant => variant.normalPrice));
  return isNaN(lowestNormalPrice) ? undefined : lowestNormalPrice;
};

export const getTotalStock = (variant: ProductVariantSearchModel) => {
  return (variant?.stock ?? 0) + (variant?.additionalStock ?? 0);
};
export const isPurchasable = (variant: ProductVariantSearchModel, carts: CartResponse[]) => getTotalStock(variant) > countVariantInCarts(variant, carts);
export const isBackOrder = (variant: ProductVariantSearchModel, carts: CartResponse[]) =>
  (variant?.stock == 0 || variant?.stock < countVariantInCarts(variant, carts)) && isPurchasable(variant, carts);
export const countVariantInCarts = (variant: ProductVariantSearchModel, carts: CartResponse[]) =>
  carts?.flatMap(c => c?.cartItems ?? [])?.filter(ci => ci?.productVariantId == variant?.id && !ci?.isLsc)?.length ?? 0;
export const countLSCVariantInCarts = (variant: ProductVariantSearchModel, carts: CartResponse[]) =>
  carts?.flatMap(c => c?.cartItems ?? [])?.filter(ci => ci?.productVariantId == variant?.id && ci?.isLsc)?.length ?? 0;
export const canBePurchasedByDelegate = (variant: ProductVariantSearchModel, carts: CartResponse[]) => variant?.isVisibleByDelegate && isPurchasable(variant, carts);
export const canBePurchasedByCustomer = (variant: ProductVariantSearchModel, carts: CartResponse[]) => variant?.isVisibleByCustomer && isPurchasable(variant, carts) && !isBackOrder(variant, carts);
export const canBePurchasedInLSCMode = (variant: ProductVariantSearchModel, carts: CartResponse[], demo: DemoResponse, isDelegate: boolean) =>
  isDelegate &&
  demo?.kitId != null &&
  demo?.kitId != '' &&
  variant?.isVisibleByDelegate &&
  variant?.isLsc &&
  getTotalStock(variant) - countVariantInCarts(variant, carts) <= 0 &&
  countLSCVariantInCarts(variant, carts) < 1 &&
  demo?.status === DemoStatusObject.Opened;

export const canBePurchasedInLSCModeIfKitNotNull = (variant: ProductVariantSearchModel, carts: CartResponse[], demo: DemoResponse, isDelegate: boolean) =>
  isDelegate &&
  (demo?.kitId == null || demo?.kitId == '') &&
  variant?.isVisibleByDelegate &&
  variant?.isLsc &&
  getTotalStock(variant) - countVariantInCarts(variant, carts) <= 0 &&
  countLSCVariantInCarts(variant, carts) < 1 &&
  demo?.status === DemoStatusObject.Opened;
export const getProductVariantViewSpecs = (variant: ProductVariantSearchModel, carts: CartResponse[], demo: DemoResponse, isDelegate: boolean): ProductsVariantViewSpecs => {
  const isBackOrderStatus = isBackOrder(variant, carts);
  let enableCTA = isDelegate ? canBePurchasedByDelegate(variant, carts) || canBePurchasedInLSCMode(variant, carts, demo, isDelegate) : canBePurchasedByCustomer(variant, carts);

  if (enableCTA && isDelegate && canBePurchasedInLSCModeIfKitNotNull(variant, carts, demo, isDelegate)) enableCTA = false;

  const additionalText = (() => {
    if (isDelegate) {
      if (canBePurchasedByDelegate(variant, carts)) return undefined;
      if (canBePurchasedInLSCMode(variant, carts, demo, true)) return 'DEMO.JEWELRY.LSC';
      if (canBePurchasedInLSCModeIfKitNotNull(variant, carts, demo, true)) return 'DEMO.JEWELRY.LSC.MISSINGKIT';
      return variant?.isVisibleByDelegate && isBackOrderStatus ? 'DEMO.JEWELRY.RAL.CV' : 'DEMO.JEWELRY.SOLDOUT';
    } else {
      if (canBePurchasedByCustomer(variant, carts)) return undefined;
      if (isBackOrderStatus) return 'DEMO.JEWELRY.ASKDELEGATE';

      return variant?.isVisibleByCustomer && !isBackOrderStatus && variant?.isLsc && demo?.kitId != null && demo?.kitId != '' ? 'DEMO.JEWELRY.RAL.CLIENT' : 'DEMO.JEWELRY.SOLDOUT';
    }
  })();

  const response = {
    enableCTA,
    additionalText,
    showETA: isDelegate && isBackOrderStatus && variant.eta && variant.eta != 0,
    ETA: isBackOrderStatus && variant.eta ? DateOnly.fromDate(new Date(variant?.eta * 1000)) : undefined,
  };

  return response;
};

export const doesProductHaveAvailabilities = (product: ProductSearchModel, carts: CartResponse[], demo: DemoResponse, isDelegate: boolean) => {
  let productsAvailable = 0;
  product?.variants?.forEach(v => {
    const specs = getProductVariantViewSpecs(v, carts, demo, isDelegate);
    if (specs?.enableCTA) productsAvailable++;
  });

  return productsAvailable > 0;
};

export const getProductVariantOptionIsActive = (variant: ProductSearchModel, carts: CartResponse[], demo: DemoResponse, isDelegate: boolean) => {
  const variantViewSpecs = getProductVariantViewSpecs(variant, carts, demo, isDelegate);
  return variantViewSpecs.enableCTA;
};
