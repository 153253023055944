import { inject, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DataForm } from '../models/dataForm.model';
import { FidelityLogin } from '../models/fidelityLogin.model';
import { FidelityRegistration } from '../models/fidelityRegistration.model';
import { CustomValidators } from '../utils/customValidators';
import { AddBirthDate } from '../models/addBirthDate.model';
import {
  DeliveryAddress,
  DemoAttendeeResponse,
  DocumentCategory,
  DocumentCategoryObject,
  DocumentResponse,
  DocumentTypeObject,
  UserProfileResponse_CustomerAddress,
} from '@victoria-company/agora-client';
import { ADD_CLIENT_FORM } from '../../features/agora-pages/demo/forms/add-client.form';
import { DateOnly } from '@microsoft/kiota-abstractions';
import { ContextStore } from '../../stores/context.store';

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  readonly contextStore = inject(ContextStore);

  public getCountries() {
    const countries = [];
    switch (this.contextStore.contextId()) {
      case 1:
        countries.push({ id: 'FR', value: 'France', selected: true });
        break;
      case 2:
        countries.push({ id: 'BE', value: 'Belgique/België', selected: true }, { id: 'NL', value: 'Nederland' }, { id: 'LU', value: 'Luxembourg' });
        break;
      case 3:
        countries.push({ id: 'DE', value: 'Deutschland', selected: true }, { id: 'AT', value: 'Österreich' });
        break;
      default:
        countries.push(
          { id: 'BE', value: 'Belgique/België' },
          { id: 'NL', value: 'Nederland' },
          { id: 'LU', value: 'Luxembourg' },
          { id: 'FR', value: 'France', selected: true },
          { id: 'DE', value: 'Deutschland' },
          { id: 'AT', value: 'Österreich' }
        );
        break;
    }

    return countries;
  }

  public getLanguages() {
    const languages = [];

    switch (this.contextStore.contextId()) {
      case 1:
        languages.push({ id: 'fr-fr', value: 'Français' });
        break;
      case 2:
        languages.push({ id: 'fr-be', value: 'Français' }, { id: 'nl-be', value: 'Nederlands' });
        break;
      case 3:
        languages.push({ id: 'de-de', value: 'Deutsch' });
        break;
      default:
        languages.push({ id: 'fr-fr', value: 'Français (France)' }, { id: 'fr-be', value: 'Français (Belgique)' }, { id: 'nl-be', value: 'Nederlands' }, { id: 'de-de', value: 'Deutsch' });
        break;
    }

    return languages;
  }

  public getEditAttendeeForm(fb: UntypedFormBuilder, data: DemoAttendeeResponse) {
    if (!data) return fb.group(ADD_CLIENT_FORM);
    else {
      const mainAddress = data.user?.addresses.find(a => a.type == 'Main');
      const birthDate = data.user?.birthDate ? DateOnly.parse(data.user?.birthDate.toString()) : null;

      return fb.group({
        gender: [data.user.gender],
        firstName: [data.user.firstname, [Validators.required, Validators.maxLength(50), CustomValidators.specialCharValidator()]],
        lastName: [data.user.lastname, [Validators.required, Validators.maxLength(50), CustomValidators.specialCharValidator()]],
        address1: [mainAddress?.address1, [Validators.required, Validators.maxLength(100)]],
        address2: [mainAddress?.address2, Validators.maxLength(100)],
        zipCode: [mainAddress?.zipCode, [Validators.required, Validators.maxLength(50)]],
        city: [mainAddress?.city, [Validators.required, Validators.maxLength(50)]],
        countryCode: [mainAddress?.countryCode, [Validators.required, Validators.maxLength(20)]],
        mobile: [mainAddress?.mobile, [Validators.required, Validators.pattern, Validators.maxLength(50)]],
        phone: [mainAddress?.phone, [Validators.pattern, Validators.maxLength(50)]],
        email: [data.user.email, [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/), Validators.maxLength(300)]],
        birthdate: [
          birthDate ? new Date(`${birthDate.year}/${(0 + birthDate.month.toString(10)).slice(-2)}/${birthDate.day}`) : null,
          [CustomValidators.dateMinimum(new Date('1920-01-01').toDateString()), CustomValidators.dateMaximum(new Date().toDateString())],
        ],
      });
    }
  }
  public getDataForms(fb: UntypedFormBuilder, data: DataForm) {
    const form: UntypedFormGroup = fb.group({
      defaultAddressId: [data.defaultAddressId],
      gender: [data.gender],
      firstName: [data.firstName, [Validators.required, CustomValidators.specialCharValidator()]],
      lastName: [data.lastName, [Validators.required, CustomValidators.specialCharValidator()]],
      address1: [data.address1, [Validators.required]],
      address2: [data.address2],
      zipCode: [data.zipCode, [Validators.required]],
      city: [data.city, [Validators.required]],
      country: [data.country, [Validators.required]],
      mobile: [data.mobile, [Validators.required]],
      phone: [data.phone],
      langage: [data.langage],
      email: [{ value: data.email, disabled: true }, [Validators.email, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
      birthDate: [data.birthDate],
    });

    return form;
  }

  public getChangeEmailForms(fb: UntypedFormBuilder, userEmail) {
    const form: UntypedFormGroup = fb.group({
      email: [userEmail, [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
      newemail: ['', [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
    });

    return form;
  }

  public getFidelityRegistrationFormStep1(fb: UntypedFormBuilder, data: FidelityRegistration) {
    const form: UntypedFormGroup = fb.group({
      gender: [data.gender, [Validators.required]],
      firstName: [data.firstName, [Validators.required]],
      lastName: [data.lastName, [Validators.required]],
      birthDate: [data.birthDate, [Validators.required, CustomValidators.dateMinimum(new Date('1920-01-01').toDateString()), CustomValidators.dateMaximum(new Date().toDateString())]],
      email: [data.email, [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
      password: [data.password, [Validators.required, Validators.pattern('^(?=(.*[0-9]){2,})(?=.*[a-z])(?=.*[A-Z])(.{8,})$')]],
    });

    return form;
  }

  public getFidelityRegistrationFormStep2(fb: UntypedFormBuilder, data: FidelityRegistration) {
    const form: UntypedFormGroup = fb.group({
      street: [data.street, [Validators.required]],
      zipCode: [data.zipCode, [Validators.required]],
      locality: [data.locality, [Validators.required]],
      countryCode: [data.countryCode, [Validators.required]],
      phone: [data.phone, [Validators.required]],
      optin: [data.optin, [Validators.requiredTrue]],
      newsletter: [data.newsletter],
    });

    return form;
  }

  public getFidelityLoginForm(fb: UntypedFormBuilder, data: FidelityLogin) {
    const form: UntypedFormGroup = fb.group({
      fidLoginEmail: [data.email, [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
      fidLoginPassword: [data.password, [Validators.required]],
    });

    return form;
  }

  public getDeliveryForm(fb: UntypedFormBuilder, data: DeliveryAddress | UserProfileResponse_CustomerAddress) {
    let country = '';

    if (data && Object.getOwnPropertyNames(data).includes('country')) country = (data as DeliveryAddress).country;
    else if (data && Object.getOwnPropertyNames(data).includes('countryCode')) country = (data as UserProfileResponse_CustomerAddress).countryCode;

    const form: UntypedFormGroup = fb.group({
      street: [data?.address1 || null, [Validators.required]],
      zipCode: [data?.zipCode || null, [Validators.required]],
      city: [data?.city || null, [Validators.required]],
      country: [country || null, [Validators.required]],
      phone: [data?.mobile || null, [Validators.required, Validators.pattern]],
    });

    return form;
  }

  public getBirthDateForm(fb: UntypedFormBuilder, data: AddBirthDate) {
    const form: UntypedFormGroup = fb.group({
      birthDate: [data?.birthDate, [Validators.required, CustomValidators.dateMinimum(new Date('1920-01-01').toDateString()), CustomValidators.dateMaximum(new Date().toDateString())]],
    });

    return form;
  }

  public getEditDocumentForm(fb: FormBuilder, category: DocumentCategory, data: DocumentResponse, contextId: number, locale: string) {
    return fb.group({
      title: [data?.title, [Validators.required]],
      locale: [data?.locale ?? locale, contextId == 2 ? [Validators.required] : []],
      category: [{ value: category, disabled: true }, [Validators.required]],
      //HOSTESS GUIDE Document are typed "HOSTESS"
      type: [
        { value: category == DocumentCategoryObject.CommercialAction ? data?.type : DocumentTypeObject.Hostess, disabled: category == DocumentCategoryObject.HostessGuide },
        [Validators.required],
      ],
      //from, until only required for COMMERCIAL ACTIONS
      appliesFrom: [data?.appliesFrom ? this.formatDateToInput(data?.appliesFrom) : null, category == DocumentCategoryObject.CommercialAction ? [Validators.required] : []],
      appliesUntil: [data?.appliesUntil ? this.formatDateToInput(data?.appliesUntil) : null, category == DocumentCategoryObject.CommercialAction ? [Validators.required] : []],
      visibleFrom: [data?.visibleFrom ? this.formatDateToInput(data?.visibleFrom) : null, [Validators.required]],
      visibleUntil: [data?.visibleUntil ? this.formatDateToInput(data?.visibleUntil) : null, [Validators.required]],
      description: [data?.description, [Validators.required]],
      link: [data?.link],
      imageUrl: [data?.imageUrl],
      attachments: [data?.attachments ?? []],
      audiences: fb.group(
        {
          audiences_delegate: [(data?.audiences ?? []).includes('Delegate') || category == DocumentCategoryObject.HostessGuide],
          audiences_hostess: [(data?.audiences ?? []).includes('Hostess') || category == DocumentCategoryObject.HostessGuide],
          audiences_customer: [(data?.audiences ?? []).includes('Customer')],
        },
        { validators: this.audienceSelected }
      ),
    });
  }

  public checkFormAndDisplayError(formToCheck: UntypedFormGroup) {
    formToCheck.markAllAsTouched();
  }

  private audienceSelected(group: FormGroup) {
    const isSelected = group.controls['audiences_delegate'].value === true || group.controls['audiences_hostess'].value === true || group.controls['audiences_customer'].value === true;
    return isSelected ? null : { audiencesRequired: true };
  }

  private formatDateToInput(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }
}
