<div *ngIf="screen.isDesktop" class="jumbo-shadow" [class.visible]="isMenuOpen"></div>
<!--<div class="banner-wrapper" *ngIf="resourcesStore.isLoaded()">-->
<!--  <ng-container *ngFor="let item of rseBanner; let i = index">-->
<!--    <app-csrhighlight-->
<!--      [highlight]="item.displayText"-->
<!--      [highlighticon]="item.displayIcon ? item.displayIcon[0]?.url : undefined"-->
<!--      [highlightlink]="item.clickLinks && item.clickLinks.length ? item.clickLinks[0].url : undefined"-->
<!--      [current]="currentElement == i && rseBanner.length > 1"-->
<!--      [alone]="rseBanner.length == 1"></app-csrhighlight>-->
<!--  </ng-container>-->
<!--</div>-->
<div class="header-wrapper" *ngIf="resourcesStore.isLoaded()">
  <header class="desktop">
    <div class="header-content">
      <div class="header-lv1">
        <div class="logo-container">
          <div class="logo">
            <a [href]="getLogoUrl()"><img loading="lazy" src="/assets/images/logo.svg" alt="Logo Victoria" /></a>
          </div>
        </div>
        <div class="menu-links-container">
          <div class="menu-links-top">
            <div class="nav-container">
              <nav>
                <ul>
                  <ng-template ngFor let-link [ngForOf]="resourcesStore.i18n().header.topNav">
                    <li>
                      <a href="{{ dataService.filter(link.navLink) }}">{{ link.navLabel }}</a>
                    </li>
                  </ng-template>
                  <li>
                    <div class="select-lang-area">
                      <div class="select-lang" id="#currentCountry" (click)="openCountries()">
                        <div class="country-area">
                          <div class="flag">
                            <svg viewBox="0 0 32 32">
                              <use id="flag" [attr.xlink:href]="langSettings.flag"></use>
                            </svg>
                          </div>
                          <div class="country-name">
                            {{ langSettings.country }}
                          </div>
                        </div>
                        <div class="carret-area">
                          <svg viewBox="0 0 32 32">
                            <use xlink:href="#icon-arrow-right"></use>
                          </svg>
                        </div>
                      </div>
                      <div class="lang-options">
                        <div class="select-lang" (click)="changeLangage('nl-be')">
                          <div class="country-area">
                            <div class="flag">
                              <svg viewBox="0 0 32 32">
                                <use xlink:href="#icon-flag-be"></use>
                              </svg>
                            </div>
                            <div class="country-name" [ngClass]="{ current: currentRegion('nl-be') }">Belgïe (NL)</div>
                          </div>
                        </div>
                        <div class="select-lang" (click)="changeLangage('nl-be', 'nl-nl')">
                          <div class="country-area">
                            <div class="flag">
                              <svg viewBox="0 0 32 32">
                                <use xlink:href="#icon-flag-nl"></use>
                              </svg>
                            </div>
                            <div class="country-name" [ngClass]="{ current: currentRegion('nl-nl') }">Nederland (NL)</div>
                          </div>
                        </div>
                        <div class="select-lang" (click)="changeLangage('fr-be')">
                          <div class="country-area">
                            <div class="flag">
                              <svg viewBox="0 0 32 32">
                                <use xlink:href="#icon-flag-be"></use>
                              </svg>
                            </div>
                            <div class="country-name" [ngClass]="{ current: currentRegion('fr-be') }">Belgique (FR)</div>
                          </div>
                        </div>
                        <div class="select-lang" (click)="changeLangage('fr-fr')">
                          <div class="country-area">
                            <div class="flag">
                              <svg viewBox="0 0 32 32">
                                <use xlink:href="#icon-flag-fr"></use>
                              </svg>
                            </div>
                            <div class="country-name" [ngClass]="{ current: currentRegion('fr-fr') }">France (FR)</div>
                          </div>
                        </div>
                        <div class="select-lang" (click)="changeLangage('de-de')">
                          <div class="country-area">
                            <div class="flag">
                              <svg viewBox="0 0 32 32">
                                <use xlink:href="#icon-flag-de"></use>
                              </svg>
                            </div>
                            <div class="country-name" [ngClass]="{ current: currentRegion('de-de') }">Deutschland (DE)</div>
                          </div>
                        </div>
                        <div class="select-lang" (click)="changeLangage('de-de', 'de-at')">
                          <div class="country-area">
                            <div class="flag">
                              <svg viewBox="0 0 32 32">
                                <use xlink:href="#icon-flag-at"></use>
                              </svg>
                            </div>
                            <div class="country-name" [ngClass]="{ current: currentRegion('de-at') }">Österreich (DE)</div>
                          </div>
                        </div>
                        <div class="select-lang" (click)="changeLangage('fr-be', 'fr-lu')">
                          <div class="country-area">
                            <div class="flag">
                              <svg viewBox="0 0 32 32">
                                <use xlink:href="#icon-flag-lu"></use>
                              </svg>
                            </div>
                            <div class="country-name" [ngClass]="{ current: currentRegion('fr-lu') }">Luxemburg (FR)</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div class="menu-links-bottom">
            <div class="nav-container">
              <nav>
                <ul>
                  <ng-template ngFor let-item [ngForOf]="resourcesStore.i18n().header.mainNav">
                    <li class="club">
                      <a class="dropbtn mega-menu" [href]="dataService.filter(item.navLink ?? item.mainLink)">
                        <div class="jumbolink">
                          {{ item.navLabel }}
                        </div>
                        <div class="carret-area">
                          <svg viewBox="0 0 32 32">
                            <use xlink:href="#icon-arrow-right"></use>
                          </svg>
                        </div>
                      </a>
                      <div class="content no-d mega-menu-content" id="mega-menu-club-content">
                        <div class="container">
                          <div class="row flex" [class.mega-menu-limits]="!item.dropdownImage">
                            <div *ngIf="item.dropdownTitle" class="flex-1 flex column center-all mega-content-card-title">
                              <div class="flex column">
                                <h1 class="mega-title">{{ item.dropdownTitle }}</h1>
                                <div class="fit">
                                  <app-wavedlink *ngIf="item.mainLink" [externalUrl]="dataService.filter(item.mainLink)" linkText="{{ item.buttonLabel }}"> </app-wavedlink>
                                </div>
                              </div>
                            </div>
                            <ng-template *ngIf="item.children" ngFor let-child [ngForOf]="item.children">
                              <div class="flex-1 flex column mega-content-card lastOption extraMargin">
                                <p class="lightbold menu-title">
                                  {{ child.columnTitle }}
                                </p>
                                <ul class="flex column">
                                  <ng-template *ngIf="child.children" ngFor let-link [ngForOf]="child.children">
                                    <li class="bottom-gap">
                                      <a href="{{ dataService.filter(link.navLink) }}">{{ link.navLabel }}</a>
                                    </li>
                                  </ng-template>
                                </ul>
                              </div>
                            </ng-template>
                            <div *ngIf="item.dropdownImage" class="flex-2 img-container">
                              <img loading="lazy" src="{{ item.dropdownImage[0].url }}" width="80%" title="{{ item.dropdownImage[0].filename }}" alt="{{ item.dropdownImage[0].filename }}" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ng-template>
                </ul>
              </nav>
            </div>
            <div class="buttons-container">
              <nav>
                <ul>
                  <li>
                    <div class="icon-button" (click)="search()">
                      <svg viewBox="0 0 32 32" class="icon">
                        <use attr.xlink:href="#icon-{{ resourcesStore.i18n().header.iconsNav[0].iconId }}"></use>
                      </svg>
                      <span class="nowrap">{{ resourcesStore.i18n().header.iconsNav[0].label }}</span>
                    </div>
                  </li>
                  <li [class.club]="userStore.isAuthenticated()">
                    <div class="icon-button dropbtn mega-menu">
                      <svg *ngIf="!userStore.isAuthenticated()" (click)="checkEnvironnementAndLogin()" viewBox="0 0 32 32" class="icon">
                        <use attr.xlink:href="#icon-{{ resourcesStore.i18n().header.iconsNav[1].iconId }}"></use>
                      </svg>
                      <app-avatar
                        data-testId="avatar-desktop"
                        *ngIf="userStore.isAuthenticated()"
                        [firstname]="userStore.profile()?.firstname ?? ''"
                        [lastname]="userStore.profile()?.lastname ?? ''"
                        (click)="navigateToHome()">
                      </app-avatar>
                      <span class="nowrap" *ngIf="!userStore.isAuthenticated()">{{ resourcesStore.i18n().header.iconsNav[1].label }}</span>
                      <span class="nowrap" *ngIf="userStore.isAuthenticated()">{{ resourcesStore.i18n().header.iconsNav[2].label }}</span>
                    </div>
                    <div *ngIf="userStore.isAuthenticated()" class="content no-d mega-menu-content mega-menu-icon" id="mega-menu-club-content">
                      <div class="container">
                        <div class="row flex">
                          <div class="flex-1 flex column mega-content-card-title-login center-all">
                            <div class="flex column">
                              <h1 class="welcome_user">
                                {{
                                  'GLOBAL.WELCOMEX'
                                    | translate
                                      : {
                                          X: storage.clientFirstName
                                        }
                                }}
                              </h1>
                              <h1 class="welcome_message">{{ 'MENU.FIDELITY.TITLE' | translate }}</h1>
                              <div *ngIf="userStore.userActiveDemoCode()" class="cta-container primary flex row">
                                <button (click)="goToDemo()" class="btn btn-primary flex party-button j-center gap-10">
                                  <svg viewBox="0 0 32 32" class="icon">
                                    <use xlink:href="#icon-party-rock"></use>
                                  </svg>
                                  {{ 'DEMO.JOINUS' | translate }}
                                  <svg viewBox="0 0 32 32" class="icon">
                                    <use xlink:href="#icon-arrow-right-long"></use>
                                  </svg>
                                </button>
                              </div>
                              <ul class="flex column">
                                <li (click)="logout()">
                                  <a class="block">
                                    <div class="flex ai-center">
                                      <svg viewBox="0 0 32 32" class="icon">
                                        <use xlink:href="#disconnect"></use>
                                      </svg>
                                    </div>
                                    <div class="flex ai-center">
                                      <p>{{ 'GLOBAL.DISCONNECT' | translate }}</p>
                                    </div>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="flex-2 flex column mega-content-card lastOption">
                            <p class="lightbold menu-title">
                              {{ 'MENU.FIDELITY.SUBTITLE' | translate }}
                            </p>
                            <div class="flex row">
                              <ul class="flex column auto-height">
                                <li class="bottom-gap">
                                  <a (click)="hideAllMegaMenu(null, null)" data-testId="home-link" [routerLink]="'/' + contextStore.locale() + '/club/home'">{{ 'MENU.HOME' | translate }}</a>
                                </li>
                                <li class="bottom-gap">
                                  <a (click)="hideAllMegaMenu(null, null)" [routerLink]="'/' + contextStore.locale() + '/club/point-encoding'">{{ 'MENU.POINTENCODING' | translate }}</a>
                                </li>
                                <li class="bottom-gap">
                                  <a (click)="hideAllMegaMenu(null, null)" [routerLink]="'/' + contextStore.locale() + '/club/status'">{{ 'MENU.STATUS' | translate }}</a>
                                </li>
                                <li class="bottom-gap">
                                  <a (click)="hideAllMegaMenu(null, null)" [routerLink]="'/' + contextStore.locale() + '/club/wallet'">{{ 'MENU.WALLET' | translate }}</a>
                                </li>
                                <li class="bottom-gap">
                                  <a (click)="hideAllMegaMenu(null, null)" [routerLink]="'/' + contextStore.locale() + '/club/orders'">{{ 'MENU.ORDERS.ORDERS' | translate }}</a>
                                </li>
                              </ul>
                              <ul class="flex column auto-height">
                                <li class="bottom-gap">
                                  <a (click)="hideAllMegaMenu(null, null)" [routerLink]="'/' + contextStore.locale() + '/myvictoria/demos'">{{ 'MENU.DEMO' | translate }}</a>
                                </li>
                                <!--                                <li class="bottom-gap">-->
                                <!--                                  <a (click)="hideAllMegaMenu(null, null)" [routerLink]="'/' + contextStore.locale() + '/myvictoria/dashboard/' + contextStore.contextId()">{{-->
                                <!--                                    'MENU.DASHBOARD' | translate-->
                                <!--                                  }}</a>-->
                                <!--                                </li>-->
                                <li class="bottom-gap">
                                  <a (click)="hideAllMegaMenu(null, null)" [routerLink]="'/' + contextStore.locale() + '/club/delegate'">{{ 'MENU.DELEGATE' | translate }}</a>
                                </li>
                                <!-- <li class="bottom-gap">
                                  <a (click)="hideAllMegaMenu(null, null)"
                                    [routerLink]="'/' + contextState().locale + '/club/point-evolution'">{{'MENU.POINTS'
                                    |
                                    translate}}</a>
                                </li> -->
                                <!-- <li
                                                                                                                                        class="bottom-gap">
                                                                                                                                        <a (click)="hideAllMegaMenu(null, null)"
                                                                                                                                                [routerLink]="'/' + locale + '/club/client-code'">{{'MENU.CLIENT_CODE'
                                                                                                                                                |
                                                                                                                                                translate}}</a>
                                                                                                                                </li> -->
                                <li class="bottom-gap" *ngIf="userStore.connectedAsRole() != 'Delegate'">
                                  <a (click)="hideAllMegaMenu(null, null)" [routerLink]="'/' + contextStore.locale() + '/club/referral'">{{ 'MENU.BOOSTER' | translate }}</a>
                                </li>
                                <li class="bottom-gap">
                                  <a (click)="hideAllMegaMenu(null, null)" [routerLink]="'/' + contextStore.locale() + '/club/profile'">{{ 'MENU.PROFILE' | translate }}</a>
                                </li>
                                <li class="bottom-gap" *ngIf="userStore.isDocumentsAdmin() || userStore.isGuideAdmin()">
                                  <a (click)="hideAllMegaMenu(null, null)" [routerLink]="'/' + contextStore.locale() + '/club/manager'">{{ 'MENU.MANAGER' | translate }}</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="icon-button" [routerLink]="['/' + contextStore.locale() + '/public/wishlist']">
                      <div *ngIf="wishlistStore.itemsCount() > 0" class="cart-badge">
                        {{ wishlistStore.itemsCount() }}
                      </div>
                      <svg viewBox="0 0 32 32" class="icon">
                        <use attr.xlink:href="#icon-{{ resourcesStore.i18n().header.iconsNav[3]?.iconId }}"></use>
                      </svg>
                      <span class="nowrap">{{ resourcesStore.i18n().header.iconsNav[3]?.label }}</span>
                    </div>
                  </li>
                  <li>
                    <div class="icon-button" [routerLink]="['/' + contextStore.locale() + '/public/cart']">
                      <div *ngIf="cartStore.itemsCount() > 0" class="cart-badge">
                        {{ cartStore.itemsCount() }}
                      </div>
                      <svg viewBox="0 0 32 32" class="icon">
                        <use attr.xlink:href="#icon-{{ resourcesStore.i18n().header.iconsNav[4]?.iconId }}"></use>
                      </svg>
                      <span class="nowrap">{{ resourcesStore.i18n().header.iconsNav[4]?.label }}</span>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <header class="mobile">
    <div class="header-content">
      <div class="header-lv1">
        <div class="logo-container">
          <div class="logo">
            <a [href]="getLogoUrl()"> <img loading="lazy" src="/assets/images/m-logo.svg" alt="Logo Victoria" /></a>
          </div>
        </div>
        <div class="buttons-container">
          <a class="d-only" (click)="search()">
            <div class="icon-button">
              <svg viewBox="0 0 21 21" class="icon">
                <use xlink:href="#icon-search"></use>
              </svg>
            </div>
          </a>
          <div data-testId="avatar-mobile" class="more-button" (click)="showUserMenu()">
            <div class="icon-button">
              <svg *ngIf="!userStore.isAuthenticated()" viewBox="0 0 32 32" class="icon">
                <use xlink:href="#icon-members"></use>
              </svg>
              <app-avatar *ngIf="userStore.isAuthenticated()" [firstname]="userStore.profile()?.firstname ?? ''" [lastname]="userStore.profile()?.lastname ?? ''"></app-avatar>
            </div>
          </div>
          <a [routerLink]="['/' + contextStore.locale() + '/public/wishlist']">
            <div class="icon-button">
              <div *ngIf="wishlistStore.itemsCount() > 0" class="cart-badge">
                {{ wishlistStore.itemsCount() }}
              </div>
              <svg viewBox="0 0 31 29" class="icon peach">
                <use xlink:href="#icon-wishlist"></use>
              </svg>
            </div> </a
          ><a [routerLink]="['/' + contextStore.locale() + '/public/cart']">
            <div class="icon-button">
              <div *ngIf="cartStore.itemsCount() > 0" class="cart-badge">
                {{ cartStore.itemsCount() }}
              </div>
              <svg viewBox="0 0 21 21" class="icon peach">
                <use xlink:href="#icon-bag"></use>
              </svg>
            </div>
          </a>
          <div class="more-button" (click)="showSideMenu()">
            <div *ngIf="!isMenuOpen" class="icon-button menu-action icon-menu">
              <svg viewBox="0 0 32 32" class="icon peach">
                <use xlink:href="#icon-menu"></use>
              </svg>
            </div>
            <div *ngIf="isMenuOpen" class="icon-button menu-action icon-close">
              <svg viewBox="0 0 20 20" class="icon peach">
                <use xlink:href="#icon-close"></use>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</div>
<!-- SIDE MENU -->
<div class="side-menu menu-visible" *ngIf="resourcesStore.isLoaded() && isMenuOpen">
  <ul class="navigable noPadding padding25">
    <ng-template ngFor let-item let-i="index" [ngForOf]="resourcesStore.i18n().header.mainNav">
      <li>
        <a class="flex j-space-between ai-center" (click)="displayMenu(i)">
          <div>{{ item.navLabel }}</div>
          <div>
            <svg viewBox="0 0 32 32">
              <use xlink:href="#icon-arrow-right-long"></use>
            </svg>
          </div>
        </a>
        <div [ngClass]="'mega-mobile mega_' + i" [class.white-container]="item.children && item.children.length > 1">
          <div class="back_menu">
            <div class="arrow_link">
              <div class="arrow_container peach" (click)="closeMenu(i)">
                <svg viewBox="0 0 32 32">
                  <use xlink:href="#icon-arrow-left-long"></use>
                </svg>
              </div>
            </div>
            <div class="back_title">{{ item.navLabel }}</div>
          </div>
          <div class="container">
            <div class="column flex">
              <div *ngIf="item.dropdownTitle" class="flex-1 flex column ac-center j-center mega-content-card-title white-container ai-stretch">
                <div class="flex column">
                  <div *ngIf="item.dropdownImage" class="mobile-img-container">
                    <a [href]="dataService.filter(item.mainLink)">
                      <figure><img loading="lazy" src="{{ item.dropdownImage[0].url }}" width="100%" title="{{ item.dropdownImage[0].filename }}" alt="{{ item.dropdownImage[0].filename }}" /></figure>
                    </a>
                  </div>
                  <h1 class="descriptif">{{ item.dropdownTitle }}</h1>
                  <div class="fit">
                    <app-wavedlink *ngIf="item.mainLink" [externalUrl]="dataService.filter(item.mainLink)" linkText="{{ item.buttonLabel }}"> </app-wavedlink>
                  </div>
                </div>
              </div>
              <div *ngIf="item.navLink" class="flex-1 flex column mega-content-card">
                <ul class="flex column navLink">
                  <li>
                    <a class="flex j-space-between ai-center" href="{{ dataService.filter(item.navLink) }}">
                      <div>{{ item.navLabel }}</div>
                    </a>
                  </li>
                </ul>
              </div>
              <ng-template *ngIf="item.children" ngFor let-child [ngForOf]="item.children">
                <div class="flex-1 flex column mega-content-card" [class.background-pink]="item.children && item.children.length == 1">
                  <p class="lightbold menu-title">
                    {{ child.columnTitle }}
                  </p>
                  <ul class="flex column">
                    <ng-template *ngIf="child.children" ngFor let-link [ngForOf]="child.children">
                      <li>
                        <a class="flex j-space-between ai-center" href="{{ dataService.filter(link.navLink) }}">
                          <div>{{ link.navLabel }}</div>
                        </a>
                      </li>
                    </ng-template>
                  </ul>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </li>
    </ng-template>
  </ul>
  <div class="eshop-mobile flex column navigable">
    <div class="link-container flex row">
      <div class="flex column flex-1">
        <ul>
          <ng-template ngFor let-link [ngForOf]="resourcesStore.i18n().header.topNav">
            <li>
              <a href="{{ dataService.filter(link.navLink) }}">{{ link.navLabel }}</a>
            </li>
          </ng-template>
          <li>
            <div class="lang-container">
              <ul>
                <li>
                  <div class="select-lang-area">
                    <div class="select-lang" id="#currentCountry" (click)="openCountries(true)">
                      <div class="country-area">
                        <div class="flag">
                          <svg viewBox="0 0 32 32">
                            <use id="flag-mobile" [attr.xlink:href]="langSettings.flag"></use>
                          </svg>
                        </div>
                        <div class="country-name">{{ langSettings.country }}</div>
                      </div>
                      <div class="carret-area">
                        <svg viewBox="0 0 32 32">
                          <use xlink:href="#icon-arrow-right"></use>
                        </svg>
                      </div>
                    </div>
                    <div class="lang-options-mobile">
                      <div class="select-lang" (click)="changeLangage('nl-be')">
                        <div class="country-area">
                          <div class="flag">
                            <svg viewBox="0 0 32 32">
                              <use xlink:href="#icon-flag-be"></use>
                            </svg>
                          </div>
                          <div class="country-name" [ngClass]="{ current: currentRegion('nl-be') }">Belgïe (NL)</div>
                        </div>
                      </div>
                      <div class="select-lang" (click)="changeLangage('nl-be', 'nl-nl')">
                        <div class="country-area">
                          <div class="flag">
                            <svg viewBox="0 0 32 32">
                              <use xlink:href="#icon-flag-nl"></use>
                            </svg>
                          </div>
                          <div class="country-name" [ngClass]="{ current: currentRegion('nl-nl') }">Nederland (NL)</div>
                        </div>
                      </div>
                      <div class="select-lang" (click)="changeLangage('fr-be')">
                        <div class="country-area">
                          <div class="flag">
                            <svg viewBox="0 0 32 32">
                              <use xlink:href="#icon-flag-be"></use>
                            </svg>
                          </div>
                          <div class="country-name" [ngClass]="{ current: currentRegion('fr-be') }">Belgique (FR)</div>
                        </div>
                      </div>
                      <div class="select-lang" (click)="changeLangage('fr-fr')">
                        <div class="country-area">
                          <div class="flag">
                            <svg viewBox="0 0 32 32">
                              <use xlink:href="#icon-flag-fr"></use>
                            </svg>
                          </div>
                          <div class="country-name" [ngClass]="{ current: currentRegion('fr-fr') }">France (FR)</div>
                        </div>
                      </div>
                      <div class="select-lang" (click)="changeLangage('de-de')">
                        <div class="country-area">
                          <div class="flag">
                            <svg viewBox="0 0 32 32">
                              <use xlink:href="#icon-flag-de"></use>
                            </svg>
                          </div>
                          <div class="country-name" [ngClass]="{ current: currentRegion('de-de') }">Deutschland (DE)</div>
                        </div>
                      </div>
                      <div class="select-lang" (click)="changeLangage('de-de', 'de-at')">
                        <div class="country-area">
                          <div class="flag">
                            <svg viewBox="0 0 32 32">
                              <use xlink:href="#icon-flag-at"></use>
                            </svg>
                          </div>
                          <div class="country-name" [ngClass]="{ current: currentRegion('de-at') }">Österreich (DE)</div>
                        </div>
                      </div>
                      <div class="select-lang" (click)="changeLangage('fr-be', 'fr-lu')">
                        <div class="country-area">
                          <div class="flag">
                            <svg viewBox="0 0 32 32">
                              <use xlink:href="#icon-flag-lu"></use>
                            </svg>
                          </div>
                          <div class="country-name" [ngClass]="{ current: currentRegion('fr-lu') }">Luxemburg (FR)</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="user-menu user-menu-visible" *ngIf="resourcesStore.isLoaded() && isUserMenuOpen">
  <ul class="navigable" [class.noPadding]="userStore.isAuthenticated()">
    <li>
      <div [ngClass]="'mega-user visible white-container'">
        <div class="container">
          <div class="container-top menu-welcome-title" [class.hasDemo]="userStore.userActiveDemoCode()">
            <h1>{{ 'GLOBAL.WELCOMEX' | translate: { X: storage.clientFirstName } }}</h1>
            <h2>{{ 'MENU.FIDELITY.TITLE' | translate }}</h2>

            <div *ngIf="userStore.userActiveDemoCode()" class="cta-container primary flex row">
              <button (click)="goToDemo()" class="btn btn-primary flex party-button j-center gap-10">
                <svg viewBox="0 0 32 32" class="icon">
                  <use xlink:href="#icon-party-rock"></use>
                </svg>
                {{ 'DEMO.JOINUS' | translate }}
                <svg viewBox="0 0 32 32" class="icon">
                  <use xlink:href="#icon-arrow-right-long"></use>
                </svg>
              </button>
            </div>

            <div class="arrow_container peach" (click)="showUserMenu()">
              <svg viewBox="0 0 32 32">
                <use xlink:href="#icon-close"></use>
              </svg>
            </div>
          </div>
          <div class="menus-container column flex">
            <div class="flex-1 flex column mega-content-card">
              <ul class="flex column" (click)="showUserMenu()">
                <li>
                  <a class="flex j-space-between ai-center" data-testId="home-link-mobile" [routerLink]="'/' + contextStore.locale() + '/club/home'">
                    <div>{{ 'MENU.HOME' | translate }}</div>
                  </a>
                </li>
                <li>
                  <a class="flex j-space-between ai-center" [routerLink]="'/' + contextStore.locale() + '/club/point-encoding'">
                    <div>{{ 'MENU.POINTENCODING' | translate }}</div>
                  </a>
                </li>
                <li>
                  <a class="flex j-space-between ai-center" [routerLink]="'/' + contextStore.locale() + '/club/status'">
                    <div>{{ 'MENU.STATUS' | translate }}</div>
                  </a>
                </li>
                <li>
                  <a class="flex j-space-between ai-center" [routerLink]="'/' + contextStore.locale() + '/club/wallet'">
                    <div>{{ 'MENU.WALLET' | translate }}</div>
                  </a>
                </li>
                <li>
                  <a class="flex j-space-between ai-center" [routerLink]="'/' + contextStore.locale() + '/club/orders'">
                    <div>{{ 'MENU.ORDERS.ORDERS' | translate }}</div>
                  </a>
                </li>
                <li>
                  <a class="flex j-space-between ai-center" [routerLink]="'/' + contextStore.locale() + '/myvictoria/demos'">
                    <div>{{ 'MENU.DEMO' | translate }}</div>
                  </a>
                </li>
                <!--                <li>-->
                <!--                  <a class="flex j-space-between ai-center" [routerLink]="'/' + contextStore.locale() + '/myvictoria/dashboard/' + contextStore.contextId()">-->
                <!--                    <div>{{ 'MENU.DASHBOARD' | translate }}</div>-->
                <!--                  </a>-->
                <!--                </li>-->
                <li>
                  <a class="flex j-space-between ai-center" [routerLink]="'/' + contextStore.locale() + '/club/delegate'">
                    <div>{{ 'MENU.DELEGATE' | translate }}</div>
                  </a>
                </li>
                <li *ngIf="userStore.connectedAsRole() != 'Delegate'">
                  <a class="flex j-space-between ai-center" [routerLink]="'/' + contextStore.locale() + '/club/referral'">{{ 'MENU.BOOSTER' | translate }}</a>
                </li>
                <li *ngIf="userStore.isDocumentsAdmin() || userStore.isGuideAdmin()">
                  <a class="flex j-space-between ai-center" [routerLink]="'/' + contextStore.locale() + '/club/manager'">{{ 'MENU.MANAGER' | translate }}</a>
                </li>
                <li class="last-menu">
                  <a class="flex j-space-between ai-center" [routerLink]="'/' + contextStore.locale() + '/club/profile'">
                    <div>{{ 'MENU.PROFILE' | translate }}</div>
                  </a>
                </li>
                <li (click)="logout()" class="disconnect">
                  <a [routerLink]="'/' + contextStore.locale()" class="block flex ai-center">
                    <svg viewBox="0 0 32 32" class="icon">
                      <use xlink:href="#disconnect"></use>
                    </svg>
                    {{ 'GLOBAL.DISCONNECT' | translate }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
<!-- END SIDE MENU -->
