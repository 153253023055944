import { inject, Injectable } from '@angular/core';
import { filterURL, getCookieDomainByUrl, getVictoriaUrl } from '../utils/filter.utils';
import { CookieService } from 'ngx-cookie-service';
import { ContextStore } from '../../stores/context.store';

@Injectable({
  providedIn: 'root',
})
export class GetDatasService {
  readonly contextStore = inject(ContextStore);
  readonly cookie = inject(CookieService);

  public filter(url: any) {
    if (url.includes('cloudfront')) return url;
    else return getVictoriaUrl(this.contextStore.locale(), true) + filterURL(url);
  }

  public getProductImagesPathForThumbs(fileName: string) {
    if (!fileName) return '/assets/images/empty.png';
    return 'https://d16xcbgaysva9k.cloudfront.net/200x200/' + fileName.toLocaleLowerCase();
  }

  public getProductImagesPathForHigh(fileName: string) {
    if (!fileName) return '/assets/images/empty.png';
    return 'https://d16xcbgaysva9k.cloudfront.net/400x400/' + fileName.toLocaleLowerCase();
  }

  public getProductImagesPathForHD(fileName: string) {
    if (!fileName) return '/assets/images/empty.png';
    return 'https://d16xcbgaysva9k.cloudfront.net/800x800/' + fileName.toLocaleLowerCase();
  }

  public getProductImagesPathForXXL(fileName: string) {
    if (!fileName) return '/assets/images/empty.png';
    return 'https://d16xcbgaysva9k.cloudfront.net/1200x1200/' + fileName.toLocaleLowerCase();
  }

  public updateClientStatusCookies(statusId: any) {
    this.cookie.set('v_cstatus', statusId, null, '/', getCookieDomainByUrl(window.origin));
  }

  public deleteClientStatusCookie() {
    this.cookie.delete('v_cstatus', '/', getCookieDomainByUrl(window.origin));
  }
}
