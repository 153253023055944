<app-modals
  [id]="'confirmation-modal'"
  [title]="title"
  [opened]="isOpened"
  [customClass]="'confirmation-modal'"
  [customStyle]="{ width: width }"
  (requestCloseModal)="cancel()"
  [uppercaseTitle]="true"
  [centerTitle]="true"
  [thinPadding]="true">
  <div class="confirmation-modal-body flex column ai-center">
    <ng-content></ng-content>
    <button class="action-button btn btn-primary j-center" animate (click)="confirm()">{{ 'GLOBAL.ANSWER.YES' | translate }}</button>
    <button class="action-button btn btn-primary j-center white" (click)="cancel()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
  </div>
</app-modals>
