import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ScreenService } from '../../../core/services/screen.service';
import { ContextStore } from '../../../stores/context.store';
import { Router } from '@angular/router';

@Component({
  selector: 'app-basic-header',
  templateUrl: './basic-header.component.html',
  styleUrls: ['./basic-header.component.scss'],
})
export class BasicHeaderComponent {
  public readonly screen = inject(ScreenService);
  private readonly contextStore = inject(ContextStore);
  private readonly router = inject(Router);

  @Input() showClose = false;
  @Input() icon = '#icon-close';
  @Output() requestNavigation = new EventEmitter();

  public async navigate() {
    await this.router.navigate([`/${this.contextStore.locale()}`]);
  }

  public navigateOnClose() {
    this.requestNavigation.emit();
  }
}
