/* eslint-disable @typescript-eslint/naming-convention */
import { Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ResourcesStore } from '../../../../../stores/resources.store';
import { ClubActionTypeEnumObject, ClubCustomerActionTypesResponse, GetClubInformationResponse } from '@victoria-company/agora-client';
import { UserStore } from '../../../../../stores/user.store';
import { ContextStore } from '../../../../../stores/context.store';
import { TranslateService } from '@ngx-translate/core';
import { getTrackableActionTypeToLegacyId, getTrackableActionTypeToLegacyText } from 'src/app/core/utils/common.utils';
import { ClubActionTypeEnum } from '@victoria-company/agora-client/dist/models';
import { Router } from '@angular/router';
import { ModalService } from '../../../../../core/services/modal.service';
import { ClubService } from '../../../../../core/services/V2/club.service';
import { ErrorResponse } from '../../../flow-error/flow-error.component';
import { ScreenService } from '../../../../../core/services/screen.service';

@Component({
  selector: 'app-status-datas',
  templateUrl: './status-datas.component.html',
  styleUrls: ['./status-datas.component.scss'],
})
export class StatusDatasComponent implements OnInit, OnChanges {
  readonly resourcesStore = inject(ResourcesStore);
  readonly userStore = inject(UserStore);
  readonly contextStore = inject(ContextStore);
  readonly translate = inject(TranslateService);
  readonly router = inject(Router);
  readonly screen = inject(ScreenService);

  //LEGACY
  readonly modal = inject(ModalService);
  //
  readonly clubService = inject(ClubService);
  showError = false;
  globalError: ErrorResponse;
  birthdateModalOpened = false

  @Input() clubInformation: GetClubInformationResponse;
  @Input() trackableActions: ClubCustomerActionTypesResponse[];

  ngOnInit() {
    this.handleDisplayErrorMessage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['trackableActions'] && changes['trackableActions'].currentValue) {
      this.handleDisplayErrorMessage();
    }
  }

  async handleTrackableAction(type: ClubActionTypeEnum) {
    try {
      this.globalError = undefined;
      this.showError = false;

      switch (type) {
        case ClubActionTypeEnumObject.CodeAdd:
          return (window.location.href = this.resourcesStore.getEshopCollectionLink());
        case ClubActionTypeEnumObject.BoosterLevel1:
          return this.router.navigate([`/${this.contextStore.locale()}/club/referral`]);
        case ClubActionTypeEnumObject.FollowFacebook: {
          this.openFacebook();
          await this.clubService.followFacebook();
          break;
        }
        case ClubActionTypeEnumObject.FollowInstagram: {
          this.openInstagram();
          await this.clubService.followInstagram();
          break;
        }
        case ClubActionTypeEnumObject.NewsletterSubscription:
          await this.clubService.subscribeNewsletter(this.userStore.profile()?.email, this.contextStore.locale(), this.contextStore.contextId());
          break;
        case ClubActionTypeEnumObject.BirthdayAdd:
          return this.openBirthDateModal();
      }

      await this.userStore.getTrackableActions(this.contextStore.contextId());
    } catch (err) {
      this.globalError = err;
    }
  }

  openBirthDateModal() {
    this.birthdateModalOpened=true;
  }

  closeBirthDateModal(){
    this.birthdateModalOpened=false;
  }

  async saveBirthdateFromModal(birthDate:Date){
    try {
      this.globalError = undefined
      await this.userStore.addBirthdateToProfile(birthDate, this.contextStore.contextId());
      this.closeBirthDateModal();
    }catch(err) {
      this.globalError = err
    }
  }

  private openInstagram() {
    switch (this.contextStore.contextId()) {
      case 1:
        window.open('https://www.instagram.com/victoriabijoux.france/', '_blank');
        break;
      case 2:
        window.open('https://www.instagram.com/victoriajewelry.benelux/', '_blank');
        break;
      case 3:
        window.open('https://www.instagram.com/victoriaschmuck.deutschland/', '_blank');
        break;
      default:
        window.open('https://www.instagram.com/victoriabijoux.france/', '_blank');
    }
  }

  private openFacebook() {
    switch (this.contextStore.contextId()) {
      case 1:
        window.open('https://www.facebook.com/victoriafranceofficiel/', '_blank');
        break;
      case 2:
        window.open('https://www.facebook.com/@victoriajewelry.benelux/', '_blank');
        break;
      case 3:
        window.open('https://www.facebook.com/victoriaschmuck.deutschland/', '_blank');
        break;
      default:
        window.open('https://www.facebook.com/victoriafranceofficiel/', '_blank');
    }
  }

  private handleDisplayErrorMessage() {
    if (this.trackableActions?.length == 0) this.showError = true;
    else this.showError = false;
  }

  protected readonly ClubActionTypeEnumObject = ClubActionTypeEnumObject;
  protected readonly getTrackableActionTypeToLegacyText = getTrackableActionTypeToLegacyText;
  protected readonly getTrackableActionTypeToLegacyId = getTrackableActionTypeToLegacyId;
}
