import { Component, Input } from '@angular/core';
import { getStatusIdFromClubStatus } from '../../../../../core/utils/common.utils';

@Component({
  selector: 'app-status-card',
  templateUrl: './status-card.component.html',
  styleUrls: ['./status-card.component.scss'],
})
export class StatusCardComponent {
  @Input() status: number;
  @Input() currentStatus: number;
  @Input() statusConditions: string;

  protected readonly getStatusIdFromClubStatus = getStatusIdFromClubStatus;
}
