<div class="wishlist-container" *ngIf="wishlistStore.isLoaded() && resourcesStore.isLoaded()">
  <div data-testId="wishlist-visible" *ngIf="wishlistStore.hasItems()">
    <app-page-title [mainTitle]="'WISHLIST.TITLE' | translate" [subTitle]="'WISHLIST.SUBTITLE' | translate"> </app-page-title>
    <div class="wishlist-item-list flex wrap">
      <div class="wishlist-item" *ngFor="let item of wishlistStore.items(); trackBy: trackByItems">
        <!-- TODO : USE PRODUCT ITEM COMPONENT AND REMOVE WISHLIST-ITEM COMPONENT -->
        <app-wishlist-item
          [wishlistItem]="item"
          (addToCartEvent)="addToCart(item)"
          (deleteFromWishlistEvent)="deleteFromWishlist($event)"
          (productClickedEvent)="navigateToProduct(item)"></app-wishlist-item>
      </div>
    </div>
    <hr class="victoria-cart-separator" />
    <div class="cta-container d-width-fit">
      <button data-testId="go-to-collection" class="btn secondary" (click)="navigateToCollection()">
        <label>{{ 'WISHLIST.CONTINUE.SHOPPING' | translate }}</label>
      </button>
    </div>
  </div>
  <div data-testId="wishlist-not-visible" *ngIf="!wishlistStore.hasItems()">
    <app-page-title [mainTitle]="'WISHLIST.TITLE' | translate" [subTitle]="'WISHLIST.NOITEMS' | translate"> </app-page-title>
    <div class="no-wishlist-body flex column center-all">
      <div class="heart-icon">
        <svg viewBox="0 0 31 29" class="icon">
          <use xlink:href="#icon-wishlist"></use>
        </svg>
      </div>
      <div class="description flex j-center">
        <p>{{ 'WISHLIST.DESCRIPTION' | translate }}</p>
      </div>
      <div class="link-container">
        <app-wavedlink
          data-testId="go-to-collection-internal"
          *ngIf="userStore.userActiveDemoCode()"
          [linkUrl]="goToInternalCollection()"
          [linkText]="resourcesStore.i18n().resources.shoppingBag.discoverButtonLabel"></app-wavedlink>
        <app-wavedlink
          data-testId="go-to-collection-external"
          *ngIf="!userStore.userActiveDemoCode()"
          [externalUrl]="goToExternalCollection()"
          [linkText]="resourcesStore.i18n().resources.shoppingBag.discoverButtonLabel"></app-wavedlink>
      </div>
    </div>
  </div>
</div>
<div class="loader-block flex j-center ai-center" *ngIf="!wishlistStore.isLoaded() || !resourcesStore.isLoaded()">
  <div class="loader"></div>
</div>
