<app-page-title mainTitle="{{ 'STATUS.TITLE' | translate }}" subTitle="{{ 'STATUS.SUBTITLE' | translate }}"> </app-page-title>

<div class="current-status-container" *ngIf="userStore.clubInformation()">
  <div class="current-status-title">
    <p
      innerHtml="{{
        'STATUS.POINT.SUMMARY'
          | translate: { Status: 'CLIENT.STATUS' + getStatusIdFromClubStatus(this.userStore.clubInformation()?.clubStatus) | translate, points: this.userStore.clubInformation()?.clubPoints }
      }}"></p>
  </div>

  <div class="current-status-content">
    <div class="card">
      <app-card
        class="flex flex-1"
        [cardType]="cardType.STATUS"
        [data]="userStore.clubInformation()"
        [forecasts]="[]"
        [showStatusLink]="false"
        [showTooltip]="true"
        [footerInformationTooltipText]="'STATUS.TOOLTIP' | translate"
        [footerInformationTooltipPosition]="'right'"
        [paddingBottom]="false"
        [dropPadding]="true"></app-card>
    </div>

    <div class="current-status-summary">
      <div class="current-status-summary-center">
        <div class="current-status-summary-title">
          <p
            innerHtml="{{
              'STATUS.POINT.SUMMARY'
                | translate: { Status: 'CLIENT.STATUS' + getStatusIdFromClubStatus(this.userStore.clubInformation()?.clubStatus) | translate, points: this.userStore.clubInformation()?.clubPoints }
            }}"></p>
        </div>
        <div class="current-status-advantages">
          <span class="current-status-advantages-title">{{ 'STATUS.CARD.AVANTAGES.TITLE' | translate }} </span>
          <ul class="current-status-advantages-list" innerHTML="{{ 'STATUS.CARD.AVANTAGES.STATUS' + getStatusIdFromClubStatus(this.userStore.clubInformation()?.clubStatus) | translate }}"></ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="status-list-container" *ngIf="userStore.clubInformation() && (screen.isDesktop || getStatusIdFromClubStatus(this.userStore.clubInformation()?.clubStatus) != 4)">
  <div class="container">
    <h1
      class="status-list-title"
      *ngIf="getStatusIdFromClubStatus(this.userStore.clubInformation()?.clubStatus) < 4"
      innerHTML="{{
        'STATUS.TITLE.XPOINTSREMAININGTOSTATUSY'
          | translate: { X: getRemainingPoints(), Y: 'CLIENT.STATUS' + (getStatusIdFromClubStatus(this.userStore.clubInformation()?.clubStatus) + 1) | translate }
      }}"></h1>
    <h1
      class="status-list-title"
      *ngIf="getStatusIdFromClubStatus(this.userStore.clubInformation()?.clubStatus) === 4"
      innerHTML="{{ 'STATUS.TITLE.STATUSMAXREACHED' | translate: { X: 'CLIENT.STATUS' + getStatusIdFromClubStatus(this.userStore.clubInformation()?.clubStatus) | translate } }}"></h1>

    <div *ngIf="screen.isDesktop" class="current-status-marker">
      <app-status-marker [status]="1" [currentStatus]="getStatusIdFromClubStatus(this.userStore.clubInformation()?.clubStatus)" />
      <app-status-marker [status]="2" [currentStatus]="getStatusIdFromClubStatus(this.userStore.clubInformation()?.clubStatus)" />
      <app-status-marker [status]="3" [currentStatus]="getStatusIdFromClubStatus(this.userStore.clubInformation()?.clubStatus)" />
      <app-status-marker [status]="4" [currentStatus]="getStatusIdFromClubStatus(this.userStore.clubInformation()?.clubStatus)" />
    </div>

    <div class="status-list">
      <app-status-card
        *ngIf="screen.isDesktop"
        [status]="1"
        [currentStatus]="getStatusIdFromClubStatus(this.userStore.clubInformation()?.clubStatus)"
        [statusConditions]="'STATUS.CARD.POINTS.RANGEXY' | translate: { X: 0, Y: 249 }" />
      <app-status-card
        *ngIf="screen.isDesktop || getStatusIdFromClubStatus(this.userStore.clubInformation()?.clubStatus) == 1"
        [status]="2"
        [currentStatus]="getStatusIdFromClubStatus(this.userStore.clubInformation()?.clubStatus)"
        [statusConditions]="'STATUS.CARD.POINTS.RANGEXY' | translate: { X: 250, Y: 499 }" />
      <app-status-card
        *ngIf="screen.isDesktop || getStatusIdFromClubStatus(this.userStore.clubInformation()?.clubStatus) == 2"
        [status]="3"
        [currentStatus]="getStatusIdFromClubStatus(this.userStore.clubInformation()?.clubStatus)"
        [statusConditions]="'STATUS.CARD.POINTS.RANGEXY' | translate: { X: 500, Y: 749 }" />
      <app-status-card
        *ngIf="screen.isDesktop || getStatusIdFromClubStatus(this.userStore.clubInformation()?.clubStatus) == 3"
        [status]="4"
        [currentStatus]="getStatusIdFromClubStatus(this.userStore.clubInformation()?.clubStatus)"
        [statusConditions]="'STATUS.CARD.POINTS.RANGEMAXX' | translate: { X: 749 }" />
    </div>
  </div>
</div>

<div class="status-tips-container" *ngIf="this.userStore.clubInformation()">
  <div>
    <app-status-data-wrapper [clubInformation]="this.userStore.clubInformation()"></app-status-data-wrapper>
  </div>
</div>
