<div class="status-datas-container">
  <div class="error-box" *ngIf="showError">
    <div class="flex row ai-center">
      <svg viewBox="0 0 28 28" class="icon-warning">
        <use xlink:href="#icon-warning"></use>
      </svg>
      <div>
        <span>{{ 'ERROR.TITLE' | translate }}</span>
      </div>
    </div>
  </div>
  <div class="data-card-container" *ngIf="clubInformation && trackableActions?.length">
    @for (dataRow of trackableActions; track dataRow.actionType) {
      <app-status-action-card (requestExecuteAction)="handleTrackableAction(dataRow.actionType)" [cardType]="dataRow.actionType" [value]="dataRow.referenceValue" [doneDate]="dataRow.doneDate" />
    }
  </div>
</div>

<app-add-birthdate-modal [opened]="birthdateModalOpened" (requestCloseModal)="closeBirthDateModal()" (requestAddBirthdateToProfile)="saveBirthdateFromModal($event)" />
<app-flow-error *ngIf="globalError" [error]="globalError" />
