<div class="status-list-card" [class.current-status]="status == currentStatus" [class.futur-status]="currentStatus + 1 == status">
  <div class="card-title">
    <span class="status-text">{{ 'STATUS.CARD.TITLE' | translate }}</span>
    <span class="status-value">{{ 'CLIENT.STATUS' + status | translate }}</span>
  </div>
  <div class="card-img-container">
    <img loading="lazy" [src]="'/assets/images/business/statut-' + status + '.svg'" />
  </div>
  <div class="card-info">
    <div class="info-sm">
      <p class="card-info-title">{{ 'STATUS.CARD.POINTS.GET' | translate }}</p>
      <div class="info-main card-info-points">{{ statusConditions }}</div>
    </div>
  </div>
  <div class="card-advantages-container">
    <div class="advantages-title center">
      {{ 'STATUS.CARD.AVANTAGES.TITLE' | translate }}
    </div>
    <div class="advantages">
      <ul class="advantages-list" innerHTML="{{ 'STATUS.CARD.AVANTAGES.STATUS' + status | translate }}"></ul>
    </div>
  </div>
</div>
