import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Breadcrumb } from '../models/breadcrumb.model';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  // Observable exposing the breadcrumb hierarchy
  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);
  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  private readonly _loaded$ = new BehaviorSubject<boolean>(false);
  readonly loaded$ = this._loaded$.asObservable();

  constructor(
    private router: Router,
    private translate: TranslateService
  ) {
    this.router.events
      .pipe(
        // Filter the NavigationEnd events as the breadcrumb is updated only when the route reaches its end
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(async () => {
        // Construct the breadcrumb hierarchy
        const root = this.router.routerState.snapshot.root;
        const breadcrumbs: Breadcrumb[] = [];
        await this.addBreadcrumb(root, [], breadcrumbs);

        // Emit the new hierarchy
        this._breadcrumbs$.next(breadcrumbs);
        this._loaded$.next(true);
      });
  }

  public async reloadBreadcrumbs() {
    const root = this.router.routerState.snapshot.root;
    const breadcrumbs: Breadcrumb[] = [];
    await this.addBreadcrumb(root, [], breadcrumbs);

    // Emit the new hierarchy
    this._breadcrumbs$.next(breadcrumbs);
  }
  private async addBreadcrumb(route: ActivatedRouteSnapshot, parentUrl: string[], breadcrumbs: Breadcrumb[]) {
    if (route) {
      //Notify Info Loading
      this._loaded$.next(false);

      // Construct the route URL
      const routeUrl = parentUrl.concat(route.url.map(url => url.path));

      // Add an element for the current route part
      if (route.data.breadcrumb && !route.params.value) {
        const breadcrumb = {
          label: await firstValueFrom(this.translate.get(this.getLabel(route.data))),
          url: '/' + routeUrl.join('/'),
        };
        breadcrumbs.push(breadcrumb);
        //if route has an id, pop the id to keep the parent route navigable
      } else if (route.data.breadcrumb && route.params.value) {
        const routes = [...routeUrl];
        routes.pop();

        //For orders route contain both value and id params
        //This is the only relevant case of double route params with ":value" params used
        if (route.params.id) routes.pop();

        const breadcrumb = {
          label: await firstValueFrom(this.translate.get(this.getLabel(route.data))),
          url: '/' + routes.join('/'),
        };
        breadcrumbs.push(breadcrumb);
      }

      //if route has an id, add it to the breadcrumb and make it navigable
      if (route.params.value) {
        const paramCrumb = {
          label: route.params.value,
          url: undefined,
        };
        breadcrumbs.push(paramCrumb);
      }

      // Add another element for the next route part
      await this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);

      this._loaded$.next(true);
    }
  }
  private getLabel(data: Data) {
    // The breadcrumb can be defined as a static string or as a function to construct the breadcrumb element out of the route data
    return typeof data.breadcrumb === 'function' ? data.breadcrumb(data) : data.breadcrumb;
  }
}
