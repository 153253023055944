import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CardType } from '../../../../../core/enums/card-type.enum';
import { GetClubInformationResponse, VoucherResponse } from '@victoria-company/agora-client';
import { ErrorResponse } from '../../../flow-error/flow-error.component';
import { UserService } from '../../../../../core/services/V2/user.service';
import { ContextStore } from '../../../../../stores/context.store';
import { UserStore } from '../../../../../stores/user.store';

export enum CreateVoucherTabsEnum {
  createVoucherTab = 'createVoucherTab',
  voucherCreatedTab='voucherCreatedTab'
}

@Component({
  selector: 'app-create-voucher-modal',
  templateUrl: './create-voucher-modal.component.html',
  styleUrls: ['./create-voucher-modal.component.scss'],
})
export class CreateVoucherModalComponent implements OnChanges {

  @Input() clubInformation: GetClubInformationResponse;
  @Input() opened = false
  @Output() requestCloseModal = new EventEmitter()

  private readonly userService = inject(UserService)
  private readonly userStore = inject(UserStore)
  private readonly contextStore = inject(ContextStore)

  selectedTab= CreateVoucherTabsEnum.createVoucherTab
  isLoading = false
  hasError = false
  error: ErrorResponse;
  voucherValue:string|number;
  voucherCreated:VoucherResponse


  ngOnChanges(changes:SimpleChanges){
    if(changes['clubInformation'] && changes['clubInformation'].currentValue){
      this.voucherValue = this.clubInformation.walletAmount.toFixed(2);
    }
  }

  public close(){
    this.requestCloseModal.emit();
  }

  //Go to step "createVoucherTab" display creation form, Go to step "voucherCreatedTab" display voucher created summary
  public goToStep(step : CreateVoucherTabsEnum){
    if(step==CreateVoucherTabsEnum.createVoucherTab){
      this.voucherValue = this.clubInformation.walletAmount.toFixed(2);
    }

    this.selectedTab = step;
  }

  public async downloadVoucher(code:string){
    const result = await this.userService.downloadVoucher(code, this.contextStore.locale());
    const mediaType = 'application/pdf';
    const blob = new Blob([result], { type: mediaType });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  public copyValue(event:MouseEvent, value:string){
    navigator.clipboard.writeText(value).then(() => {});
    const el = event.target as HTMLElement;
    if (el) {
      el.closest('div').getElementsByClassName('icon-copy-check')[0]?.classList.add('display');
      setTimeout(() => {
        el.closest('div').getElementsByClassName('icon-copy-check')[0]?.classList.remove('display');
      }, 3000);
    }
  }

  async createVoucherAction() {
    if (!this.voucherValue) {
      this.hasError = true;
      return;
    }
    this.isLoading = true;
    this.hasError = false;

    try {
      const voucherResponse = await this.userStore.createVoucherClubFromWallet(this.voucherValue as number);
      this.voucherCreated = voucherResponse;
      this.goToStep(CreateVoucherTabsEnum.voucherCreatedTab);
      await this.userStore.getVouchers(0, true);
      await this.userStore.loadClubInformation();
      this.isLoading = false;
    } catch (err) {
      this.error = err;
      this.isLoading = false;
    }
  }

  protected readonly cardType = CardType;
  protected readonly CreateVoucherTabsEnum = CreateVoucherTabsEnum;
}
