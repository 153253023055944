import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { createMask } from '@ngneat/input-mask';
import { DataForm } from '../../../../../core/models/dataForm.model';
import { FormsService } from '../../../../../core/services/forms.service';

@Component({
  selector: 'app-add-birthdate-modal',
  templateUrl: './add-birthdate-modal.component.html',
  styleUrls: ['./add-birthdate-modal.component.scss'],
})
export class AddBirthdateModalComponent implements OnInit {

  private readonly formService = inject(FormsService)
  private readonly fb = inject(UntypedFormBuilder)

  @Input() opened = false;
  @Output() requestAddBirthdateToProfile = new EventEmitter<Date>()
  @Output() requestCloseModal = new EventEmitter()


  dateInputMask: any;
  birthDateForm: UntypedFormGroup;
  isBusy = false;

  public get bForm() {
    return this.birthDateForm?.controls;
  }

  constructor() { }

  ngOnInit() {
    this.dateInputMask = createMask<Date>({
      alias: 'datetime',
      inputFormat: 'dd/mm/yyyy',
      placeholder: '__/__/____',
      parser: (value: string) => {
        const values = value.split('/');
        const year = +values[2];
        const month = +values[1] - 1;
        const date = +values[0];
        return new Date(year, month, date);
      },
    });

    this.birthDateForm = this.formService.getBirthDateForm(this.fb, new DataForm());

  }

  close(){
    this.requestCloseModal.emit();
  }

  addBirthDate(){
    if(this.isBusy) return;

    this.isBusy = true;
    const data = this.birthDateForm.getRawValue();

    if (data.birthDate && this.birthDateForm.valid) {
      this.requestAddBirthdateToProfile.emit(data.birthDate)
    }  else this.formService.checkFormAndDisplayError(this.birthDateForm);

    this.isBusy = false;
  }
}
